import { useQuery } from '@tanstack/react-query'

import { userKeys } from '../query-keys'

import type { User } from '@/common'
import { api } from '@/services'

const fetchUsers = async () => {
  const { data } = await api.get<User[]>('/user')

  return data
}

const useUsers = () => useQuery(userKeys.list(), fetchUsers)

export { useUsers }
