import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Category, categoryKeys, productKeys } from '@/features/product'
import { api } from '@/services'

type RemoveProductCategoryMutation = {
  productId: string
  categoryId: string
}

const useRemoveProductCategoryMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ productId, categoryId }: RemoveProductCategoryMutation) => {
      console.log({ productId, categoryId })

      return api.put(`/categories/${categoryId}`, {
        product: [productId],
      })
    },
    {
      onMutate: async ({ productId, categoryId }) => {
        await queryClient.cancelQueries(categoryKeys.all)

        const oldProductCategories = queryClient.getQueryData<Category[]>(
          categoryKeys.product(productId),
        )

        if (oldProductCategories) {
          const updatedProductCategories = oldProductCategories.filter(
            (category) => category.id !== categoryId,
          )

          queryClient.setQueryData(categoryKeys.product(productId), updatedProductCategories)

          return oldProductCategories
        }
      },
      onError: (err, { productId }, oldProductCategories) => {
        console.log(err)

        if (oldProductCategories) {
          queryClient.setQueryData(categoryKeys.product(productId), oldProductCategories)
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(productKeys.all)
        queryClient.invalidateQueries(categoryKeys.all)
      },
    },
  )
}

export { useRemoveProductCategoryMutation }
