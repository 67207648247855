import styled from 'styled-components'

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin: 16px 0 16px;

  .total {
    color: #000000d9;
  }

  .controls {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 12px;
    line-height: 1;
  }

  .input-wrapper {
  }

  .ant-typography {
    color: #000000;
    padding-top: 1px;
  }

  .input-pagination {
    border: 1px solid #80c343;
    border-radius: 3px;
    color: #80c343;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 32px;
    text-align: center;
  }
`

export const PageBtn = styled.button<{ page: number; totalPages: number }>`
  background: transparent;
  border: 0;

  &.previous {
    color: ${({ page }) => {
      if (page === 0 || page === 1) {
        return '#ccc'
      }

      return `#80c343`
    }};
  }

  &.next {
    color: ${({ page, totalPages }) => {
      if (page >= totalPages) {
        return '#ccc'
      }

      return `#80c343`
    }};
  }
`
