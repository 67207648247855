import Balancer from 'react-wrap-balancer'

import { Container } from './styles'

import { formatCurrency } from '@/common'

type TableTooltipTitleProps = {
  type: 'cashback' | 'cupom'
  valueType?: 'PERCENT' | 'REAL'
  value: number
  valueWithDiscount: number
}

const TableTooltipTitle = ({
  type,
  valueType = 'PERCENT',
  value,
  valueWithDiscount,
}: TableTooltipTitleProps) => {
  const formattedValue = valueType === 'PERCENT' ? `${value}%` : formatCurrency({ value })

  const hasDiscount = valueWithDiscount > 0

  return (
    <Container>
      <span>
        Possui {type} de <strong>{formattedValue}</strong>
      </span>

      {hasDiscount && (
        <Balancer>
          <span>
            Preço com desconto aplicado:{' '}
            <strong>{formatCurrency({ value: valueWithDiscount })}</strong>
          </span>
        </Balancer>
      )}
    </Container>
  )
}

export { TableTooltipTitle }
