import { Pagination as AntdPagination } from 'antd'
import styled from 'styled-components'

type CustomPaginationProps = {
  $size?: 'small' | 'default'
}

export const Pagination = styled(AntdPagination)<CustomPaginationProps>`
  width: 100%;
  display: flex;
  margin: ${({ $size }) => ($size === 'small' ? '10px 0 8px' : '16px 0')};
  justify-content: flex-end;

  .ant-pagination-total-text {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .ant-pagination-item-link:not([disabled]) {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const PaginationRange = styled.div``
