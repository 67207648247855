import { Button, Divider as AntdDivider } from 'antd'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 20px;
    font-weight: 700;
    color: #414243;
    margin-bottom: 0;
  }

  div {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
      padding: 0;
    }
  }
`

export const CancelButton = styled(Button)`
  color: #80c343;
  border-radius: 2px;
  border: 1px solid #80c343;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const SaveButton = styled(Button)`
  border-radius: 2px;
  background: #83b81a;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const Content = styled.div`
  padding: 12px 24px;
`

export const Divider = styled(AntdDivider)`
  border-color: rgba(0, 0, 0, 0.2);
  margin: 0 0 20px;
`
