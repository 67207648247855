import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import type { Products } from '../../types'
import { productKeys } from '../query-keys'

import { filterStore as useFilterStore } from '@/app'
import { api } from '@/services'

export async function fetchProducts({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['list']>>) {
  const [, , { page, activeFilters, activeSorting }] = queryKey

  const hasSorting = Object.keys(activeSorting).length

  const payload = {
    filtros: activeFilters,
    ...(hasSorting && { ordenacao: activeSorting }), // only send sorting if it has a value
  }

  const { data } = await api.post<Products>(`/produto/list?page=${page}`, payload)

  return data
}

const useProducts = (page = 1) => {
  const activeFilters = useFilterStore((s) => s.activeFilters)
  const activeSorting = useFilterStore((s) => s.activeSorting)

  return useQuery(productKeys.list(page, activeFilters, activeSorting), fetchProducts, {
    keepPreviousData: true,
  })
}

export { useProducts }
