import toast from 'react-hot-toast'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { productKeys } from '../../../queries'
import type { ProductDetails, Products } from '../../../types'

import { sleep } from '@/common'
import { api } from '@/services'

const useStatusMutation = () => {
  return useMutation(
    async (productId: string) => {
      return api.get(`/produto/${productId}/request_price_update_status`)
    },
    {
      onError: (error) => {
        toast.dismiss()
        toast.error('Erro ao atualizar os preços do produto, tente novamente mais tarde')
        console.error(error)
      },
    },
  )
}

const useUpdatePricesMutation = () => {
  const queryClient = useQueryClient()
  const updatePriceStatus = useStatusMutation()

  return useMutation(
    async (productId: string) => {
      return api.post(`/produto/${productId}/request_price_update`)
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(productKeys.all)
      },
      onError: (error) => {
        toast.dismiss()
        toast.error('Erro ao atualizar os preços do produto, tente novamente mais tarde')
        console.error(error)
      },
      onSuccess: async ({ data }, productId) => {
        await sleep(5000) // wait 5 seconds to make sure server is processing the previous request
        let response: AxiosResponse

        response = await updatePriceStatus.mutateAsync(productId)

        while (response.status && response.status === 204) {
          await sleep(3500)
          response = await updatePriceStatus.mutateAsync(productId)
        }

        const [productsCache] = queryClient.getQueriesData<Products>(productKeys.lists())

        if (productsCache) {
          const oldProductsList = productsCache[1]?.results
          // Update product list
          const updatedProductsList = oldProductsList?.map((product) => {
            if (product.id === productId) {
              return {
                ...product,
                precos: response.data.precos,
              }
            }
            return product
          })

          const updatedListChache = [
            productsCache[0],
            { ...productsCache[1], results: updatedProductsList },
          ]
          queryClient.setQueryData(productKeys.lists(), updatedListChache)
        }

        const oldProduct = queryClient.getQueryData<ProductDetails>(productKeys.detail(productId))
        if (oldProduct) {
          // Update product page
          queryClient.setQueryData(productKeys.detail(productId), {
            ...response.data,
            marketplaces: {
              info: oldProduct.marketplaces.info,
              results: response.data.marketplaces,
            },
          })
        }

        toast.dismiss()
        toast.success('Preços atualizados com sucesso')
      },
      onSettled: () => queryClient.invalidateQueries(productKeys.all),
    },
  )
}

export { useUpdatePricesMutation, useStatusMutation }
