import { CloseOutlined } from '@ant-design/icons'
import { Tag as AntdTag } from 'antd'
import styled, { css } from 'styled-components'

import { hexToRgba } from '@/common'

export const Tag = styled(AntdTag)<{ $isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  position: relative;
  padding: 0 8px;

  color: #434343;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.brand};
  border-radius: 9999999px;
  font-size: 14px;

  text-transform: capitalize;
  cursor: default;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      color: #6c6c6c;
      border-color: #6c6c6c;
    `}
`

export const RemoveTagButton = styled(CloseOutlined)<{ $isDisabled?: boolean }>`
  color: ${({ theme }) => theme.colors.darkGreen};
  font-size: 12px;
  background: transparent;
  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => hexToRgba(theme.colors.darkGreen, 0.7)};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed !important;
      color: #6c6c6c;

      :hover {
        color: #6c6c6c;
      }
    `}
`
