import { Link } from 'react-router-dom'

import { StarFilled } from '@ant-design/icons'
import { Tag } from 'antd'
import styled, { css } from 'styled-components'

type TableTagProps = {
  $isDisabled: boolean
  brand: string
}

type RankingSpanProps = {
  $isDisabled: boolean
}

type ProductLinkProps = {
  $isDisabled: boolean
}

type Brands = keyof typeof brandColor

type TopRanking = 1 | 2 | 3

const brandColor = {
  Acer: `#99cf69`,
  Asus: `#696969`,
  Dell: `#59afcd`,
  Lenovo: `#e95751`,
  // Positivo: `#f9ad64`,
}

const starColor = {
  1: `#FFBB0E`,
  2: `#999999`,
  3: `#FF730E`,
} as const

export const TableTag = styled(Tag)<TableTagProps>`
  color: #fff;
  background-color: ${({ brand }) => brandColor[brand as Brands] ?? `#367100`};
  border-radius: 4px;
  font-size: 11px;
  font-weight: 700;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: #808080;
      background-color: #f5f5f5;
      opacity: 0.5;
      cursor: not-allowed;
    `}
`
export const RankingSpan = styled.span<RankingSpanProps>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  justify-content: end;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: #808080;
      opacity: 0.5;
      cursor: not-allowed;
    `}
`

export const Star = styled(StarFilled)<{ rank: TopRanking }>`
  font-size: 8px;
  color: ${({ rank }) => starColor[rank]};
  padding-bottom: 3px;
  padding-right: 1px;
`
export const SoldSpan = styled.span`
  font-size: 20px;
  font-weight: bold;
`

export const ProductLink = styled(Link)<ProductLinkProps>`
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 600;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: #808080;
      opacity: 0.5;
      cursor: not-allowed;
      :hover {
        color: #808080;
        filter: none;
      }
    `}
`
