import { Tag, RemoveTagButton } from './styles'

type ProductTagProps = {
  tagId: string
  children: string
  closable?: boolean
  handleRemoveTag?: (tagId: string) => void
  isDisabled?: boolean
}

const ProductTag = ({
  tagId,
  closable = false,
  children,
  handleRemoveTag,
  isDisabled,
}: ProductTagProps) => {
  return (
    <Tag $isDisabled={isDisabled}>
      {children}
      {closable && handleRemoveTag && (
        <RemoveTagButton $isDisabled={isDisabled} onClick={() => handleRemoveTag(tagId)} />
      )}
    </Tag>
  )
}

export { ProductTag }
