import { ChangeEvent, useEffect, useState } from 'react'
import { FixedSizeList } from 'react-window'

import { Space } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { CheckboxValueType } from 'antd/lib/checkbox/Group'

import { activeFilterNames } from '../../../constants'
import { Checkbox, CheckboxGroup, Container, Header, ResetButton, Searchbox, Title } from './styles'

import { filterStore as useFilterStore } from '@/app'
import { useDebounce } from '@/features/product/hooks'

interface CheckboxFilterProps {
  title?: string
  originalData: string[]
  placeholder?: string
  tooltipDescription?: string
  onChange?: (value: CheckboxValueType[] | undefined) => void
  value?: CheckboxValueType[] | undefined
  name: string
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  title,
  name,
  originalData,
  placeholder = 'Buscar',
  onChange,
  value,
}) => {
  const activeFilters = useFilterStore((s) => s.activeFilters)

  const [data, setData] = useState(originalData)
  const [searchValue, setSearchValue] = useState('')
  const [options, setOptions] = useState(data)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedItems, setCheckedItems] = useState<string[]>([])

  const debouncedSearch = useDebounce(searchValue, 300)

  useEffect(() => {
    const activeFilterValues = activeFilters[name]

    if (!activeFilterValues) {
      // if there are no active filters for this filter name (e.g. marca) then reset the checked items
      setCheckedItems([])
      setIsCheckedAll(false)
      onChange?.([])
      return
    } else {
      // if there are active filters for this filter name (e.g. marca) then set the checked items to the active filters
      setCheckedItems(activeFilterValues as string[])
    }
    // exhaustive-deps is disabled because we don't want to re-run this effect when onChange changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, name])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e?.target?.value)

  useEffect(() => {
    let filteredOptions

    if (searchValue === '') {
      filteredOptions = data
    } else {
      filteredOptions = data.filter(
        (item) =>
          item.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          item.toLowerCase() === debouncedSearch.toLowerCase(),
      )
    }

    setOptions(filteredOptions)
  }, [data, debouncedSearch, searchValue])

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const currentIndex = checkedItems.indexOf(e.target.value)
    const newCheckedItems = [...checkedItems]

    if (currentIndex === -1) {
      newCheckedItems.push(e.target.value)
    } else {
      newCheckedItems.splice(currentIndex, 1)
    }

    setCheckedItems(newCheckedItems)
    onChange?.(newCheckedItems)
  }

  const checkAll = () => {
    setIsCheckedAll(true)

    const newCheckedItems = [...originalData]
    setCheckedItems(newCheckedItems)
    onChange?.(newCheckedItems)
  }

  const uncheckAll = () => {
    setIsCheckedAll(false)

    const newCheckedItems = [] as string[]
    setCheckedItems(newCheckedItems)
    onChange?.(undefined)
  }

  const filterTitle = activeFilterNames.get(name) || title

  const Row = ({ index, style }: any) => {
    const option = options[index]
    return (
      <div style={style}>
        <Checkbox
          value={option}
          key={option}
          checked={checkedItems.includes(option)}
          onChange={(e) => handleCheckboxChange(e)}
        >
          {option}
        </Checkbox>
      </div>
    )
  }

  return (
    <Container>
      <Header>
        {title ? (
          <Space size={6}>
            <Title>{filterTitle}</Title>
          </Space>
        ) : (
          <Searchbox
            placeholder={placeholder}
            value={searchValue}
            onChange={handleSearch}
            enterButton
          />
        )}
        {isCheckedAll ? (
          <ResetButton onClick={uncheckAll} htmlType="button" type="link">
            Limpar
          </ResetButton>
        ) : (
          <ResetButton onClick={checkAll} htmlType="button" type="link">
            Selecionar Todos
          </ResetButton>
        )}
      </Header>

      {title && (
        <Searchbox
          placeholder={placeholder}
          value={searchValue}
          onChange={handleSearch}
          enterButton
        />
      )}

      <CheckboxGroup value={value}>
        <FixedSizeList
          height={300}
          width={'100%'}
          itemData={options}
          itemCount={options.length}
          itemSize={26}
        >
          {Row}
        </FixedSizeList>
      </CheckboxGroup>
    </Container>
  )
}

export { CheckboxFilter }
