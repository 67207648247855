import { Radio } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Title = styled.h5`
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`

export const RadioGroup = styled(Radio.Group)`
  height: auto;
  max-height: 175px;
  /* overflow-y: auto; */

  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    text-transform: capitalize;
  }
`
