import { useMutation, useQueryClient } from '@tanstack/react-query'

import { filterKeys } from '../../queries'
import type { PresetFilter } from '../../types'

import { filterStore as useFilterStore } from '@/app'
import type { ActiveFilters } from '@/common'
import { api } from '@/services'

type CreatePresetMutation = {
  filters: ActiveFilters
}

const useCreatePresetMutation = () => {
  const queryClient = useQueryClient()
  const setLastActivePreset = useFilterStore((s) => s.setLastActivePreset)

  return useMutation(
    async ({ filters }: CreatePresetMutation) => {
      return api.post<PresetFilter>('/presetfilter', filters)
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(filterKeys.presets())
      },
      onSuccess: ({ data }) => {
        const oldPresets = queryClient.getQueryData<PresetFilter[]>(filterKeys.presets())

        if (oldPresets) {
          queryClient.setQueryData(filterKeys.presets(), [...oldPresets, data])
        }

        setLastActivePreset({
          id: data.id,
          name: data.name,
          filters: data.filters,
          username: data.username,
        })
      },
      onError: (err) => console.log(err),
      onSettled: () => {
        queryClient.invalidateQueries(filterKeys.presets())
      },
    },
  )
}

export { useCreatePresetMutation }
