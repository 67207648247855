import { useState, useRef } from 'react'
import toast from 'react-hot-toast'

import { PlusOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Input, InputRef, Form, Tooltip } from 'antd'

import { useUserTags } from '../../queries'
import { Tag } from '../../types'

type TagInputProps = {
  productTags?: Tag[]
  handleAddTag?: (tag: string) => Promise<void>
  showButton?: boolean
}

const TagInput = ({ productTags, handleAddTag, showButton = false }: TagInputProps) => {
  const form = Form.useFormInstance()

  const { data: userTags } = useUserTags()
  const inputRef = useRef<InputRef>(null)
  const [inputValue, setInputValue] = useState('')

  const isDisabled = productTags && productTags?.length >= 3

  const autocompleteOptions = userTags
    ?.map((tag) => ({ value: tag.tag }))
    .filter((tag) => !productTags?.some((productTag) => productTag.tag === tag.value))

  function handleChange(tag: string) {
    setInputValue(tag)
    form.setFieldsValue({ tag: tag })
  }

  async function handleAdd(selectedTag?: string) {
    if (!inputValue || !inputRef.current?.input?.value) {
      inputRef.current?.focus()
    } else {
      const tag = selectedTag || inputValue

      if (tag !== inputRef.current?.input?.value) return

      if (tag.length > 10) {
        return toast.error('O tamanho máximo de uma tag é de 10 caracteres')
      }

      handleAddTag?.(tag)

      setInputValue('')
      form.setFieldsValue({ tag: '' })
    }
  }

  return (
    <Form.Item name="tag" style={{ width: '100%', position: 'relative' }}>
      <Tooltip
        trigger={['hover', 'click']}
        title={'É possível adicionar até 3 tags a um produto.'}
        placement="topLeft"
      >
        <AutoComplete
          disabled={isDisabled}
          aria-autocomplete="none"
          backfill
          value={inputValue}
          dropdownMatchSelectWidth
          options={autocompleteOptions}
          onSelect={(selectedTag: string) => handleChange(selectedTag)}
          // onBlur={() => setInputValue('')}
          maxLength={10}
          filterOption={(inputValue, option) =>
            option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
          }
        >
          <Input
            name="tag"
            autoComplete="off"
            aria-autocomplete="none"
            ref={inputRef}
            placeholder="Ex: Blackfriday"
            onPressEnter={() => handleAdd()}
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            style={{ height: '32px', paddingRight: '42px' }}
          />
        </AutoComplete>

        {showButton && (
          <Button
            disabled={isDisabled}
            type="primary"
            onClick={() => handleAdd()}
            style={{ position: 'absolute', right: 0 }}
          >
            <PlusOutlined />
          </Button>
        )}
      </Tooltip>
    </Form.Item>
  )
}

export { TagInput }
