import { useMutation, useQueryClient } from '@tanstack/react-query'

import { userKeys } from '../../../queries'

import type { User } from '@/common'
import { api } from '@/services'

type CreateUserMutation = {
  name: string
  username: string
  password: string
  groups: string[]
  phone_number: string
}

const useCreateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (data: CreateUserMutation) => {
      return api.post('/user', data)
    },
    {
      onMutate: async (newUser) => {
        await queryClient.cancelQueries(userKeys.all)

        const previousUsers = queryClient.getQueryData<User[]>(userKeys.list())

        if (previousUsers) {
          queryClient.setQueryData(userKeys.list(), [...previousUsers, newUser])
        }

        return previousUsers // context for rollback
      },
      onError: (error, _, previousUsers) => {
        console.log(error)

        if (previousUsers) {
          queryClient.setQueryData(userKeys.list(), previousUsers)
        }
      },
      onSettled: () => queryClient.invalidateQueries(userKeys.all),
    },
  )
}

export { useCreateUserMutation }
