import { MenuOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const TableContainer = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background-color: rgba(240, 242, 245, 0.5);
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: #efefef;
  }

  .ant-table-wrapper .ant-table-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 0;
  }

  .ant-table-content > table > thead > tr > th {
    border: 1px solid var(--conditional-divider, rgba(0, 0, 0, 0.06));
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0 !important;
    background-color: #f4f4f4;
  }

  /* .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border: 0;
  } */
`

export const TableActionIcon = styled(MenuOutlined)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 18px;

  transition: all 0.2s ease;

  :hover {
    filter: brightness(1.2);
  }
`

export const Text = styled.span`
  text-transform: capitalize;
`
