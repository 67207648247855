import { Link } from 'react-router-dom'

import { Layout } from 'antd'
import styled from 'styled-components'

export const Container = styled(Layout)`
  height: 100vh;
  width: 100vw;
`

export const NavLink = styled(Link)`
  cursor: pointer;
`

export const LogoHeader = styled.header`
  align-self: flex-start;
  justify-self: flex-start;
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .ant-typography {
    font-size: 11px;
    text-transform: uppercase;
    line-height: initial;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px;

  main {
    width: 100%;
  }
`

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 48px 0 0;

  h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: initial;
    color: ${({ theme }) => theme.colors.black};
  }

  .description {
    font-size: 16px;
    display: block;
    margin-bottom: 6px;
    line-height: initial;
    color: ${({ theme }) => theme.colors.text};
  }
`

export const ImageArea = styled(Layout.Sider)`
  flex: 0 0 65% !important;
  max-width: 65% !important;
  width: 100% !important;
  height: 100%;

  background: url('https://img.freepik.com/free-vector/site-stats-concept-illustration_114360-1434.jpg?w=900&t=st=1663600700~exp=1663601300~hmac=3ea6dbb5623aef09d723dfda33d2dea0d215e4f15bbd278976f63c46dfb2d7dd')
    center center / cover rgb(76, 175, 80) !important;

  background-repeat: no-repeat;
  display: block;
  position: relative;

  &::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.44), rgba(128, 195, 67, 0.68));
    height: 100%;
    width: 100%;
    inset: 0;
    content: ' ';
    display: block;
    position: absolute;
  }
`
