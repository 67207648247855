import toast from 'react-hot-toast'
import { AiOutlineCalendar, AiOutlineCheckCircle } from 'react-icons/ai'

import { EditOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Modal, Switch, Typography } from 'antd'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'

import * as S from './styles'

import { formatCurrency } from '@/common'
import { TableAction } from '@/components'
import { NotificationConfig, useEditNotification } from '@/features/notifications'
import { getProduct, productKeys } from '@/features/product'

type AdminNotificationTableProps = {
  data: NotificationConfig[] | undefined
  isLoading: boolean
  handleSelectNotification: (notification: NotificationConfig) => void
}

const AdminNotificationTable = ({
  data,
  isLoading,
  handleSelectNotification,
}: AdminNotificationTableProps) => {
  const queryClient = useQueryClient()
  const editNotificationMutation = useEditNotification()

  function prefetchProduct(productId: string) {
    queryClient.prefetchQuery(productKeys.detail(productId), () => getProduct(productId))
  }

  async function handleChangeStatus(notification: NotificationConfig) {
    const updatedNotification = {
      ...notification,
      start_date: notification.start_date.split(' ')[0],
      end_date: notification.end_date ? notification.end_date.split(' ')[0] : null,
      status: !notification.status,
    }

    const promise = editNotificationMutation.mutateAsync({
      notification: updatedNotification,
      notificationId: notification.id,
    })

    toast.promise(promise, {
      loading: 'Alterando status do alerta...',
      success: 'Status do alerta alterado com sucesso!',
      error: 'Não foi possível alterar o status do alerta.',
    })
  }

  function confirmStatus(notification: NotificationConfig) {
    Modal.confirm({
      centered: true,
      closable: true,
      maskClosable: true,
      title: 'Deseja alterar o status do alerta?',
      icon: <ExclamationCircleFilled />,
      okText: 'Alterar',
      cancelText: 'Cancelar',
      okButtonProps: {
        className: 'modal-confirm-button',
      },
      cancelButtonProps: {
        className: 'modal-cancel-button',
      },
      onOk: () => {
        handleChangeStatus(notification)
      },
    })
  }

  const tableColumns: ColumnsType<NotificationConfig> = [
    {
      title: 'n°',
      dataIndex: '',
      width: '10px',
      align: 'center',
      render: (_, __, index) => {
        const SortColor = index % 2 === 0 ? <>{index + 1}</> : <S.GreyText>{index + 1}</S.GreyText>

        return <>{index + 1}</>
      },
    },
    {
      title: 'Marca',
      dataIndex: 'marca',
      width: '60px',
      render: () => <Typography.Text>Acer</Typography.Text>,
    },
    {
      title: 'Produto',
      dataIndex: 'modelo',
      width: 300,
      render: (product, record) => (
        <S.ProductLink $isDark={true} to={`/produtos/${record.productId}`}>
          {product}
        </S.ProductLink>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'alert_description',
      width: '390px',
      render: (description) => <S.Description>{description}</S.Description>,
    },
    {
      title: 'Preço de Referência',
      dataIndex: 'alert_price_reference',
      width: '110px',
      align: 'center',
      render: (referencePrice) => (
        <Typography.Text strong>{formatCurrency({ value: referencePrice })}</Typography.Text>
      ),
    },
    {
      title: 'Prazo do alerta',
      dataIndex: ['start_date', 'end_date'],
      width: '250px',
      align: 'center',
      render: (_, record) => (
        <S.AlertContainer>
          <AiOutlineCalendar />
          {dayjs(record.start_date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}
          <S.ArrowRight />
          <AiOutlineCheckCircle />
          {dayjs(record.end_date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}
        </S.AlertContainer>
      ),
    },
    {
      title: 'Variação',
      dataIndex: ['alert_percent', 'alert_price'],
      width: '94px',
      align: 'center',
      render: (_, record) => (
        <Typography.Text strong>
          {record.alert_percent
            ? `${record.alert_percent}%`
            : `${formatCurrency({ value: record.alert_price! })}`}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 50,
      align: 'center',
      render: (status, notification) => (
        <Switch
          checkedChildren="Ativo"
          unCheckedChildren="Inativo"
          checked={status}
          size="small"
          onClick={() => confirmStatus(notification)}
        />
      ),
    },
    {
      title: 'Ações',
      dataIndex: '',
      width: '66px',
      align: 'center',
      render: (_, notification) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <Button
                icon={<EditOutlined />}
                type="link"
                onClick={() => handleSelectNotification(notification)}
                onMouseEnter={() => prefetchProduct(notification.productId)}
              >
                Editar
              </Button>
            ),
            // onClick: () => updateProductPrices(product.id),
          },
          // {
          //   key: '2',
          //   label: <Button icon={<DeleteOutlined />}>Deletar</Button>,
          // },
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  return (
    <S.TableContainer>
      <Table
        bordered
        size="middle"
        rowKey={(record) => `${record.productId}-${crypto.randomUUID()}`}
        pagination={false}
        dataSource={data ? data : []}
        footer={() => ''}
        columns={tableColumns}
        rowClassName={(_, index) => (index % 2 === 0 ? 'light-row' : 'dark-row')}
        loading={isLoading}
      />
    </S.TableContainer>
  )
}

export { AdminNotificationTable }
