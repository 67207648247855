import { CloseOutlined } from '@ant-design/icons'
import { Button, Layout, Divider as AntdDivider, Tooltip } from 'antd'
import styled, { css } from 'styled-components'

const { Sider } = Layout

export const Container = styled(Sider)`
  max-width: 50px !important;
  width: 100% !important;
  min-width: fit-content !important;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};

  padding: 16px 0;

  .ant-layout-sider-children {
    width: 100%;
  }

  button.ant-btn {
    width: 100%;
  }
`

export const SidebarTrigger = styled(Button)`
  .anticon {
    color: ${({ theme }) => theme.colors.brand};
    font-size: 20px;

    :hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

export const TooltipFilter = styled(Tooltip)`
  width: 100%;
`

export const Divider = styled(AntdDivider)`
  width: 100%;
  margin: 8px 0;
`

export const ClearButton = styled(Button)`
  border-radius: 5px;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;

  button {
    padding: 0;
  }

  .ant-typography {
    font-size: 16px;
    font-weight: 600;
  }
`

export const SavePresetArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  .ant-typography {
    color: gray;
    font-size: 14px;
    font-weight: 400;
    line-height: inherit;
  }

  input {
    border-radius: 5px;
  }

  transition: all 0.2s ease;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PresetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Preset = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  padding: 4px 6px;
`

export const PresetButton = styled(Button)<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;

  padding: 0;
  margin: 8px auto;

  background-color: transparent;
  border-radius: 9999px;
  border: 2px solid #cfcfcf;
  color: ${({ theme }) => theme.colors.brand};

  position: relative;

  :hover {
    border-color: ${({ theme }) => theme.colors.brand};
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.brand)};
    box-shadow: none;
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      color: ${theme.colors.white};
      background: ${theme.colors.brand};
      border-color: transparent;

      :active,
      :focus,
      :hover {
        border-color: transparent;
        color: ${theme.colors.white};
        box-shadow: none;
        background: ${theme.colors.brand};
      }

      :hover {
        filter: brightness(1.1);
      }
    `}
`

export const DeletePresetButton = styled(CloseOutlined)`
  height: fit-content;
  width: fit-content;
  padding: 0;

  position: absolute;
  top: -3px;
  right: 2px;

  background: transparent;
  color: ${({ theme }) => theme.colors.brand};
  font-size: 10px;
  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    filter: brightness(1.2);
  }
`

export const SavePresetButton = styled(Button)`
  border: none;
  border-radius: 4px;
  box-shadow: none;
  outline: none;

  transition: all 0.2s ease;

  color: ${({ theme }) => theme.colors.brand};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.brand};

    /* background-color: 'transparent'; */

    filter: brightness(0.85);

    border: none;
    outline: none;
  }
`
