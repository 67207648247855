import { signOut as clearAuthStore, filterStore as useFilterStore } from '@/app'
import { resetFilters } from '@/app/stores/filters'

export const useSignOut = () => {
  const clearLastActivePreset = useFilterStore((s) => s.setLastActivePreset)

  function signOut() {
    resetFilters()
    clearLastActivePreset(null)
    clearAuthStore()
  }

  return { signOut }
}
