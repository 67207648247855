import { useMutation, useQueryClient } from '@tanstack/react-query'

import { NotificationPayload } from '../../types'

import { api } from '@/services'

type CreateNotificationMutation = {
  notification: NotificationPayload
}

const useCreateNotification = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ notification }: CreateNotificationMutation) => {
      return api.post('/alerts', notification)
    },
    {
      // onMutate: async () => await queryClient.cancelQueries(),
    },
  )
}

export { useCreateNotification }
