import { useState } from 'react'
import { toast } from 'react-hot-toast'

import { SearchOutlined } from '@ant-design/icons'
import { Image, Select, Skeleton, Tooltip, Typography } from 'antd'

import * as S from './styles'

import { formatCurrency } from '@/common'
import { Modal } from '@/components'
import {
  ProductTag,
  useAddCategoryMutation,
  useProduct,
  useCategories,
  useProductCategories,
  useRemoveProductCategoryMutation,
} from '@/features/product'

type CategoryModalProps = {
  isOpen: boolean
  closeModal: () => void
  productId?: string
  products?: React.Key[]
  clearCheckedProducts?: () => void
}

const CategoryModal = ({
  isOpen,
  closeModal,
  productId,
  products,
  clearCheckedProducts,
}: CategoryModalProps) => {
  const { data: product, isLoading: isProductLoading } = useProduct(
    (productId as string) || (products?.[0] as string),
  )

  const { data: categories, isLoading: isCategoriesLoading } = useCategories()
  const { data: productCategories, isInitialLoading: isProductCategoriesLoading } =
    useProductCategories((productId as string) || ((products && products[0]) as string))

  const removeCategoryMutation = useRemoveProductCategoryMutation()

  const selectOptions = categories?.map((category) => ({
    value: category.name,
    label: category.name,
  }))

  const addCategoryMutation = useAddCategoryMutation()

  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [text, setText] = useState<string | undefined>(undefined)

  function handleSelectCategory(selectedCategories: string[]) {
    const minCategoryLength = 2

    const validCategories = selectedCategories.filter(
      (category) => category.length >= minCategoryLength,
    )

    if (selectedCategories.length !== validCategories.length) {
      toast.error('A categoria deve ter no mínimo 2 caracteres')
    }

    setText(undefined)
    setSelectedCategories(validCategories)
  }

  function handleDropdownVisibleState(visible: boolean) {
    if (text) {
      setIsDropdownVisible(false)
      return
    }

    setIsDropdownVisible(visible)
  }

  function handleCreateCategory(text: string) {
    setIsDropdownVisible(false)
    setText(text.length > 15 ? text.slice(0, 15) : text)
  }

  function handleRemoveSelectedCategory(category: string) {
    const filteredCategories = selectedCategories.filter((c) => c !== category)
    setSelectedCategories(filteredCategories)
  }

  async function handleRemoveProductCategory(categoryId: string) {
    const product = productId || ((products && products[0]) as string)

    const promise = removeCategoryMutation.mutateAsync({
      productId: product,
      categoryId,
    })

    await toast.promise(promise, {
      loading: 'Removendo categoria...',
      success: 'Categoria removida com sucesso!',
      error: 'Erro ao remover categoria',
    })
  }

  function getToastMessage(products: string[]) {
    let successMessage = ''
    let alredyHasCategoryErrorMessage = ''

    if (products.length === 1) {
      successMessage = 'Produto categorizado com sucesso!'
      alredyHasCategoryErrorMessage = 'Produto ja possui essa categoria'
    }

    if (products.length > 1) {
      successMessage = 'Produtos categorizados com sucesso!'
      alredyHasCategoryErrorMessage = 'Produtos ja possuem essas categorias'
    }

    return { successMessage, alredyHasCategoryErrorMessage }
  }

  async function handleSubmit() {
    let productIds: string[]

    if (products && products.length > 0) {
      productIds = products as string[]
    } else {
      productIds = [productId as string]
    }

    const promise = addCategoryMutation.mutateAsync({
      categoria: selectedCategories,
      product: productIds,
    })

    const { successMessage, alredyHasCategoryErrorMessage } = getToastMessage(productIds)

    await toast.promise(promise, {
      loading: 'Categorizando...',
      success: successMessage,
      error: (err) => {
        if (err.response.data.message === 'Produto ja possui as categorias') {
          return alredyHasCategoryErrorMessage
        } else {
          return 'Erro ao categorizar'
        }
      },
    })

    clearCheckedProducts?.()
    closeModal()
  }

  const SkeletonPage = () => (
    <>
      {products && products.length > 1 ? (
        <S.SkeletonContent>
          <S.SkeletonFirstContainer>
            <Skeleton.Input active size="large" style={{ width: '323px', height: '80px' }} />
          </S.SkeletonFirstContainer>

          <S.SkeletonSecondContainer>
            <S.SkeletonAddCategoryArea>
              <Skeleton.Input active size="small" style={{ width: '50%' }} />
              <Skeleton.Input active size="large" block />
            </S.SkeletonAddCategoryArea>
          </S.SkeletonSecondContainer>
        </S.SkeletonContent>
      ) : (
        <S.SkeletonContent>
          <S.SkeletonFirstContainer>
            <S.SkeletonImageArea>
              <Skeleton.Image active />
            </S.SkeletonImageArea>

            <S.SkeletonTextArea>
              <Skeleton.Input active size="small" block />
              <Skeleton.Input active size="small" block />
              <Skeleton.Input active size="small" block />
            </S.SkeletonTextArea>
          </S.SkeletonFirstContainer>

          <S.SkeletonSecondContainer>
            <S.SkeletonAddCategoryArea>
              <Skeleton.Input active size="small" style={{ width: '30%' }} />
              <Skeleton.Input active size="large" style={{ width: '50%' }} />
            </S.SkeletonAddCategoryArea>
          </S.SkeletonSecondContainer>
        </S.SkeletonContent>
      )}
    </>
  )

  return (
    <>
      <Modal
        title={
          products && products.length > 1
            ? 'Categorizar produtos selecionados'
            : 'Categorizar produto'
        }
        centered
        open={isOpen}
        onOk={closeModal}
        onCancel={closeModal}
        width={products && products.length > 1 ? 450 : 717}
        footer={
          <>
            <S.BackButton onClick={closeModal} disabled={addCategoryMutation.isLoading}>
              Voltar
            </S.BackButton>
            <S.SubmitButton
              type="primary"
              onClick={handleSubmit}
              disabled={addCategoryMutation.isLoading}
            >
              Finalizar
            </S.SubmitButton>
          </>
        }
      >
        <S.Content>
          {isProductLoading && isProductCategoriesLoading ? (
            <SkeletonPage />
          ) : (
            <>
              {(productId || (products && products.length === 1)) && (
                <>
                  <S.ProductArea>
                    <Image
                      style={{ objectFit: 'contain' }}
                      fallback="/src/assets/fallback-image.png"
                      src={product?.informacoes.url_imagem}
                      alt={product?.modelo}
                      width={155}
                      height={121}
                      preview={false}
                    />

                    <S.InfoContainer>
                      <S.TitleProduct>{product?.modelo}</S.TitleProduct>

                      <S.ValueProduct>
                        {product?.precos.sugerido ? (
                          <Typography.Text>
                            Preço sugerido:{' '}
                            <Typography.Text strong>
                              {formatCurrency({ value: product?.precos.sugerido as number })}
                            </Typography.Text>
                          </Typography.Text>
                        ) : (
                          <Typography.Text>
                            Preço médio:{' '}
                            <Typography.Text strong>
                              {formatCurrency({ value: product?.precos.media as number })}
                            </Typography.Text>
                          </Typography.Text>
                        )}
                      </S.ValueProduct>
                    </S.InfoContainer>
                  </S.ProductArea>
                </>
              )}

              {products && products.length > 1 && (
                <S.ProductCard>
                  <S.AreaTitle fontSize={24}>
                    Produtos selecionados:{' '}
                    <S.SelectedProducts>
                      {products.length < 10 ? `0${products.length}` : products.length}
                    </S.SelectedProducts>
                  </S.AreaTitle>
                </S.ProductCard>
              )}

              {(productId || (products && products.length === 1)) &&
                productCategories &&
                productCategories.length > 0 && (
                  <>
                    <S.Divider />

                    <S.CategoriesContainer>
                      <S.CategoryArea>
                        <S.AreaTitle>
                          Categorias do produto{' '}
                          <Tooltip title="Categorias ja atriuidas a este produto. Clique no X para remove-las.">
                            <S.QuestionMark />
                          </Tooltip>
                        </S.AreaTitle>

                        <S.CategoriesList>
                          {productCategories?.map((category) => {
                            return (
                              <ProductTag
                                key={category.id}
                                tagId={category.id}
                                closable
                                handleRemoveTag={() => handleRemoveProductCategory(category.id)}
                              >
                                {category.name}
                              </ProductTag>
                            )
                          })}
                        </S.CategoriesList>
                      </S.CategoryArea>
                    </S.CategoriesContainer>
                  </>
                )}

              <S.Divider />

              <S.CategoriesContainer>
                <S.CategoryArea>
                  <S.AreaTitle>
                    Adicionar categorias{' '}
                    <Tooltip title="Após categorizar este produto, ele aparecerá em listas filtradas por essa categoria.">
                      <S.QuestionMark />
                    </Tooltip>
                  </S.AreaTitle>

                  <S.InputArea>
                    <Select
                      suffixIcon={<SearchOutlined />}
                      showSearch={false}
                      options={selectOptions}
                      loading={isCategoriesLoading}
                      maxTagCount={0}
                      value={selectedCategories}
                      onChange={(selectedCategories: string[]) =>
                        handleSelectCategory(selectedCategories)
                      }
                      searchValue={text}
                      onSearch={(text) => handleCreateCategory(text)}
                      mode="tags"
                      open={isDropdownVisible}
                      onDropdownVisibleChange={(visible) => handleDropdownVisibleState(visible)}
                      disabled={addCategoryMutation.isLoading}
                    />
                    <S.CreateButton type="primary" disabled={addCategoryMutation.isLoading}>
                      {text ? 'Criar categoria' : 'Vincular categoria'}
                    </S.CreateButton>
                  </S.InputArea>
                </S.CategoryArea>

                {selectedCategories.length > 0 && (
                  <S.SelectArea>
                    <S.CategoriesText>
                      Categorias que serão vinculadas
                      <Tooltip title="Categorias selecionadas para serem adicionadas ao produto.">
                        <S.QuestionMark />
                      </Tooltip>
                    </S.CategoriesText>

                    <S.CategoriesList>
                      {selectedCategories &&
                        selectedCategories.length > 0 &&
                        selectedCategories?.map((category, index) => (
                          <ProductTag
                            key={index}
                            tagId={category}
                            closable
                            handleRemoveTag={handleRemoveSelectedCategory}
                            isDisabled={addCategoryMutation.isLoading}
                          >
                            {category}
                          </ProductTag>
                        ))}
                    </S.CategoriesList>
                  </S.SelectArea>
                )}
              </S.CategoriesContainer>
            </>
          )}
        </S.Content>
      </Modal>
    </>
  )
}

export { CategoryModal }
