import { Link } from 'react-router-dom'

import { TagOutlined } from '@ant-design/icons'
import { Divider as AntdDivider, Button, Typography } from 'antd'
import styled, { css } from 'styled-components'

import { hexToRgba } from '@/common'

type TooltipTableTextProps = {
  isDisabled: boolean
}

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReportButton = styled(Button)`
  padding: 0;
`

export const Title = styled.h2`
  font-size: 18px;
  color: #414243;
`

export const TotalProducts = styled(Title)`
  color: #434343;
  font-weight: 700;
`
export const TotalProductsValue = styled(Title)`
  color: #3d85c6;
  font-weight: 700;
`

export const Divider = styled(AntdDivider)<{ color?: string; thickness?: number }>`
  color: ${({ color }) => (color ? color : '#414243')};
  border-left: ${({ thickness }) => (thickness ? `${thickness}px` : '1px')} solid
    ${({ color }) => (color ? color : '#414243')};
  border-color: #414243;
  height: 18px;
  margin: 0 4px;
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const HeaderText = styled(Typography.Text)`
  font-size: 16px;
  color: #434343;
`

export const CheckAllButton = styled(Button)`
  font-size: 16px;
  padding: 0;
  color: ${({ theme }) => theme.colors.darkGreen};

  :hover {
    color: ${({ theme }) => hexToRgba(theme.colors.darkGreen, 0.7)} !important;
  }
`

export const CategorizeButton = styled(Button)`
  background-color: #fff;
  border-color: ${({ theme }) => theme.colors.darkGreen};
  color: ${({ theme }) => theme.colors.darkGreen};

  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => hexToRgba(theme.colors.darkGreen, 0.7)} !important;
    border-color: ${({ theme }) => hexToRgba(theme.colors.darkGreen, 0.7)} !important;
  }
`

export const TableContainer = styled.div`
  .ant-table-thead > tr > th {
    background-color: rgba(240, 242, 245, 0.5);
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: rgba(240, 242, 245, 0.5);
  }

  .ant-table-column-sort {
    background: none;
  }
`

export const ExpandedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  img {
    width: 130px;
    height: 100px;
  }

  > div {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    row-gap: 4px;

    .ant-tag {
      height: fit-content;
      text-transform: capitalize;
    }
  }
`

export const TableText = styled.span`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  color: #000000d9;
`

export const TooltipText = styled.span`
  font-size: 14px;
  color: #000000d9;
`

export const Text = styled.span`
  text-transform: capitalize;
`

export const StoreLink = styled.a<TooltipTableTextProps>`
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #d9d9d9;
      cursor: not-allowed;

      :hover {
        color: #d9d9d9;
        filter: brightness(1);
      }
    `}
`

export const TooltipTableText = styled.span<TooltipTableTextProps>`
  color: #000000d9;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #d9d9d9;
      cursor: not-allowed;
    `}
`

export const TooltipTableFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  a {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.brand};
    margin-left: auto;
    padding: 0;
  }
`

export const StoreIcon = styled(TagOutlined)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 16px;
  transform: rotateY(180deg);
`

export const ProductLink = styled(Link)`
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }
`

export const FilterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ActiveFiltersArea = styled.div`
  display: flex;
  align-items: center;

  gap: 12px;
  flex-wrap: wrap;
`

export const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: flex-end;

  font-size: 16px;
  padding: 0;
  color: ${({ theme }) => theme.colors.darkGreen};

  :hover {
    color: ${({ theme }) => hexToRgba(theme.colors.darkGreen, 0.7)} !important;
  }

  strong {
    font-weight: 700;
  }
`

export const Preset = styled.span`
  text-transform: capitalize;
  margin-left: 4px;
`
