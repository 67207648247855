import styled from 'styled-components'

type TooltipListProps = {
  divider?: boolean
}

type TooltipMarketplaceProps = {
  color: string
}

export const TooltipContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  min-width: 264px;
  width: 100%;

  max-height: 300px;
  overflow-y: auto;
  pointer-events: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(250, 250, 250, 0.7);
    width: 16px;
    background: #fafafa;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #c7c7c7;
  }
`

export const TooltipDate = styled.p`
  text-align: right;
`

export const TooltipList = styled.ul<TooltipListProps>`
  list-style: none;
  border-top: 1px solid transparent;
  border-color: ${({ divider }) => (divider ? '#d9d9d9' : 'transparent')};
`

export const TooltipMarketplace = styled.span<TooltipMarketplaceProps>`
  color: ${({ color }) => color};
  font-weight: 400;
  text-transform: uppercase;
`

export const TooltipItem = styled.li`
  padding: 4px 0;
  text-transform: capitalize;
`

export const TooltipOffer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const TooltipSeller = styled.span`
  font-weight: 500;
  font-size: 14px;
`

export const TooltipPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const OfferPrice = styled.span`
  font-weight: 500;
  font-size: 14px;
`

export const OfferTime = styled.span`
  font-size: 10px;
  color: #999;
`

export const LegendList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 32px;
  padding-left: 60px;
  overflow-x: auto;
  overflow-y: hidden;
`

export const LegendItem = styled.li`
  width: fit-content;
  max-height: 16px;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;

  white-space: nowrap;
`

export const Legend = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ color }) => color};
`
