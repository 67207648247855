import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

import { Table, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { NotificationMarketplace } from '@/features/notifications'

const columns: ColumnsType<NotificationMarketplace> = [
  {
    title: 'Loja',
    dataIndex: 'store',
    key: 'store',
  },
  {
    title: 'Seller',
    dataIndex: 'seller',
    key: 'seller',
  },
  {
    title: 'Preço Atual',
    dataIndex: 'price_atual',
    key: 'price_atual',
    align: 'center',
    render: (price: string) => <Typography.Text strong>{`R$ ${price}`}</Typography.Text>,
  },
  {
    title: 'Percentual',
    dataIndex: 'percent',
    key: 'percent',
    align: 'center',
    render: (percent, record) => {
      const isPositive = record.price_up

      return (
        <S.Percentage $isPositive={isPositive}>
          {isPositive ? <AiFillCaretUp /> : <AiFillCaretDown />}
          {percent}%
        </S.Percentage>
      )
    },
  },
]

type ViewNotificationModalTableProps = {
  data: NotificationMarketplace[]
}

const ViewNotificationModalTable = ({ data }: ViewNotificationModalTableProps) => {
  return (
    <S.TableContainer>
      <Table
        pagination={false}
        rowClassName={(_, index) => (index % 2 === 0 ? 'light-row' : 'dark-row')}
        dataSource={data}
        rowKey={(record) => `${record.store}-${crypto.randomUUID()}`}
        columns={columns}
        size="middle"
      />
    </S.TableContainer>
  )
}

export { ViewNotificationModalTable }
