import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { NotificationSettingsResponse } from '../types'
import { notificationKeys } from './query-keys'

import { api } from '@/services'

async function fetchNotifications({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof notificationKeys)['createdList']>>) {
  const [, , , page] = queryKey

  const { data } = await api.get<NotificationSettingsResponse>(`/alerts?page=${page}`)

  return data
}

const useCreatedNotifications = (page: number) => {
  return useQuery(notificationKeys.createdList(page), fetchNotifications)
}

export { useCreatedNotifications }
