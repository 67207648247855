import { Form, FormInstance, Input, Select } from 'antd'

import { useGroups } from '../../../queries'
import type { NewUserCredentials } from '../../../types'

type CreateUserFormProps = {
  form: FormInstance<NewUserCredentials>
  handleCreateUser: (values: NewUserCredentials) => void
}

const CreateUserForm = ({ form, handleCreateUser }: CreateUserFormProps) => {
  const { data: groups, isLoading } = useGroups()

  const selectGroups = groups?.map((group) => ({
    value: group.groupName,
    label: group.groupName,
  }))

  return (
    <Form
      form={form}
      name="register"
      initialValues={{
        name: '',
        username: '',
        password: '',
        confirm: '',
      }}
      onFinish={handleCreateUser}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        rules={[{ required: true, message: 'Digite o seu nome.', whitespace: true }]}
      >
        <Input placeholder="Nome" autoComplete="off" />
      </Form.Item>

      <Form.Item
        name="username"
        rules={[
          {
            type: 'email',
            message: 'Digite um e-mail válido.',
          },
          {
            required: true,
            message: 'Digite o seu e-mail.',
          },
        ]}
      >
        <Input placeholder="Email" autoComplete="off" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            pattern: new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,25}$'),
            message:
              'A senha deve conter letra maiuscula, minuscula, número, símbolo (#?!@$%^&*-) e pelo menos 8 caracteres.',
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Senha temporária" autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        name="groups"
        rules={[
          {
            required: true,
            message: 'Selecione um grupo.',
          },
        ]}
      >
        <Select placeholder="Grupo" loading={isLoading} options={selectGroups} />
      </Form.Item>
    </Form>
  )
}

export { CreateUserForm }
