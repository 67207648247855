import { LoadingOutlined } from '@ant-design/icons'
import { SpinProps } from 'antd/lib/spin'

import { Loader } from './styles'

type LoadingProps = SpinProps

const Loading = ({ ...props }: LoadingProps) => {
  const loadingIcon = <LoadingOutlined spin />

  return <Loader indicator={loadingIcon} {...props} />
}

export { Loading }
