import dayjs from 'dayjs'

import { NotificationConfig, NotificationForm, fixDecimalPrecision } from '@/features/notifications'

function formatNotificationData(notification: NotificationConfig): NotificationForm {
  const variation = notification.alert_price || notification.alert_percent

  const startDate = dayjs(notification.start_date, 'DD/MM/YYYY HH:mm') as unknown as string

  const endDate = notification.end_date
    ? (dayjs(notification.end_date, 'DD/MM/YYYY HH:mm') as unknown as string)
    : undefined

  console.log(notification.emails_externos)

  // format notification data to form
  const formattedFormValues: NotificationForm = {
    data_inicio: startDate,
    data_fim: endDate,
    descricao: notification.alert_description,
    notificacao_usuarios: notification.usernames,
    preco_referencia: fixDecimalPrecision(notification.alert_price_reference).replace('.', ','), // replace to satisfy the input mask
    variacao: String(variation),
    variation_type: notification.alert_percent ? 'percent' : 'monetary',
    email_usuarios_externos: notification.emails_externos ? notification.emails_externos : [],
    email_usuarios_sistema: notification.emails_internos ? notification.emails_internos : [],
    mensagem_email: notification.mensagem_email,
  }

  return formattedFormValues
}

export { formatNotificationData }
