import { Image, Typography } from 'antd'

import * as S from './styles'

import acerLogo from '@/assets/acer-logo-green.svg'

type AccessLayoutProps = {
  title?: string
  description?: string
  children: React.ReactNode
}

const AccessLayout = ({ title, description, children }: AccessLayoutProps) => {
  return (
    <S.Container>
      <S.Content>
        <S.LogoHeader>
          <S.NavLink to="/auth/login">
            <Image src={acerLogo} width={120} height={32} preview={false} />
          </S.NavLink>
          <Typography>Product Track Tool</Typography>
        </S.LogoHeader>

        <main>
          <S.TextArea>
            <Typography.Title level={1}>{title}</Typography.Title>
            <Typography className="description">{description}</Typography>
          </S.TextArea>
          {children}
        </main>
      </S.Content>

      <S.ImageArea />
    </S.Container>
  )
}

export { AccessLayout }
