export const activeFilterNames = new Map([
  ['marca', 'Marca'],
  ['marketplace', 'Lojas'],
  ['disponivel', 'Disponibilidade'],
  ['text_search', 'Palavra Chave'],
  ['ram', 'Memória RAM'],
  ['hd', 'HD'],
  ['processador', 'Processador'],
  ['processador_serie', 'Processador série'],
  ['sistema_operacional', 'Sistema Operacional'],
  ['placa_video', 'Placa de Vídeo'],
  ['tela', 'Tela'],
  ['resolucao_tela', 'Resolução da Tela'],
  ['tipo_hd', 'Tipo de HD'],
  ['tags', 'Tags'],
  ['categorias', 'Categorias'],
  ['seller', 'Sellers'],
  ['velocidade_processador', 'Velocidade do Processador'],
  ['categorias', 'Categorias'],
  ['cashback', 'Cashback'],
  ['frete_gratis', 'Frete Grátis'],
  ['patrocinado', 'Patrocinado'],
  ['cupom', 'Cupom'],
  ['novos', 'Produtos novos'],
  ['review', 'Possui review'],
])
