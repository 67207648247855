import toast from 'react-hot-toast'
import { useLocation, Navigate } from 'react-router-dom'

import * as S from './styles'

import {
  ResetFirstPasswordCredentials,
  ResetFirstPasswordForm,
  useResetPasswordMutation,
} from '@/features/auth'
import { AccessLayout } from '@/layouts'

const ResetFirstPasswordPage = () => {
  const { search } = useLocation()
  const resetPasswordMutation = useResetPasswordMutation()

  const email = search.split('=')[1]

  const description = 'Bem vindo! Como é seu primeiro acesso, por favor insira uma nova senha.'

  function handleResetPassword({ password }: ResetFirstPasswordCredentials) {
    const data = {
      email,
      newPassword: password,
    }

    resetPasswordMutation.mutateAsync(data)
  }

  if (!email) {
    toast.error('Algo deu errado, tente novamente.')
    return <Navigate to="/auth/login" replace />
  }

  return (
    <AccessLayout title="Registre uma nova senha" description={description}>
      <S.Container>
        <ResetFirstPasswordForm
          handleResetPassword={handleResetPassword}
          isLoading={resetPasswordMutation.isLoading}
        />
      </S.Container>
    </AccessLayout>
  )
}

export { ResetFirstPasswordPage }
