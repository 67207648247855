import { Layout } from 'antd'
import styled from 'styled-components'

export const Container = styled(Layout)`
  min-height: 100vh;
  height: 100%;
`

export const SidebarContainer = styled(Layout)`
  height: 100%;

  .ant-layout-sider {
    background-color: #fff;
  }
`

export const ContentContainer = styled(Layout)`
  padding: 0 24px 24px;
`

export const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`
