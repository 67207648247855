import styled from 'styled-components'

export const Container = styled.div`
  form {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0;
  }

  button {
    width: 100%;
  }

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error {
    background-color: white;

    :hover,
    :focus,
    :active,
    :focus-within {
      background-color: white;
    }
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:focus-within {
    background-color: white;
  }
`
