import toast from 'react-hot-toast'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { tagKeys } from '../../../queries'
import type { Tag } from '../../../types'

import { api } from '@/services'

type NewTagMutation = {
  tagName: string
  productId: string
}

const useAddTagMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ tagName, productId }: NewTagMutation) => {
      const productTags = queryClient.getQueryData<Tag[]>(tagKeys.product(productId))

      const productHasTag = productTags?.some((productTag) => {
        return productTag.tag.toLowerCase() === tagName.toLowerCase()
      })

      if (productHasTag) {
        toast.dismiss()

        throw new Error('Produto ja possui essa tag')
      }

      return api.post('/tag', {
        name: tagName,
        product: productId,
      })
    },
    {
      onMutate: async ({ productId }) => {
        await queryClient.cancelQueries(tagKeys.all)

        const previousTags = queryClient.getQueryData<Tag[]>(tagKeys.product(productId))

        return previousTags
      },
      onError: (error: any, { productId }, previousTags) => {
        console.log(error)

        if (error.message === 'Produto ja possui essa tag') {
          toast.error(error.message)
        }

        if (previousTags) {
          queryClient.setQueryData(tagKeys.product(productId), previousTags)
        }
      },
      onSettled: () => queryClient.invalidateQueries(tagKeys.all),
    },
  )
}

export { useAddTagMutation }
