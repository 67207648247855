import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import type { InternalMarketplaces } from '../../types'
import { productKeys } from '../query-keys'

import type { ActiveSorting } from '@/common'
import { api } from '@/services'

type ProductMarketplacesQuery = {
  productId: string
  page: number
  tableSort: ActiveSorting
}

async function fetchMarketplaces({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['marketplace']>>) {
  const [, , productId, , { page, tableSort }] = queryKey

  const { data } = await api.post<InternalMarketplaces>(
    `/produto/${productId}/marketplace?limit=5`,
    {
      ordenacao: tableSort,
      page,
    },
  )

  return data
}

const useMarketplaces = ({ productId, page = 1, tableSort }: ProductMarketplacesQuery) => {
  return useQuery(productKeys.marketplace(productId, page, tableSort), fetchMarketplaces, {
    keepPreviousData: true,
  })
}

export { useMarketplaces }
