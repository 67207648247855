import { CloseOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import styled from 'styled-components'

export const DropdownIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: ${({ theme }) => theme.colors.brand};

  span {
    font-size: 12px;
  }
`

export const MenuItem = styled(Menu.Item)`
  text-transform: capitalize;
`

export const DropdownButton = styled(Dropdown.Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  a {
    font-weight: 600;
    color: #414243;
  }

  .ant-dropdown-trigger {
    width: 100%;
    min-width: 24px;
    width: 48px;

    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn {
    :hover,
    :focus {
      color: ${({ theme }) => theme.colors.brand};
      border-color: ${({ theme }) => theme.colors.brand};
    }

    :first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    :last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`

export const CloseIcon = styled(CloseOutlined)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.brand};
`

export const RemoveFilterButton = styled(CloseIcon)`
  position: relative;
  top: -1px;
  left: -6px;
`
