import { Divider } from 'antd'

import { activeFilterNames } from '../../constants'
import * as S from './styles'

interface TooltipContentProps {
  name: string
  id: string
  data: [string, string | boolean | string[]][]
}

const PresetFilterTooltip = ({ name, data, id }: TooltipContentProps) => {
  return (
    <S.TooltipContainer>
      <S.TooltipTitle>{name}</S.TooltipTitle>

      <Divider style={{ margin: '4px 0', border: '1px solid #c7c7c7' }} />

      <S.FiltersContainer>
        {data.map(([key, value], i: number, arr) => {
          const filterLabel = activeFilterNames.get(key)
          const shouldRenderDivider = i !== arr.length - 1

          return (
            <>
              <S.FilterContainer key={id}>
                <S.FilterName>{filterLabel}: </S.FilterName>

                <S.TooltipText>
                  {typeof value === 'string' && `${value}`}

                  {typeof value === 'boolean' && `${value ? 'Disponível' : 'Indisponível'}`}

                  {Array.isArray(value) &&
                    (value.length === 1
                      ? `${value.length} selecionado`
                      : `${value.length} selecionados`)}
                </S.TooltipText>
              </S.FilterContainer>

              {shouldRenderDivider && <Divider style={{ margin: 0 }} />}
            </>
          )
        })}
      </S.FiltersContainer>
    </S.TooltipContainer>
  )
}

export { PresetFilterTooltip }
