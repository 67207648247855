import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { authStore } from '@/app'
import { api } from '@/services'

type ResetFirstPasswordMutation = {
  email: string
  newPassword: string
}

const useResetPasswordMutation = () => {
  const navigate = useNavigate()

  return useMutation(
    async ({ email, newPassword }: ResetFirstPasswordMutation) => {
      return api.post('/auth/first_login', {
        username: email,
        newPassword,
      })
    },
    {
      onSuccess: ({ data }) => {
        toast.success('Senha atualizada com sucesso!')

        const { AccessToken, IdToken, RefreshToken } = data

        api.defaults.headers.common.Authorization = `Bearer ${IdToken}`

        authStore.setState(
          {
            accessToken: AccessToken,
            idToken: IdToken,
            refreshToken: RefreshToken,
            isUserAuthenticated: true,
          },
          false,
          'sign-in',
        )

        navigate('/')
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 403) {
          return toast.error('Email ou senha incorretos')
        }

        toast.error('Algo deu errado')
        console.log(error)
      },
    },
  )
}

export { useResetPasswordMutation }
