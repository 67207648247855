import { useQuery } from '@tanstack/react-query'

import type { Filters } from '../../types'
import { filterKeys } from '../query-keys'

import { api } from '@/services'

async function fetchFilters() {
  const { data } = await api.get<Filters>('/produto/filter')

  return data
}

const useFilters = () => useQuery(filterKeys.list(), fetchFilters)

export { useFilters }
