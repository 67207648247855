import { create } from 'zustand'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'

import { ActiveFilters, ActiveSorting } from '../../common/types'

import { PresetFilter } from '@/features/product'

type FilterStoreState = {
  activeFilters: ActiveFilters
  activeSorting: ActiveSorting
  lastActivePreset: PresetFilter | null
  setLastActivePreset: (preset: PresetFilter | null) => void
  setActiveFilters: (filters: ActiveFilters) => void
  setActiveSorting: (sorting: ActiveSorting) => void
  handleFilter: (filters: ActiveFilters) => void
  resetFilters: () => void
  clearFilters: () => void
}

const filterStore = create<FilterStoreState>()(
  devtools(
    persist(
      (set, get) => ({
        activeFilters: {
          disponivel: true,
        },

        activeSorting: {
          coluna: 'a_vista',
          ordem: 'ASC',
        } as ActiveSorting,

        lastActivePreset: null,

        setActiveFilters: (filters) => {
          const hasFilters = Object.keys(filters).length > 0

          if (!hasFilters) {
            set({ activeFilters: {} }, false, 'no-active-filters')
          }

          set({ activeFilters: { ...filters } }, false, 'set-active-filters')
        },

        setLastActivePreset: (preset) => {
          if (!preset) {
            return set({ lastActivePreset: null }, false, 'no-active-preset')
          }

          set({ lastActivePreset: { ...preset } }, false, 'set-last-active-preset')
        },

        handleFilter: (filters) => {
          const { activeFilters, setActiveFilters } = get()

          setActiveFilters({ ...activeFilters, ...filters })
        },

        setActiveSorting: (sorting) => {
          set({ activeSorting: { ...sorting } }, false, 'set-active-sorting')
        },

        resetFilters: () => {
          set(
            {
              activeFilters: {
                disponivel: true,
              },
            },
            false,
            'reset-filters',
          )
        },

        clearFilters: () => {
          set(
            {
              activeFilters: {},
            },
            false,
            'clear-filters',
          )
        },
      }),
      {
        name: 'filter-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
)

export const resetFilters = filterStore.getState().resetFilters
export const clearFilters = filterStore.getState().clearFilters

export default filterStore
