import Dinero from 'dinero.js'

type DineroFormatProps = {
  value: number
  type?: 'currency' | 'unit'
}

function DineroFormat({ value, type = 'currency' }: DineroFormatProps): string | number {
  if (type === 'unit') {
    return Dinero({ amount: value }).toUnit()
  }

  return Dinero({ amount: value, currency: 'BRL' }).setLocale('pt-BR').toFormat('$0,0.00')
}

export { DineroFormat }
