import { BrowserRouter, Navigate, Route, Routes as ReactRoutes } from 'react-router-dom'

import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

import {
  ChangePasswordPage,
  ForgotPasswordPage,
  LoginPage,
  ProductList,
  ProductPage,
  ResetFirstPasswordPage,
  Users,
  Dashboard,
  Groups,
  NotificationSettings,
  NotificationList,
} from '@/pages'

const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/auth" element={<PublicRoute />}>
          <Route index element={<Navigate to="login" replace={false} />} />

          <Route path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetFirstPasswordPage />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Navigate to="/produtos" replace={false} />} />

          <Route path="produtos">
            <Route index element={<ProductList />} />
            <Route path=":id" element={<ProductPage />} />
          </Route>

          <Route path="change-password" element={<ChangePasswordPage />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="users" element={<PrivateRoute allowedUsers={['admin']} />}>
            <Route index element={<Users />} />
          </Route>

          <Route path="groups" element={<PrivateRoute allowedUsers={['admin']} />}>
            <Route index element={<Groups />} />
          </Route>

          <Route path="notifications" element={<PrivateRoute />}>
            <Route index element={<NotificationList />} />
            <Route path="settings" element={<PrivateRoute allowedUsers={['admin']} />}>
              <Route index element={<NotificationSettings />} />
            </Route>
          </Route>
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  )
}

export { Routes }
