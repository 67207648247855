const removeUndefinedFields = (obj: Record<string, string | string[]>) => {
  const newObj = { ...obj }

  Object.keys(newObj).forEach(
    (key) =>
      (newObj[key] === undefined ||
        newObj[key] === '' ||
        (Array.isArray(newObj[key]) && !newObj[key].length)) &&
      delete newObj[key],
  )

  return newObj
}

export { removeUndefinedFields }
