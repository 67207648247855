import type { UseQueryResult } from '@tanstack/react-query'
import { Tooltip, Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table'

import { ErrorMessage } from '../../ErrorMessage'
import { LoadingMessage } from '../../LoadingMessage'
import { RankingSpan, Star, TableTag, ProductLink } from './styles'

import { HighlightedProduct, HighlightedProducts } from '@/features/dashboard'

type RankingTableProps = {
  query: UseQueryResult<HighlightedProducts[], unknown>
}

const TOOLTIP_MESSAGE = 'Produto não mapeado pelo sistema'

const errorMessages = new Map([['No highlights found', 'Nenhum produto encontrado.']])

const RankingAmazon = ({ query }: RankingTableProps) => {
  const columns: ColumnsType<HighlightedProducts> = [
    {
      title: 'Ranking',
      dataIndex: 'ranking',
      key: 'ranking',
      width: '5%',
      render: (rank: number, record) => {
        const isDisabled = !record.produto.id

        const RankingTopThree = rank === 1 || rank === 2 || rank === 3
        return RankingTopThree ? (
          <Tooltip title={isDisabled ? TOOLTIP_MESSAGE : null}>
            <RankingSpan $isDisabled={isDisabled}>
              <Star rank={rank} />
              {rank}º
            </RankingSpan>
          </Tooltip>
        ) : (
          <Tooltip title={isDisabled ? TOOLTIP_MESSAGE : null}>
            <RankingSpan $isDisabled={isDisabled}>{rank}º</RankingSpan>
          </Tooltip>
        )
      },
    },
    {
      title: 'Brand',
      key: 'brand',
      dataIndex: ['produto', 'marca'],
      width: '5%',
      render: (brand: string, record) => {
        const isDisabled = !record.produto.id

        return (
          <Tooltip title={isDisabled ? TOOLTIP_MESSAGE : null}>
            <TableTag brand={brand} $isDisabled={isDisabled}>
              {brand}
            </TableTag>
          </Tooltip>
        )
      },
    },
    {
      title: 'Produto',
      dataIndex: 'produto',
      key: 'produto',
      render: (product: HighlightedProduct) => {
        const isDisabled = !product.id

        return (
          <Tooltip
            title={
              isDisabled ? TOOLTIP_MESSAGE : `${product.marca} - ${product.informacoes.referencia}`
            }
          >
            <ProductLink $isDisabled={isDisabled} to={isDisabled ? '' : `/produtos/${product.id}`}>
              {/* {product.marca}  */}
              {product.informacoes.referencia}
            </ProductLink>
          </Tooltip>
        )
      },
    },
    // {
    //   title: 'Vendidos',
    //   dataIndex: 'vendidos',
    //   key: 'vendidos',
    //   width: '10%',
    //   render: () => {
    //     return <SoldSpan>100</SoldSpan>
    //   },
    // },
  ]

  const isError = query.isError
  const isLoading = query.isLoading

  if (isError) {
    return (
      <ErrorMessage
        message={errorMessages.get((query.error as any).response.data.message) || undefined}
      />
    )
  }

  if (isLoading) return <LoadingMessage />

  return (
    <Table
      rowKey={(record) => `${record.ranking}-${crypto.randomUUID()}`}
      dataSource={query.data?.slice(0, 10)}
      columns={columns}
      pagination={false}
      showHeader={false}
      loading={query.isLoading}
      size="middle"
    />
  )
}

export { RankingAmazon }
