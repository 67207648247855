import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { UserNotifications } from '../types'
import { notificationKeys } from './query-keys'

import { api } from '@/services'

async function fetchUserNotifications({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof notificationKeys)['userList']>>) {
  const [, , , page] = queryKey

  const { data } = await api.get<UserNotifications>(`/notifications?page=${page}`)

  return data
}

const useUserNotifications = (page: number) => {
  return useQuery(notificationKeys.userList(page), fetchUserNotifications)
}

export { useUserNotifications, fetchUserNotifications }
