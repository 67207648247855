import { useState } from 'react'

import { Typography } from 'antd'

import * as S from './styles'

import { useToggle } from '@/common'
import { CustomPagination, Loading } from '@/components'
import {
  AdminNotificationTable,
  NotificationConfig,
  useCreatedNotifications,
  NotificationForm,
  NotificationModal,
  formatNotificationData,
} from '@/features/notifications'
import { PageLayout } from '@/layouts'

const ITEMS_PER_PAGE = 30

const NotificationSettings = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const { data, isLoading, isFetching, isError } = useCreatedNotifications(currentPage)
  const [isNotificationModalOpen, toggleNotificationModal] = useToggle(false)

  const [selectedNotification, setSelectedNotification] = useState<NotificationConfig | undefined>(
    undefined,
  )
  const [editNotificationForm, setEditNotificationForm] = useState<NotificationForm | undefined>(
    undefined,
  )

  function handleSelectNotification(notification: NotificationConfig) {
    setSelectedNotification(notification) // get notification from table
    toggleNotificationModal()

    const formValues = formatNotificationData(notification)

    // set form values to edit
    setEditNotificationForm(formValues)
  }

  return (
    <PageLayout windowTitle="Configurações de notificação">
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          closeModal={toggleNotificationModal}
          productId={selectedNotification?.productId as string}
          initialValues={editNotificationForm}
          notificationId={selectedNotification?.id}
        />
      )}

      <S.Space>
        <Typography.Title level={5}>Lista de alertas</Typography.Title>
        <S.Divider type="vertical" />
        <S.FoundProducts>
          <Typography.Text strong>{data?.result?.length?.toLocaleString('pt-BR')}</Typography.Text>{' '}
          {data && (
            <Typography.Text>
              {data?.result?.length > 1 ? 'encontrados' : 'encontrado'}
            </Typography.Text>
          )}
        </S.FoundProducts>
        {}
        {(isLoading || isFetching) && <Loading />}
      </S.Space>

      <AdminNotificationTable
        data={data?.result}
        isLoading={isLoading}
        handleSelectNotification={handleSelectNotification}
      />

      <CustomPagination
        scrollToTop
        page={currentPage}
        isLoading={isLoading}
        pageSize={ITEMS_PER_PAGE}
        total={data?.info.total}
        totalPages={data?.info.pages as number}
        updatePage={setCurrentPage}
      />
    </PageLayout>
  )
}

export { NotificationSettings }
