import { UseQueryResult } from '@tanstack/react-query'
import { Divider } from 'antd'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import * as S from './styles'

import { formatAmounts } from '@/common'
import {
  OffersByMarketplaceGraph,
  ActiveGraphType,
  ErrorMessage,
  LoadingMessage,
} from '@/features/dashboard'

type ByMarketplaceGraphProps = {
  query: UseQueryResult<OffersByMarketplaceGraph[], unknown>
  activeData: ActiveGraphType
}

type ReducedData = {
  marketplace: string
  [key: string]: string | number
}

type ReducedArray = ReducedData[]

type TooltipPayload = {
  color: string
  dataKey: string
  name: string // Marketplace
  value: number
  payload: {
    [key: string]: string | number // Marketplace | Date
  }
}

type CustomTooltip = {
  active: boolean
  payload: TooltipPayload[]
  label: string
}

const IMPORTANT_MARKETPLACES = [
  // 'Americanas',
  'Casas Bahia',
  'Fast Shop',
  'Magazine Luiza',
  'Ponto Frio',
  // 'Submarino',
]

const MarketplaceGraph = ({ query, activeData }: ByMarketplaceGraphProps) => {
  const filteredData = query.data?.filter((offers) =>
    IMPORTANT_MARKETPLACES.includes(offers.marketplace),
  )

  const reducedData = filteredData?.reduce((acc: ReducedArray, item) => {
    if (activeData === 'ofertas_nao_oficiais') return acc

    const { marketplace, marca } = item

    const displayData = item[activeData] // ofertas_sem_seller || ofertas_com_seller

    const marketplaceIndex = acc.findIndex((accItem) => accItem.marketplace === marketplace)

    if (marketplaceIndex > -1) {
      acc[marketplaceIndex][marca] = displayData
    } else {
      acc.push({
        marketplace,
        [marca]: displayData,
      })
    }
    return acc
  }, [])

  const CustomTooltip = ({ active, payload, label }: CustomTooltip) => {
    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer>
          <S.TooltipTitle>{label}</S.TooltipTitle>

          <Divider style={{ margin: 0 }} />

          {payload.map((entry, index) => (
            <S.TooltipItem key={`item-${index}`} color={entry.color}>
              {`${entry.name}: ${formatAmounts(entry.value)}`}
            </S.TooltipItem>
          ))}
        </S.TooltipContainer>
      )
    }

    return null
  }

  const isError = query.isError
  const isLoading = query.isLoading

  if (isError) return <ErrorMessage />
  if (isLoading) return <LoadingMessage />

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        cx="50%"
        cy="50%"
        data={reducedData}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="marketplace" />
        <YAxis />
        <Tooltip
          content={({ active, payload, label }) => (
            <CustomTooltip
              active={active as boolean}
              payload={payload as TooltipPayload[]}
              label={label}
            />
          )}
        />
        {/* <Tooltip /> */}
        <Legend />
        <Bar dataKey="Acer" fill="#99cf69" animationEasing="linear" animationDuration={400} />
        <Bar dataKey="Asus" fill="#696969" animationEasing="linear" animationDuration={400} />
        <Bar dataKey="Dell" fill="#59afcd" animationEasing="linear" animationDuration={400} />
        <Bar dataKey="Lenovo" fill="#e95751" animationEasing="linear" animationDuration={400} />
        {/* <Bar dataKey="Positivo" fill="#f9ad64" animationEasing="linear" animationDuration={400} /> */}
        <Bar dataKey="Samsung" fill="#4d5cb7" animationEasing="linear" animationDuration={400} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export { MarketplaceGraph }
