import { useCallback, useEffect, useState } from 'react'

import { DownOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'

import { activeFilterNames } from '../../constants'
import { useTextSearchFilter } from '../../hooks'
import * as S from './styles'

import { filterStore as useFilterStore } from '@/app'

type ActiveFiltersDropdownProps = {
  filter: string
  value: string | boolean | string[]
}

type DropdownIcon = {
  value: string | boolean | string[]
}

const DropdownIcon = ({ value }: DropdownIcon) => (
  <S.DropdownIcon>
    {typeof value === 'object' && <span>{value.length}</span>}
    <DownOutlined />
  </S.DropdownIcon>
)

const ActiveFiltersDropdown = ({ filter, value }: ActiveFiltersDropdownProps) => {
  const [items, setItems] = useState<MenuProps['items']>([])
  const [, setTextSearchInput] = useTextSearchFilter()
  const activeFilters = useFilterStore((s) => s.activeFilters)
  const setActiveFilters = useFilterStore((s) => s.setActiveFilters)

  const filterName = activeFilterNames.get(filter) || filter

  const removeFilter = useCallback(() => {
    const updatedFilters = { ...activeFilters }
    delete updatedFilters[filter]

    // reset controlled input when removing text search filter
    if (filter === 'text_search') {
      setTextSearchInput('')
    }

    setActiveFilters(updatedFilters)
  }, [activeFilters, filter, setActiveFilters, setTextSearchInput])

  const removeFilterValue = useCallback(
    (selectedValue: string | boolean | string[]) => {
      const updatedFilters = { ...activeFilters }

      if (typeof value === 'boolean') {
        // disponibilidade
        removeFilter()
      }

      if (typeof value === 'string') {
        // palavra chave
        delete updatedFilters[filter]
        setTextSearchInput('')
        setActiveFilters(updatedFilters)
      }

      if (typeof value === 'object') {
        // outros filtros
        const updatedValue = value.filter((v) => v !== selectedValue)

        if (updatedValue.length === 0) {
          delete updatedFilters[filter]
        } else {
          updatedFilters[filter] = updatedValue
        }

        setActiveFilters(updatedFilters)
      }
    },
    [activeFilters, filter, removeFilter, setActiveFilters, setTextSearchInput, value],
  )

  // mount dropdown items based on filter value type
  useEffect(() => {
    if (typeof value === 'boolean') {
      const booleanLabel =
        filter === 'disponivel' ? (value ? 'Disponível' : 'Indisponível') : value ? 'Sim' : 'Não'

      setItems([
        {
          key: crypto.randomUUID(),
          icon: <S.CloseIcon />,
          label: booleanLabel,
          onClick: () => removeFilterValue(value),
        },
      ])
    }

    if (typeof value === 'string') {
      setItems([
        {
          key: crypto.randomUUID(),
          icon: <S.CloseIcon />,
          label: value,
          onClick: () => removeFilterValue(value),
        },
      ])
    }

    if (typeof value === 'object') {
      setItems(
        value.map((v) => ({
          key: crypto.randomUUID(),
          icon: <S.CloseIcon />,
          label: v,
          onClick: () => removeFilterValue(v),
        })),
      )
    }
  }, [filter, removeFilterValue, value])

  return (
    <S.DropdownButton
      menu={{ items }}
      key={filter}
      trigger={['click', 'hover']}
      icon={<DropdownIcon value={value} />}
      onClick={removeFilter}
    >
      <S.RemoveFilterButton />
      <a>{filterName}</a>
    </S.DropdownButton>
  )
}

export { ActiveFiltersDropdown }
