import { UseQueryResult } from '@tanstack/react-query'
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts'

import { formatAmounts } from '@/common'
import {
  ErrorMessage,
  ActiveGraphType,
  LoadingMessage,
  OffersByBrandGraph,
} from '@/features/dashboard'

type ByBrandsGraphProps = {
  query: UseQueryResult<OffersByBrandGraph[], unknown>
  activeData: ActiveGraphType
}

const IMPORTANT_BRANDS = [
  'Acer',
  'Asus',
  'Dell',
  'Lenovo',
  // 'Positivo',
  'Samsung',
]
const BRAND_COLORS = [
  {
    name: 'acer',
    color: '#99cf69',
  },
  {
    name: 'asus',
    color: '#696969',
  },
  {
    name: 'dell',
    color: '#59afcd',
  },
  {
    name: 'lenovo',
    color: '#e95751',
  },
  // {
  //   name: 'positivo',
  //   color: '#f9ad64',
  // },
  {
    name: 'samsung',
    color: '#4d5cb7',
  },
]

const BrandsGraph = ({ query, activeData }: ByBrandsGraphProps) => {
  const filteredBrands = query.data?.filter((offers) => IMPORTANT_BRANDS.includes(offers.marca))

  const formattedData = filteredBrands?.map((item) => {
    return {
      name: item.marca,
      value: item[activeData],
    }
  })

  const renderCustomLabel = ({ value }: { value: number }) => {
    return formatAmounts(value)
  }

  const isError = query.isError
  const isLoading = query.isLoading

  if (isError) return <ErrorMessage />
  if (isLoading) return <LoadingMessage />

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          dataKey="value"
          data={formattedData}
          innerRadius={80}
          outerRadius={125}
          animationBegin={0}
          animationDuration={400}
          label={renderCustomLabel}
          animationEasing="linear"
        >
          {formattedData?.map((entry, index) => {
            const color = BRAND_COLORS.find(
              (item) => item.name === entry.name.toLocaleLowerCase(),
            )?.color

            return <Cell key={`cell-${index}`} fill={color} />
          })}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export { BrandsGraph }
