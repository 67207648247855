import { useRef } from 'react'
import { AiOutlineBook } from 'react-icons/ai'
import { HiCurrencyDollar, HiMiniTicket, HiMiniTruck } from 'react-icons/hi2'
import { TbCirclesRelation } from 'react-icons/tb'

import {
  ReloadOutlined,
  ShareAltOutlined,
  TagsOutlined,
  WhatsAppOutlined,
  MailOutlined,
  OrderedListOutlined,
  NotificationOutlined,
} from '@ant-design/icons'
import { Tooltip, Button, Typography, Tag } from 'antd'
import Table from 'antd/es/table'
import { Key } from 'antd/es/table/interface'
import type { ColumnsType } from 'antd/lib/table'
import type { SorterResult } from 'antd/lib/table/interface'

import { useUpdatePricesMutation } from '../../../mutations'
import { Marketplace, MarketplaceResult, Product, Products } from '../../../types'
import * as S from './styles'

import { filterStore as useFilterStore } from '@/app'
import { ActiveSorting, formatDate, formatCurrency, useUserGroup } from '@/common'
import { TableAction, DismissibleToast } from '@/components'
import { TooltipIcon, TableTooltipTitle, usePagination } from '@/features/product'
import { HandleCheckedProductsType, SelectProductActions } from '@/pages'

type ProductsListTableProps = {
  data: Products
  isLoading: boolean
  currentPage: number
  itemsPerPage: number
  checkedProducts: Key[]
  handleSelectedProduct: ({ productId, type }: SelectProductActions) => void
  handleCheckedProducts: ({
    selectedRows,
    selectedRowKeys,
    info,
  }: HandleCheckedProductsType) => void
}

const ProductsListTable = ({
  data,
  isLoading,
  currentPage,
  itemsPerPage,
  checkedProducts,
  handleCheckedProducts,
  handleSelectedProduct,
}: ProductsListTableProps) => {
  const { isAdmin, userGroup } = useUserGroup()

  const iconRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLDivElement>()
  const handleFilter = useFilterStore((s) => s.handleFilter)
  const activeSorting = useFilterStore((s) => s.activeSorting)
  const setActiveSorting = useFilterStore((s) => s.setActiveSorting)
  const updateProductPricesMutation = useUpdatePricesMutation()
  const [_, updateCurrentPage] = usePagination()

  function handleSort(sorter: SorterResult<Product>) {
    const { order, columnKey } = sorter

    // update column name to match API
    const column = columnKey === 'marketplaces' ? 'ofertas' : (columnKey as string)

    if (order) {
      const formattedOrder = order === 'ascend' ? 'ASC' : 'DESC'

      setActiveSorting({
        coluna: column,
        ordem: formattedOrder,
      })
    } else {
      setActiveSorting({} as ActiveSorting)
    }
  }

  function updateProductPrices(productId: string) {
    DismissibleToast({ text: 'Atualizando preços do produto' })

    updateProductPricesMutation.mutate(productId)
  }

  function handleFilterCategory(category: string) {
    handleFilter({
      categorias: [category],
    })
  }

  function handleFilterNewProducts() {
    handleFilter({
      novos: true,
    })
  }

  function handleFilterInfoTag(type: 'novos' | 'review') {
    updateCurrentPage(1)

    handleFilter({
      [type]: true,
    })
  }

  const tableColumns: ColumnsType<Product> = [
    {
      title: 'n°',
      dataIndex: 'id',
      align: 'center',
      width: 15,
      render: (_, __, index) => {
        const rank = (currentPage - 1) * itemsPerPage + index + 1

        return <S.TableText $isBold={false}>{rank}</S.TableText>
      },
    },
    {
      title: 'Marca',
      dataIndex: 'marca',
      width: 115,
      render: (value) => <Typography.Text>{value}</Typography.Text>,
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      key: 'modelo',
      width: 547,
      render: (value: string, record: Product) => {
        return (
          <S.ProductLink $isDark={true} to={`/produtos/${record.id}`}>
            {value}
          </S.ProductLink>
        )
      },
    },
    {
      title: ' ',
      dataIndex: 'tags',
      key: 'tags',
      width: 70,
      align: 'center',
      render: (_, record: Product) => {
        const hasReview = record.review as unknown as boolean

        const isNewProduct = record.produto_novo

        return (
          <>
            {isNewProduct && (
              <Button type="link" onClick={() => handleFilterInfoTag('novos')}>
                <S.TagsContainer>
                  <S.TagNewProduct>Novo</S.TagNewProduct>
                </S.TagsContainer>
              </Button>
            )}

            {hasReview && (
              <Button type="link" onClick={() => handleFilterInfoTag('review')}>
                <S.ReviewArea>
                  <S.TagReview>Review</S.TagReview>
                </S.ReviewArea>
              </Button>
            )}
          </>
        )
      },
    },
    {
      title: 'Categorias',
      dataIndex: 'categorias',
      key: 'categoria',
      align: 'center',
      width: 100,
      render: (categories: string[], record: Product) => {
        const hasCategories = categories.length > 0
        const hasMultipleCategories = categories.length > 1

        if (!hasCategories) {
          return <Typography.Text>-</Typography.Text>
        }

        return (
          <S.CategoriesContainer>
            <S.CategoryButton type="link" onClick={() => handleFilterCategory(categories[0])}>
              {categories[0]}
            </S.CategoryButton>

            {hasMultipleCategories && (
              <Tooltip
                color={'#fbfbfb'}
                overlayInnerStyle={
                  hasMultipleCategories
                    ? {
                        minWidth: 200,
                        width: 'fit-content',
                        borderRadius: '5px',
                        maxHeight: '300px',
                        overflow: 'auto',
                      }
                    : {}
                }
                title={
                  <Table
                    bordered
                    rowKey={() => `${crypto.randomUUID()}-${record.id}`}
                    dataSource={categories as any}
                    columns={[
                      {
                        title: 'Categorias',
                        key: 'categoria',
                        align: 'center',
                        width: 100,
                        render: (value: string) => {
                          return (
                            <S.CategoryButton
                              type="link"
                              onClick={() => handleFilterCategory(value)}
                            >
                              {value}
                            </S.CategoryButton>
                          )
                        },
                      },
                    ]}
                    pagination={false}
                    size="small"
                  />
                }
              >
                <S.CategoryTooltip>+{categories.length - 1}</S.CategoryTooltip>
              </Tooltip>
            )}
          </S.CategoriesContainer>
        )
      },
    },
    {
      title: 'Lojas',
      dataIndex: 'marketplaces',
      key: 'marketplaces',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: activeSorting.coluna === 'ofertas' ? 'ascend' : undefined, // if activeSorting is not ofertas, then it's not sorted by default
      width: 92,
      align: 'center',
      render: (marketplaces: Marketplace, product) => {
        const total = marketplaces.count
        const stores = marketplaces.result.sort((a, b) => {
          if (a.url && !b.url) {
            return -1
          }

          return 0
        })

        const hasStores = total > 0
        const hasMultipleStores = total > 10

        return (
          <Tooltip
            color={'#fbfbfb'}
            overlayInnerStyle={
              hasStores
                ? { width: '580px', borderRadius: '5px', maxHeight: '300px', overflow: 'auto' }
                : {}
            }
            placement="topRight"
            title={
              hasStores ? (
                <Table
                  bordered
                  rowKey={(record) => `${crypto.randomUUID()}-${record.webprice_id}`}
                  dataSource={stores}
                  columns={tooltipColumns}
                  pagination={false}
                  size="small"
                  footer={() =>
                    hasMultipleStores ? (
                      <S.TooltipTableFooter>
                        <S.ProductLink to={`/produtos/${product.id}#lojas`}>
                          Ver todas
                        </S.ProductLink>
                      </S.TooltipTableFooter>
                    ) : (
                      <></>
                    )
                  }
                />
              ) : (
                <S.TooltipText>Nenhuma loja encontrada</S.TooltipText>
              )
            }
          >
            <S.TableText color="#5EA320" $isBold>
              {String(total).padStart(2, '0')} <S.StoreIcon />
            </S.TableText>
          </Tooltip>
        )
      },
    },
    {
      title: 'À vista (R$)',
      dataIndex: ['precos', 'menor_preco', 'a_vista'],
      key: 'a_vista',
      align: 'center',
      width: 126,
      sorter: true,
      defaultSortOrder: activeSorting.coluna === 'a_vista' ? 'ascend' : undefined, // if activeSorting is not a_vista, then it's not sorted by default
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (value, product) => {
        return (
          <Tooltip
            color={'#fbfbfb'}
            overlayInnerStyle={{
              width: '255px',
              borderRadius: '5px',
              justifyContent: 'center',
            }}
            title={
              <S.TooltipText>Última atualização: {formatDate(product.updated_at)}</S.TooltipText>
            }
          >
            {formatCurrency({ value, type: 'decimal' })}
          </Tooltip>
        )
      },
      showSorterTooltip: false,
    },
    {
      title: 'Parcelado (R$)',
      dataIndex: ['precos', 'menor_preco', 'a_prazo'],
      key: 'a_prazo',
      align: 'center',
      width: 126,
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (value, product) => {
        return (
          <Tooltip
            color={'#fbfbfb'}
            overlayInnerStyle={{
              width: '255px',
              borderRadius: '5px',
              justifyContent: 'center',
            }}
            title={
              <S.TooltipText>Última atualização: {formatDate(product.updated_at)}</S.TooltipText>
            }
          >
            {formatCurrency({ value, type: 'decimal' })}
          </Tooltip>
        )
      },
      showSorterTooltip: false,
    },
    {
      title: 'Ações',
      dataIndex: 'settings',
      key: 'settings',
      align: 'center',
      width: 69,
      render: (_, product) => {
        const options = [
          {
            key: '1',
            label: (
              <Button
                icon={<OrderedListOutlined />}
                type="link"
                onClick={() =>
                  handleSelectedProduct({
                    productId: product.id,
                    type: 'ADD_CATEGORY',
                  })
                }
              >
                Categorizar produto
              </Button>
            ),
            // onClick: () => updateProductPrices(product.id),
          },
          {
            key: '2',
            label: (
              <Button icon={<ReloadOutlined />} type="link">
                Atualizar Preços
              </Button>
            ),
            onClick: () => updateProductPrices(product.id),
          },

          {
            key: '3',
            label: (
              <Button icon={<TagsOutlined />} type="link">
                Adicionar Tag
              </Button>
            ),
            onClick: () => handleSelectedProduct({ productId: product.id, type: 'ADD_TAG' }),
          },
          {
            key: '4',
            permissions: ['admin'],
            label: (
              <Button
                icon={<NotificationOutlined />}
                type="link"
                // onMouseEnter={() => prefetchProduct(product.id)}
              >
                Criar notificação
              </Button>
            ),
            onClick: () =>
              handleSelectedProduct({ productId: product.id, type: 'CREATE_NOTIFICATION' }),
          },
          {
            key: '5',
            label: (
              <S.SimilarProductButton icon={<TbCirclesRelation />} type="link">
                Produtos Similares
              </S.SimilarProductButton>
            ),
            onClick: () =>
              handleSelectedProduct({ productId: product.id, type: 'SIMILAR_PRODUCTS' }),
          },
          {
            key: '7',
            label: (
              <S.ReviewButton icon={<AiOutlineBook />} type="link">
                Reviews do produto
              </S.ReviewButton>
            ),
            onClick: () => handleSelectedProduct({ productId: product.id, type: 'PRODUCT_REVIEW' }),
          },
          {
            key: '6',
            label: (
              <Button icon={<ShareAltOutlined />} type="link">
                Compartilhar
              </Button>
            ),
            children: [
              {
                key: '6-1',
                label: (
                  <Button
                    type="link"
                    icon={<MailOutlined />}
                    onClick={() =>
                      handleSelectedProduct({
                        productId: product.id,
                        type: 'SHARE_PRODUCT',
                      })
                    }
                  >
                    Email
                  </Button>
                ),
              },
              {
                key: '6-2',
                label: (
                  <a
                    href={`https://web.whatsapp.com/send?text= Veja os dados do *_${product?.modelo}_* no PTT ${window.location.origin}/produtos/${product.id}`}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    <Button type="link" icon={<WhatsAppOutlined />}>
                      Whatsapp
                    </Button>
                  </a>
                ),
              },
            ],
          },
        ]

        const dropdownOptions = options.map((option) => {
          if (option.permissions && !option.permissions.includes(userGroup as string)) {
            return null
          }

          return option
        })

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  const tooltipColumns: ColumnsType<MarketplaceResult> = [
    {
      title: 'Loja',
      dataIndex: 'loja',
      width: 30,
      render: (_, store) => {
        const isLinkDisabled = !store.url

        return (
          <S.MarketplaceContainer>
            {store.patrocinado && (
              <Tooltip
                title={'Oferta patrocinada'}
                placement="topLeft"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon ref={iconRef} icon={<S.FireIcon />} />
              </Tooltip>
            )}

            <S.StoreLink
              href={isLinkDisabled ? '' : store.url}
              isDisabled={isLinkDisabled}
              target="_blank"
              rel="noreferrer"
            >
              {store.loja}
            </S.StoreLink>
          </S.MarketplaceContainer>
        )
      },
    },
    {
      title: 'Seller',
      dataIndex: 'seller',
      width: 50,
      align: 'left',
      render: (_, record) => {
        const isLinkDisabled = !record.url
        const hasSeller = !!record.seller

        const isSameSeller = record.seller === record.loja

        if (hasSeller && isSameSeller) return '-'

        return hasSeller ? (
          <S.TooltipTableText isDisabled={isLinkDisabled}>{record.seller}</S.TooltipTableText>
        ) : (
          '-'
        )
      },
    },
    {
      title: 'À vista (R$)',
      dataIndex: 'a_vista',
      align: 'center',
      width: 25,
      render: (value: string, record) => {
        const isLinkDisabled = !record.url

        return (
          <S.TooltipTableText isDisabled={isLinkDisabled}>
            {formatCurrency({ value, type: 'decimal' })}
          </S.TooltipTableText>
        )
      },
    },
    {
      title: 'Parcelado (R$)',
      dataIndex: 'a_prazo',
      align: 'center',
      width: 25,
      render: (value: string, record) => {
        const isLinkDisabled = !record.url

        return (
          <S.TooltipTableText isDisabled={isLinkDisabled}>
            {formatCurrency({ value, type: 'decimal' })}
          </S.TooltipTableText>
        )
      },
    },
    {
      title: 'Condições especiais',
      dataIndex: 'disponivel',
      width: 80,
      align: 'center',
      render: (_, store) => {
        const noItems = !store.frete_gratis && !store.cashback && !store.cupom

        if (noItems) return <S.TooltipTableText isDisabled={false}>-</S.TooltipTableText>

        return (
          <S.ConditionsContainer>
            {store.frete_gratis && (
              <Tooltip
                title={`Possui frete grátis`}
                placement="topLeft"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon ref={iconRef} icon={<HiMiniTruck />} />
              </Tooltip>
            )}

            {store.cashback && (
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={
                  <TableTooltipTitle
                    type="cashback"
                    value={store.cashback}
                    valueWithDiscount={store.discount_with_cashback as number}
                  />
                }
                placement="top"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon ref={iconRef} icon={<HiCurrencyDollar />} />
              </Tooltip>
            )}

            {store.cupom && (
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={
                  <TableTooltipTitle
                    type="cupom"
                    valueType={store.cupom.cupom_type}
                    value={store.cupom.value}
                    valueWithDiscount={store.discount_with_cupom as number}
                  />
                }
                placement="topRight"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon ref={iconRef} icon={<HiMiniTicket />} />
              </Tooltip>
            )}
          </S.ConditionsContainer>
        )
      },
    },
  ]

  return (
    <S.TableContainer>
      <Table
        ref={tableRef as any}
        bordered
        rowKey="id"
        dataSource={data?.results.length > 0 ? data?.results : []}
        columns={tableColumns as ColumnsType<Product>}
        loading={isLoading}
        rowClassName={(_, index) => (index % 2 === 0 ? 'light-row' : 'dark-row')}
        pagination={false}
        onChange={(_, __, sorter) => handleSort(sorter as SorterResult<Product>)}
        footer={() => ''}
        rowSelection={
          isAdmin
            ? {
                type: 'checkbox',
                selectedRowKeys: checkedProducts,
                onChange: (selectedRowKeys, selectedRows, info) =>
                  handleCheckedProducts({ selectedRowKeys, selectedRows, info }),
              }
            : undefined
        }
      />
    </S.TableContainer>
  )
}

export { ProductsListTable }
