import { AiOutlineSwapRight } from 'react-icons/ai'
import { Link } from 'react-router-dom'

import { Typography } from 'antd'
import styled from 'styled-components'

import { hexToRgba } from '@/common'

type ProductLinkProps = {
  $isBold?: boolean
  $isDark?: boolean
}

export const Description = styled(Typography.Text)`
  line-height: inherit;
`

export const GreyText = styled(Typography.Text)`
  color: #999999;
`

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .ant-typography {
    color: #666666;
  }
`

export const ArrowRight = styled(AiOutlineSwapRight)`
  margin: 0px 26px;
`

export const ProductLink = styled(Link)<ProductLinkProps>`
  color: ${({ theme, $isDark }) => ($isDark ? theme.colors.darkGreen : theme.colors.brand)};
  font-weight: ${({ $isBold }) => ($isBold ? 700 : 400)};
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    color: ${({ theme, $isDark }) =>
      $isDark ? hexToRgba(theme.colors.darkGreen, 0.7) : theme.colors.brand};
    filter: brightness(0.8);
  }
`

export const VariationContainer = styled.div`
  font-weight: 600;
`

export const TableContainer = styled.div`
  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    padding: 16px 9px;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    padding: 10px;
    border: none;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.6);
  }

  .ant-table-wrapper .ant-table.ant-table-middle .ant-table-footer {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background-color: rgba(240, 242, 245, 0.5);
    border: none;
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: rgba(240, 242, 245, 0.5);
  }
`
