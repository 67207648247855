import { ReactNode, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { FilterOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { Drawer, Form, Input, InputRef, Tooltip, Button, Typography } from 'antd'
import isEqual from 'lodash.isequal'

import {
  usePagination,
  useCollapsePanels,
  useAutoAnimateRef,
  useTextSearchFilter,
} from '../../hooks'
import { useCreatePresetMutation, useDeletePresetMutation } from '../../mutations'
import { usePresetFilters } from '../../queries'
import { PresetFilter } from '../../types'
import { removeUndefinedFields, sanitizeText, getStringInitials } from '../../utils'
import { PresetFilterTooltip } from '../PresetFilterTooltip'
import {
  Container,
  SidebarTrigger,
  Footer,
  ClearButton,
  Divider,
  SavePresetArea,
  HeaderContainer,
  HeaderContent,
  PresetButton,
  PresetsContainer,
  Preset,
  DeletePresetButton,
  SavePresetButton,
  TooltipFilter,
} from './styles'

import { filterStore as useFilterStore, resetFilters } from '@/app'
import { useToggle } from '@/common'

interface FilterSidebarProps {
  children: ReactNode
  isLoading?: boolean
  isError?: boolean
}

const TOOLTIP_MESSAGE = 'Filtros estão desabilitados'

const FilterSidebar = ({ children, isLoading, isError }: FilterSidebarProps) => {
  const activeFilters = useFilterStore((s) => s.activeFilters)
  const setActiveFilters = useFilterStore((s) => s.setActiveFilters)
  const setLastActivePreset = useFilterStore((s) => s.setLastActivePreset)
  const [, setCurrentPage] = usePagination()
  const [, setActivePanels] = useCollapsePanels()
  const [form] = Form.useForm()
  const [textSearchInput] = useTextSearchFilter()

  const [animatedParentRef] = useAutoAnimateRef()
  const inputRef = useRef<InputRef>(null)
  const [isDrawerOpen, toggleDrawer] = useToggle(false)
  const [showSavePreset, setShowSavePreset] = useState(false)

  const savePresetMutation = useCreatePresetMutation()
  const deletePresetMutation = useDeletePresetMutation()
  const { data: presetFilters, isError: presetsError } = usePresetFilters()

  useEffect(() => {
    // make sure the active filters are shown in the checkboxes
    if (form && Object.keys(activeFilters).length) {
      form.setFieldsValue(activeFilters)
    }
  }, [activeFilters, form])

  useEffect(() => {
    // focus input whenever save preset is shown
    if (showSavePreset && inputRef.current) {
      inputRef.current.focus()
    }
  }, [showSavePreset])

  function handleCloseDrawer() {
    toggleDrawer()
    setShowSavePreset(false)
  }

  function toggleSavePreset() {
    setShowSavePreset((prevState) => !prevState)
  }

  async function handleFilterProducts(values: Record<string, string | string[]>) {
    const filters = {
      ...values,
      text_search: textSearchInput, // inject text search value from the controlled input
    }

    const filteredValues = removeUndefinedFields(filters)

    if (filteredValues?.text_search) {
      filteredValues.text_search = sanitizeText(filteredValues.text_search as string)
    }

    if (showSavePreset && !inputRef.current?.input?.value) {
      return toast.error('Insira um nome para seu atalho')
    }

    const isSavingPreset = showSavePreset && !!inputRef.current?.input?.value
    const hasFilters = Object.keys(filteredValues).length > 0

    if (isSavingPreset && !hasFilters) {
      return toast.error('Para salvar um atalho você precisa selecionar pelo menos um filtro')
    }

    if (isSavingPreset) {
      const presetName = inputRef.current?.input?.value as string

      const filterPreset = {
        name: presetName,
        ...filteredValues,
      }

      const promise = savePresetMutation.mutateAsync({ filters: filterPreset })

      await toast.promise(promise, {
        loading: 'Salvando atalho...',
        success: 'Atalho salvo com sucesso!',
        error: (error) => error?.response?.data || 'Erro ao salvar atalho',
      })

      setShowSavePreset(false)
    }

    setCurrentPage(1)
    setActiveFilters(filteredValues)
    toggleDrawer()
  }

  async function deleteFilterPreset(presetId: string) {
    const promise = deletePresetMutation.mutateAsync({ presetId })

    toast.promise(promise, {
      loading: 'Deletando atalho...',
      success: 'Atalho deletado com sucesso!',
      error: 'Erro ao deletar atalho',
    })

    form.resetFields()
    setActivePanels([])
    setCurrentPage(1)
    resetFilters()
  }

  function handleClear() {
    form.resetFields()
    setActivePanels([])
    setCurrentPage(1)
    resetFilters()
    toggleDrawer()
  }

  function filterByPreset(filterPreset: PresetFilter) {
    const { filters } = filterPreset

    const isPresetActive = isEqual(activeFilters, filters)

    // if the preset is already active, reset the filters
    if (isPresetActive) {
      setLastActivePreset(null)
      return resetFilters()
    }

    setActiveFilters(filterPreset.filters)
    setLastActivePreset({ ...filterPreset })
  }

  return (
    <Container>
      <TooltipFilter title={isLoading || isError ? TOOLTIP_MESSAGE : null} placement="right">
        <SidebarTrigger
          type="link"
          onClick={toggleDrawer}
          icon={<FilterOutlined />}
          disabled={isLoading || isError}
        />
      </TooltipFilter>
      <Divider />
      <PresetsContainer>
        {!isLoading &&
          !presetsError &&
          presetFilters?.map((preset) => {
            return (
              <Preset key={preset.id}>
                <Tooltip
                  title={
                    <PresetFilterTooltip
                      name={preset.name}
                      id={preset.id}
                      data={Object.entries(preset.filters)}
                    />
                  }
                  color={'#fbfbfb'}
                  placement="right"
                >
                  <PresetButton
                    onClick={() => filterByPreset(preset)}
                    $isActive={isEqual(activeFilters, preset.filters)}
                  >
                    {getStringInitials(preset.name)}
                  </PresetButton>

                  <DeletePresetButton onClick={() => deleteFilterPreset(preset.id)} />
                  <Divider style={{ marginBottom: 0 }} />
                </Tooltip>
              </Preset>
            )
          })}
      </PresetsContainer>

      <Drawer
        headerStyle={{ padding: '10px 16px' }}
        title={
          <HeaderContainer ref={animatedParentRef}>
            <HeaderContent>
              <Typography>Filtros</Typography>
              <SavePresetButton
                htmlType="button"
                type="link"
                icon={showSavePreset ? <StopOutlined /> : <SaveOutlined />}
                onClick={toggleSavePreset}
              >
                {showSavePreset ? 'Cancelar' : 'Salvar atalho de filtros'}
              </SavePresetButton>
            </HeaderContent>

            {showSavePreset && (
              <SavePresetArea>
                <Typography>Ao salvar, este atalho ficará disponível na barra lateral.</Typography>
                <Input
                  placeholder="Nome do atalho"
                  ref={inputRef}
                  onPressEnter={() => form.submit()}
                />
              </SavePresetArea>
            )}
          </HeaderContainer>
        }
        placement="left"
        onClose={handleCloseDrawer}
        width={420}
        open={isDrawerOpen}
        footer={
          <Footer>
            <ClearButton onClick={handleClear} htmlType="button">
              Limpar
            </ClearButton>
            <Button type="primary" onClick={() => form.submit()} htmlType="submit">
              {showSavePreset ? 'Salvar e aplicar' : 'Filtrar'}
            </Button>
          </Footer>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleFilterProducts}>
          {children}
        </Form>
      </Drawer>
    </Container>
  )
}

export { FilterSidebar }
