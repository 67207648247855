import { Form, Input, Button } from 'antd'

import { ResetFirstPasswordCredentials } from '@/features/auth'

type ResetFirstPasswordFormProps = {
  handleResetPassword: (values: ResetFirstPasswordCredentials) => void
  isLoading: boolean
}

const ResetFirstPasswordForm = ({
  handleResetPassword,
  isLoading,
}: ResetFirstPasswordFormProps) => {
  return (
    <Form onFinish={handleResetPassword}>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            pattern: new RegExp(
              '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{8,25}$',
            ),
            message:
              'A senha deve conter letra maiuscula, minuscula, número e pelo menos 8 caracteres.',
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Senha" autoComplete="off" />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'As senhas devem corresponder',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('As senhas devem corresponder'))
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirme a senha" autoComplete="off" />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={isLoading}>
        Salvar
      </Button>
    </Form>
  )
}

export { ResetFirstPasswordForm }
