import { useMutation, useQueryClient } from '@tanstack/react-query'

import { filterKeys } from '../../queries'
import { PresetFilter } from '../../types'

import { filterStore as useFilterStore } from '@/app'
import { ActiveFilters } from '@/common'
import { api } from '@/services'

type UpdatePresetFilterMutation = {
  presetId: string
  filters: ActiveFilters
}

const useUpdatePresetMutation = () => {
  const queryClient = useQueryClient()
  const setLastActivePreset = useFilterStore((s) => s.setLastActivePreset)

  return useMutation(
    async ({ presetId, filters }: UpdatePresetFilterMutation) => {
      return api.put<PresetFilter>(`/presetfilter/${presetId}`, filters)
    },
    {
      onMutate: async () => await queryClient.cancelQueries(filterKeys.presets()),
      onSuccess: ({ data }, { presetId, filters }) => {
        const oldPresets = queryClient.getQueryData<PresetFilter[]>(filterKeys.presets())

        if (oldPresets) {
          const updatedPresets = oldPresets.map((preset) => {
            if (preset.id === presetId) {
              return {
                ...preset,
                filters: filters,
              }
            }

            return preset
          })

          queryClient.setQueryData(filterKeys.presets(), updatedPresets)
        }

        setLastActivePreset({
          id: data.id,
          name: data.name,
          filters: data.filters,
          username: data.username,
        })
      },
      onError: (err) => console.log(err),
      onSettled: async () => await queryClient.invalidateQueries(filterKeys.presets()),
    },
  )
}

export { useUpdatePresetMutation }
