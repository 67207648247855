import { Card } from 'antd'

import * as S from './styles'

import { useSignOut } from '@/common'
import {
  ChangePasswordCredentials,
  ChangePasswordForm,
  useChangePasswordMutation,
  getUserEmail,
} from '@/features/auth'
import { PageLayout } from '@/layouts'

const ChangePasswordPage = () => {
  const { signOut } = useSignOut()
  const userEmail = getUserEmail()
  const changePasswordMutation = useChangePasswordMutation()

  if (!userEmail) {
    signOut()
    return null
  }

  function handleChangePassword({ email, oldPassword, newPassword }: ChangePasswordCredentials) {
    changePasswordMutation.mutate({
      email,
      oldPassword,
      newPassword,
    })
  }

  return (
    <PageLayout windowTitle="Trocar senha">
      <S.Container>
        <Card>
          <S.TypographyText>Registre uma nova senha</S.TypographyText>

          <ChangePasswordForm
            handleChangePassword={handleChangePassword}
            userEmail={userEmail}
            isLoading={changePasswordMutation.isLoading}
          />
        </Card>
      </S.Container>
    </PageLayout>
  )
}

export { ChangePasswordPage }
