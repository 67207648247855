import { UserOutlined } from '@ant-design/icons'
import { Form, Input, Button } from 'antd'

import { ChangePasswordCredentials } from '@/features/auth'

type ChangePasswordFormProps = {
  handleChangePassword: (values: ChangePasswordCredentials) => void
  userEmail: string
  isLoading: boolean
}

const ChangePasswordForm = ({
  handleChangePassword,
  userEmail,
  isLoading,
}: ChangePasswordFormProps) => {
  return (
    <Form onFinish={handleChangePassword} initialValues={{ email: userEmail }}>
      <Form.Item name="email">
        <Input placeholder="Email" prefix={<UserOutlined />} disabled />
      </Form.Item>

      <Form.Item
        name="oldPassword"
        rules={[
          {
            required: true,
            message: 'Por favor, insira sua senha atual!',
          },
        ]}
      >
        <Input.Password placeholder="Senha atual" />
      </Form.Item>

      <Form.Item
        name="newPassword"
        rules={[
          {
            required: true,
            pattern: new RegExp(
              '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{8,25}$',
            ),
            message:
              'A senha deve conter letra maiuscula, minuscula, número e pelo menos 8 caracteres.',
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Nova senha" />
      </Form.Item>

      <Form.Item
        name="confirmNewPassword"
        dependencies={['newPassword']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'As novas senhas devem corresponder',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('As senhas devem corresponder'))
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirme a nova senha" />
      </Form.Item>

      <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
        Salvar
      </Button>
    </Form>
  )
}

export { ChangePasswordForm }
