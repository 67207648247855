import { Loading } from '@/components'
import { Typography } from 'antd'

import { Container } from './styles'

const LoadingMessage = () => (
  <Container>
    <Loading size="large" />
    <Typography>Carregando dados.</Typography>
  </Container>
)

export { LoadingMessage }
