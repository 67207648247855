import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'

import { ApiError } from '@/common'
import { api } from '@/services'

type ConfirmForgotPasswordMutation = {
  email: string
  newPassword: string
  confirmationCode: string
}

const errorMessages = new Map([
  [
    'Invalid verification code provided, please try again.',
    'Código de verificação inválido, tente novamente.',
  ],
  [
    'Attempt limit exceeded, please try after some time.',
    'Limite de tentativas excedido, tente novamente mais tarde.',
  ],
])

const useConfirmForgotPasswordMutation = () => {
  const navigate = useNavigate()

  return useMutation(
    async ({ email, newPassword, confirmationCode }: ConfirmForgotPasswordMutation) => {
      return api.post('/auth/confirm_reset_password', {
        email,
        password: newPassword,
        confirmationCode,
      })
    },
    {
      onSuccess: () => {
        toast.success('Senha trocada com sucesso!')
        navigate('/auth/login')
      },

      onError: (error: ApiError<string>) => {
        if (error.response?.status === 403) {
          return toast.error(errorMessages.get(error.response?.data.message) || 'Algo deu errado')
        }

        console.log(error)
      },
    },
  )
}

export { useConfirmForgotPasswordMutation }
