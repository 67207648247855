import styled from 'styled-components'

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const TooltipTitle = styled.span`
  font-size: 18px;
  text-transform: capitalize;

  color: ${({ theme }) => theme.colors.black};
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const TooltipText = styled.span`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
`

export const FilterName = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`
