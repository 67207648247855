import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

import * as S from './styles'

interface UserTagProps extends CustomTagProps {
  userInitial: string | undefined
}

const UserTag = ({ label, value, closable, onClose, userInitial }: UserTagProps) => {
  if (!value) return null

  return (
    <S.UserTag closable={closable} onClose={onClose}>
      {userInitial && <S.UserInitial>{userInitial}</S.UserInitial>}
      <span>{label}</span>
    </S.UserTag>
  )
}

export { UserTag }
