import { useQuery } from '@tanstack/react-query'

import { OffersByBrandGraph } from '../../types'
import { dashboardKeys } from '../query-keys'

import { api } from '@/services'

const fetchOffersByBrands = async () => {
  const { data } = await api.get<OffersByBrandGraph[]>('/dashboard/graphs/ofertas_marca')
  return data
}

const useOffersByBrandsGraph = () =>
  useQuery(dashboardKeys.offersByBrand(), fetchOffersByBrands, {
    retry: 2,
  })

export { useOffersByBrandsGraph }
