import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

import { Button, Form, Steps } from 'antd'
import dayjs from 'dayjs'
import { z } from 'zod'

import { FirstStep, SecondStep, ThirdStep } from './steps'
import * as S from './styles'

import { Modal } from '@/components'
import {
  NotificationPayload,
  useCreateNotification,
  removeNonDigitCharacters,
  useEditNotification,
  getDecimalsString,
} from '@/features/notifications'
import { useProduct } from '@/features/product'

type NotificationModalProps = {
  isOpen: boolean
  closeModal: () => void
  productId: string
  initialValues?: NotificationForm
  notificationId?: string
}

const formSchema = z.object({
  // step 1
  descricao: z.string(),
  data_inicio: z.string(),
  data_fim: z.string().optional().nullable(),
  preco_referencia: z.string(),
  variacao: z.string(),
  variation_type: z.string().optional().nullable(),
  // step 2
  notificacao_usuarios: z.array(z.string()),
  // step 3
  email_usuarios_sistema: z.array(z.string()).optional().nullable(),
  email_usuarios_externos: z.array(z.string()).optional().nullable(),
  mensagem_email: z.string().optional().nullable(),
})

export type NotificationForm = z.infer<typeof formSchema>

const NotificationModal = ({
  isOpen,
  closeModal,
  productId,
  initialValues,
  notificationId,
}: NotificationModalProps) => {
  const { data: product, isLoading, isError } = useProduct(productId)
  const createNotificationMutation = useCreateNotification()
  const editNotificationMutation = useEditNotification()
  const [variationType, setVariationType] = useState<'percent' | 'monetary'>(() => {
    if (initialValues?.variation_type) {
      return initialValues.variation_type as 'percent' | 'monetary'
    }

    return 'percent'
  })

  const [form] = Form.useForm<NotificationForm>()
  const [currentStep, setCurrentStep] = useState(0)
  const [formValues, setFormValues] = useState(() => {
    if (initialValues) {
      return initialValues
    } else {
      return {} as NotificationForm
    }
  })

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues as any)
    }
  }, [form, initialValues])

  useEffect(() => {
    if (isError) {
      closeModal()
      toast.error('Ocorreu um erro ao carregar o produto')
    }
  }, [closeModal, isError])

  const steps = [
    {
      title: 'Alerta',
      description: 'Parâmetros do alerta',
      content: (
        <FirstStep
          product={product}
          isProductLoading={isLoading}
          variationType={initialValues?.variation_type as 'percent' | 'monetary'}
          variation={initialValues?.variacao}
          onChangeVariationType={setVariationType}
        />
      ),
    },
    {
      title: 'Notificação',
      description: 'Receber pelo sistema',
      content: <SecondStep />,
    },
    {
      title: 'E-mail',
      description: 'Receber por e-mail',
      content: <ThirdStep selectedUsers={formValues?.notificacao_usuarios} />,
    },
  ]

  function returnStep(step: number) {
    setCurrentStep(step)
  }

  async function handleChangeStep(step: number) {
    try {
      await form.validateFields()

      const values = form.getFieldsValue()

      if (step === 1) {
        const startDate = dayjs(values.data_inicio).format('DD/MM/YYYY')
        const endDate = values.data_fim ? dayjs(values.data_fim).format('DD/MM/YYYY') : undefined

        setFormValues((oldValues) => ({
          ...oldValues,
          ...values,
          data_inicio: startDate,
          data_fim: endDate,
        }))
      } else {
        setFormValues((oldValues) => ({ ...oldValues, ...values }))
      }

      setCurrentStep(step)
    } catch (e) {
      console.error(e)
    }
  }

  async function handleSubmit(values: NotificationForm) {
    try {
      const completeForm = { ...formValues, ...values }

      const validFormValues = formSchema.parse(completeForm)

      const variationTypeKey = variationType === 'percent' ? 'alert_percent' : 'alert_price'

      let priceVariation
      // initialValues?.variacao !== validFormValues.variacao,

      if (variationType === 'monetary') {
        const shouldDivide = initialValues
          ? initialValues?.variacao !== validFormValues.variacao
          : true

        priceVariation = getDecimalsString({
          value: Number(removeNonDigitCharacters(validFormValues.variacao)),
          shouldDivideBy100: shouldDivide,
        })
        console.log({ valor: validFormValues.variacao, priceVariation })
      } else {
        priceVariation = Number(removeNonDigitCharacters(validFormValues.variacao))
      }

      const priceReference = getDecimalsString({
        value: Number(removeNonDigitCharacters(validFormValues.preco_referencia)),
        shouldDivideBy100: true,
      })

      // se for editar para um valor monetario e for menor do que 1, muktiplica por 100 para validar para o Dinero.js
      if (initialValues && variationType === 'monetary' && Number(priceVariation) < 1) {
        priceVariation = (Number(priceVariation) * 100).toFixed(2)
      }

      const payload: NotificationPayload = {
        productId,
        alert_description: validFormValues.descricao,
        start_date: validFormValues.data_inicio,
        end_date: validFormValues.data_fim,
        [variationTypeKey]: priceVariation,
        alert_price_reference: priceReference,
        usernames: validFormValues.notificacao_usuarios,
        emails_internos: validFormValues.email_usuarios_sistema,
        emails_externos: validFormValues.email_usuarios_externos,
        status: true,
        mensagem_email: validFormValues.mensagem_email,
      }

      if (variationType === 'percent') {
        payload.alert_price = null
      } else {
        payload.alert_percent = null
      }

      if (initialValues && notificationId) {
        const promise = editNotificationMutation.mutateAsync({
          notification: payload,
          notificationId: notificationId,
        })

        await toast.promise(promise, {
          loading: 'Editando notificação...',
          success: 'Notificação editada com sucesso!',
          error: 'Ocorreu um erro ao editar a notificação',
        })
      } else {
        const promise = createNotificationMutation.mutateAsync({ notification: payload })

        await toast.promise(promise, {
          loading: 'Criando notificação...',
          success: 'Notificação criada com sucesso!',
          error: 'Ocorreu um erro ao criar a notificação',
        })
      }
    } catch (e) {
      console.log(e)
    }

    form.setFieldsValue({})
    closeModal()
  }

  if (isError) {
    return <></>
  }

  return (
    <Modal
      title="Configuração do alerta"
      open={isOpen}
      onCancel={closeModal}
      bodyStyle={{ padding: '16px 24px' }}
      modalRender={(modal) => <S.Container>{modal}</S.Container>}
      footer={
        <>
          {currentStep === 0 ? (
            <Button danger onClick={closeModal}>
              Cancelar
            </Button>
          ) : (
            <S.ReturnButton
              disabled={editNotificationMutation.isLoading || createNotificationMutation.isLoading}
              onClick={() => returnStep(currentStep - 1)}
            >
              Voltar
            </S.ReturnButton>
          )}

          {currentStep < 2 ? (
            <Button type="primary" onClick={() => handleChangeStep(currentStep + 1)}>
              Próximo
            </Button>
          ) : (
            <Button
              disabled={editNotificationMutation.isLoading || createNotificationMutation.isLoading}
              type="primary"
              onClick={() => form.submit()}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Content>
        <Steps current={currentStep} items={steps} />
        <Form
          layout="vertical"
          form={form}
          onFinish={(v) => handleSubmit(v)}
          disabled={editNotificationMutation.isLoading || createNotificationMutation.isLoading}
        >
          {steps[currentStep].content}
        </Form>
      </S.Content>
    </Modal>
  )
}

export { NotificationModal }
