import { useEffect, useState } from 'react'
import { AiFillExclamationCircle } from 'react-icons/ai'
import { NumericFormat } from 'react-number-format'

import { SwapRightOutlined } from '@ant-design/icons'
import { Radio, Form, Image, Typography, Skeleton } from 'antd'
import dayjs from 'dayjs'
import Dinero from 'dinero.js'

import * as S from '../styles'

import { formatCurrency } from '@/common'

import { NotificationForm } from '..'

import { InputLabel } from '@/components'
import { DineroFormat, removeNonDigitCharacters } from '@/features/notifications'
import { ProductDetails } from '@/features/product'

type VariationType = 'percent' | 'monetary'

interface Variation {
  percent?: number
  monetary?: number
}

type FirstStepProps = {
  product: ProductDetails | undefined
  isProductLoading: boolean
  variation?: string
  variationType?: VariationType
  onChangeVariationType: (value: VariationType) => void
}

const FirstStep = ({
  product,
  isProductLoading,
  variationType,
  variation,
  onChangeVariationType,
}: FirstStepProps) => {
  const form = Form.useFormInstance<NotificationForm>()

  const [inputType, setInputType] = useState<VariationType>(() =>
    variationType ? variationType : 'percent',
  )
  // const [variationNumber, setVariationNumber] = useState<number | undefined>(() => {
  //   if (!variation) return undefined

  //   return Number(variation)
  // })

  const [variationNumber, setVariationNumber] = useState<Variation>(() => {
    if (!variation) return { percent: undefined, monetary: undefined }

    return {
      percent: Number(variation),
      monetary: Number(variation),
    }
  })

  const referencePrice = Form.useWatch('preco_referencia', form)

  const [alertRange, setAlertRange] = useState({ min: 0, max: 0 })

  const labels = {
    description: (
      <InputLabel label="Descrição do alerta" tooltip="Descrição para identificar o alerta" />
    ),
    referencePrice: (
      <InputLabel
        label="Preço de referência (R$)"
        tooltip="Usado em conjunto com a variação para disparar o alerta"
      />
    ),
    suggestPrice: (
      <InputLabel
        label="Preço sugerido (R$)"
        tooltip="Usado em conjunto com a variação para disparar o alerta"
      />
    ),
    dates: (
      <InputLabel
        label="Prazo do alerta"
        tooltip="Prazo de funcionalidade do alerta (não precisa ter uma data final)"
      />
    ),
    variation: (
      <InputLabel
        label="Variação"
        tooltip="Aplicada em cima do preço de referencia para disparar o alerta"
      />
    ),
  }

  // Set initial price if product has a suggested price
  useEffect(() => {
    if (product?.precos.sugerido) {
      form.setFieldValue('preco_referencia', formatCurrency({ value: product.precos.sugerido }))
    }
  }, [form, product])

  useEffect(() => {
    if (referencePrice && (variationNumber !== undefined || form.getFieldValue('variacao'))) {
      try {
        const variation = variationNumber
        // const variation =
        //   variationNumber || Number(removeNonDigitCharacters(form.getFieldValue('variacao')))

        const parsedReferencePrice = Number(removeNonDigitCharacters(referencePrice))

        if (inputType === 'percent') {
          const percentageValue = Dinero({ amount: parsedReferencePrice, currency: 'BRL' })
            .percentage(variation.percent!)
            .getAmount()

          setAlertRange({
            min: Dinero({ amount: parsedReferencePrice })
              .subtract(Dinero({ amount: percentageValue }))
              .getAmount(),
            max: Dinero({ amount: parsedReferencePrice })
              .add(Dinero({ amount: percentageValue }))
              .getAmount(),
          })
        }

        if (inputType === 'monetary') {
          setAlertRange({
            min: Dinero({ amount: parsedReferencePrice })
              .subtract(Dinero({ amount: variation.monetary! }))
              .getAmount(),
            max: Dinero({ amount: parsedReferencePrice })
              .add(Dinero({ amount: variation.monetary! }))
              .getAmount(),
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [form, inputType, variationNumber, referencePrice])

  return (
    <S.Content>
      {isProductLoading ? (
        <Skeleton active />
      ) : (
        <>
          <S.Title level={5}>Parâmetros do alerta</S.Title>

          <S.ProductCard bordered={false}>
            <Image src={product?.informacoes.url_imagem} alt={product?.modelo} preview={false} />

            <S.CardContent>
              <Typography.Text>
                ID: <Typography.Text strong>{product?.id}</Typography.Text>
              </Typography.Text>

              <Typography.Text>{product?.modelo}</Typography.Text>
              <Typography.Text>
                {product?.precos.sugerido ? 'Preço sugerido:' : 'Preço médio:'}{' '}
                <Typography.Text strong className="price">
                  {product?.precos.sugerido
                    ? formatCurrency({ value: product?.precos.sugerido })
                    : formatCurrency({ value: product!.precos.media })}
                </Typography.Text>
              </Typography.Text>
            </S.CardContent>
          </S.ProductCard>

          <S.FormRow>
            <Form.Item
              label={labels.description}
              name="descricao"
              required={false}
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira uma descrição',
                },
                {
                  min: 2,
                  message: 'A descrição deve ter no mínimo 2 caracteres',
                },
                {
                  max: 20,
                  message: 'A descrição deve ter no máximo 20 caracteres',
                },
              ]}
            >
              <S.Input type="text" placeholder="Ex. Blackfriday" minLength={2} maxLength={20} />
            </Form.Item>

            <S.DatePickerArea>
              <Form.Item label={labels.dates}>
                <Form.Item
                  name={'data_inicio'}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira pelo menos uma data inicial para o alerta',
                    },
                  ]}
                >
                  <S.DateInput
                    placeholder={'Data inicio'}
                    format={'DD/MM/YYYY'}
                    $hasError={form.getFieldError('data_inicio').length > 0}
                    disabledDate={(currentDate) =>
                      currentDate && currentDate.isBefore(dayjs(), 'day')
                    }
                  />
                </Form.Item>

                <SwapRightOutlined />

                <Form.Item name={'data_fim'} noStyle>
                  <S.DateInput
                    placeholder={'Data fim'}
                    format={'DD/MM/YYYY'}
                    $hasError={form.getFieldError('data_inicio').length > 0}
                    disabledDate={(currentDate) =>
                      currentDate && currentDate.isBefore(dayjs(), 'day')
                    }
                  />
                </Form.Item>
              </Form.Item>
            </S.DatePickerArea>
          </S.FormRow>

          <S.FormRow>
            <Form.Item
              label={product?.precos.sugerido ? labels.suggestPrice : labels.referencePrice}
              name={'preco_referencia'}
              required={false}
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira um preço de referência para o alerta',
                },
              ]}
            >
              <NumericFormat
                customInput={S.Input}
                placeholder={
                  product?.precos.sugerido
                    ? formatCurrency({ value: product.precos.sugerido })
                    : 'R$ 1.800,00'
                }
                prefix={'R$'}
                fixedDecimalScale
                allowNegative={false}
                decimalScale={2}
                thousandSeparator="."
                decimalSeparator=","
              />
            </Form.Item>

            <S.SwitchInputContainer>
              <Form.Item label={labels.variation}>
                <S.RadioSwitch
                  optionType="button"
                  buttonStyle="solid"
                  value={inputType}
                  onChange={(e) => {
                    setInputType(e.target.value)
                    onChangeVariationType(e.target.value)
                  }}
                >
                  <Radio.Button value="percent">Percentual (%)</Radio.Button>
                  <Radio.Button value="monetary">Valor (R$)</Radio.Button>
                </S.RadioSwitch>
                <Form.Item
                  noStyle
                  name="variacao"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(new Error('Por favor, insira uma variação'))
                        }

                        if (value && inputType === 'percent') {
                          // console.log(Number(value), inputType)

                          if (Number(removeNonDigitCharacters(value)) <= 0) {
                            return Promise.reject(
                              new Error('A porcentagem precisa ser maior do que 0'),
                            )
                          }

                          if (Number(removeNonDigitCharacters(value)) > 100) {
                            return Promise.reject(
                              new Error('A porcentagem precisa ser menor do que 100'),
                            )
                          }
                        }

                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <NumericFormat
                    customInput={S.Input}
                    allowNegative={false}
                    placeholder={inputType === 'percent' ? '10%' : 'R$ 500,00'}
                    prefix={inputType === 'monetary' ? 'R$' : undefined}
                    suffix={inputType === 'percent' ? '%' : undefined}
                    allowedDecimalSeparators={inputType === 'monetary' ? ['.'] : undefined}
                    decimalSeparator={inputType === 'monetary' ? ',' : undefined}
                    thousandSeparator={inputType === 'monetary' ? '.' : undefined}
                    decimalScale={inputType === 'monetary' ? 2 : 0}
                    fixedDecimalScale={inputType === 'monetary' ? true : undefined}
                    min={1}
                    max={100}
                    onValueChange={(values) => {
                      const numericValue = Number(removeNonDigitCharacters(values.value))

                      setVariationNumber({
                        percent: values.floatValue,
                        monetary: numericValue,
                      })
                    }}
                    // isAllowed={(values) => {
                    //   if (inputType === 'percent') {
                    //     return values.floatValue! <= 100
                    //   }

                    //   return true
                    // }}
                    $hasError={form.getFieldError('variacao').length > 0}
                  />
                </Form.Item>
              </Form.Item>
            </S.SwitchInputContainer>
          </S.FormRow>

          <S.FormRow>
            {(variationNumber || form.getFieldValue('variacao')) && (
              <>
                {inputType === 'percent' &&
                variationNumber.percent !== undefined &&
                (variationNumber.percent > 100 || variationNumber.percent <= 0) ? (
                  <></>
                ) : (
                  <S.AlertRulesCard>
                    <AiFillExclamationCircle size={16} />
                    <Typography.Text>
                      Os valores fora do intervalo entre{' '}
                      <Typography.Text strong>
                        {alertRange.min < 0 ? 0 : DineroFormat({ value: alertRange.min })}
                      </Typography.Text>
                      <br />e{' '}
                      <Typography.Text strong>
                        {DineroFormat({ value: alertRange.max })}
                      </Typography.Text>{' '}
                      serão notificados.
                    </Typography.Text>
                  </S.AlertRulesCard>
                )}
              </>
            )}
          </S.FormRow>
        </>
      )}
    </S.Content>
  )
}

export { FirstStep }
