import { useMutation, useQueryClient } from '@tanstack/react-query'

import { notificationKeys } from '../../queries/query-keys'
import { NotificationPayload } from '../../types'

import { api } from '@/services'

type EditNotificationMutation = {
  notificationId: string
  notification: NotificationPayload
}

const useEditNotification = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ notification, notificationId }: EditNotificationMutation) => {
      return api.put(`/alerts/${notificationId}`, notification)
    },
    {
      onMutate: async () => await queryClient.cancelQueries(),
      onSettled: () => queryClient.invalidateQueries(notificationKeys.lists()),
    },
  )
}

export { useEditNotification }
