import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Input, Button } from 'antd'

import { LoginCredentials } from '@/features/auth'

type LoginFormProps = {
  handleSignIn: (values: LoginCredentials) => void
  isLoading: boolean
}

const LoginForm = ({ handleSignIn, isLoading }: LoginFormProps) => {
  return (
    <Form onFinish={handleSignIn}>
      <Form.Item
        name="email"
        hasFeedback
        rules={[
          {
            type: 'email',
            required: true,
            message: 'Por favor, insira um email valido!',
          },
        ]}
      >
        <Input placeholder="Email" prefix={<UserOutlined />} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Por favor, insira uma senha', min: 8 }]}
        hasFeedback
      >
        <Input.Password
          placeholder="Senha"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          prefix={<LockOutlined />}
        />
      </Form.Item>

      <Button type="primary" loading={isLoading} htmlType="submit">
        Entrar
      </Button>
    </Form>
  )
}

export { LoginForm }
