import { MailOutlined } from '@ant-design/icons'
import { Form, FormInstance, Input, Button } from 'antd'

import { ForgotPasswordCredentials } from '@/features/auth'

type ForgotPasswordFormProps = {
  form: FormInstance<ForgotPasswordCredentials>
  handleSubmit: (values: ForgotPasswordCredentials) => void
  isLoading: boolean
}

const ForgotPasswordForm = ({ handleSubmit, isLoading, form }: ForgotPasswordFormProps) => {
  return (
    <Form onFinish={handleSubmit} form={form}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Por favor, insira um email valido!',
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={isLoading}>
        Enviar codigo
      </Button>
    </Form>
  )
}

export { ForgotPasswordForm }
