import styled from 'styled-components'

import { hexToRgba } from '@/common'

export const Container = styled.table`
  width: 100%;
  margin: 8px 0px;
  text-transform: capitalize;
  border-collapse: collapse;

  tr {
    text-align: center;
    height: 48px;

    :nth-of-type(odd) {
      background-color: ${hexToRgba('#f0f2f5', 0.7)};
    }
  }
  td:nth-child(2) {
    border-right: 1px solid #f0f2f5;
  }

  td.title {
    padding: 10px 5px;
    text-align: right;
    width: 25%;
  }

  td.info {
    padding: 10px 5px;
    text-align: left;

    width: 25%;
    font-weight: bold;
  }
`
