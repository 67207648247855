import { useMutation, useQueryClient } from '@tanstack/react-query'

import { productKeys } from '../../../queries'
import type { ProductDetails } from '../../../types'

import { api } from '@/services'

type SuggestedPriceMutation = {
  productId: string
  suggestedPrice: number | null
}

const useSuggestedPriceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ productId, suggestedPrice }: SuggestedPriceMutation) => {
      return api.put(`/produto/${productId}`, {
        sugerido: suggestedPrice,
      })
    },
    {
      onMutate: async ({ productId, suggestedPrice }) => {
        await queryClient.cancelQueries(productKeys.detail(productId))

        const previousProduct = queryClient.getQueryData<ProductDetails>(
          productKeys.detail(productId),
        )

        if (previousProduct) {
          const updatedProduct = {
            ...previousProduct,
            precos: {
              ...previousProduct.precos,
              sugerido: suggestedPrice,
            },
          }

          // console.log(previousProduct.precos, updatedProduct.precos)

          queryClient.setQueryData(productKeys.detail(productId), updatedProduct)

          return previousProduct // context for rollback
        }
      },
      onError: (error, { productId }, previousProduct) => {
        console.error(error)

        if (previousProduct) {
          queryClient.setQueryData(productKeys.detail(productId), previousProduct)
        }
      },
      onSettled: (_, __, { productId }) => {
        queryClient.invalidateQueries(productKeys.detail(productId))
      },
    },
  )
}

export { useSuggestedPriceMutation }
