import { Navigate, Outlet } from 'react-router-dom'

import { authStore as useAuthStore } from '@/app'

const PublicRoute = () => {
  const isAuthenticated = useAuthStore((s) => s.isUserAuthenticated)

  if (isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

export { PublicRoute }
