import { useMutation, useQueryClient } from '@tanstack/react-query'

import { tagKeys } from '../../../queries'
import type { Tag } from '../../../types'

import { api } from '@/services'

type RemoveProductTagMutation = {
  tagId: string
  productId: string
}

const useRemoveProductTagMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ tagId, productId }: RemoveProductTagMutation) => {
      return api.delete(`/tag/${tagId}`)
    },
    {
      onMutate: async ({ tagId, productId }) => {
        await queryClient.cancelQueries(tagKeys.all)

        const oldProductTags = queryClient.getQueryData<Tag[]>(tagKeys.product(productId))

        if (oldProductTags) {
          const updatedProductTags = oldProductTags.filter((tag) => tag.id !== tagId)

          queryClient.setQueryData(tagKeys.product(productId), updatedProductTags)
        }

        return oldProductTags
      },
      onError: (err, { productId }, oldProductTags) => {
        console.log(err)

        if (oldProductTags) {
          queryClient.setQueryData(tagKeys.product(productId), oldProductTags)
        }
      },
      onSettled: () => queryClient.invalidateQueries(tagKeys.all),
    },
  )
}

export { useRemoveProductTagMutation }
