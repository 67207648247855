import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import {
  DashboardApiResponse,
  GraphFilterParams,
  HighlightedProducts,
  HighlightedMarketplaces,
} from '../../types'
import { dashboardKeys } from '../query-keys'

import { api } from '@/services'

export async function fetchHighlights({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof dashboardKeys)['highlight']>>) {
  const [, , marketplace, { data_min, data_max }] = queryKey

  const params = new URLSearchParams()
  if (data_min) params.append('data_min', data_min)
  if (data_max) params.append('data_max', data_max)

  const { data } = await api.get<DashboardApiResponse<HighlightedProducts[]>>(
    `/produto/highlights?marketplace=${marketplace}&${params.toString()}`,
  )
  return data.results
}

const useHighlights = (marketplace: HighlightedMarketplaces, params: GraphFilterParams) =>
  useQuery(dashboardKeys.highlight(marketplace, params), fetchHighlights, { retry: 2 })

export { useHighlights }
