import styled from 'styled-components'

export const Container = styled.div`
  height: 20px;
  width: 20px;
  padding: 0;
  border: none;
  /* cursor: pointer; */

  font-size: 20px;
  color: #4a91fd;
`
