import { useMutation, useQueryClient } from '@tanstack/react-query'

import { filterKeys } from '../../queries'
import { PresetFilter } from '../../types'

import { api } from '@/services'

type DeletePresetMutation = {
  presetId: string
}

const useDeletePresetMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ presetId }: DeletePresetMutation) => {
      return api.delete(`/presetfilter/${presetId}`)
    },
    {
      onMutate: async ({ presetId }) => {
        await queryClient.cancelQueries(filterKeys.presets())

        const oldPresets = queryClient.getQueryData<PresetFilter[]>(filterKeys.presets())

        if (oldPresets) {
          const updatedPresets = oldPresets.filter((preset) => preset.id !== presetId)

          queryClient.setQueryData(filterKeys.presets(), updatedPresets)
        }
      },
      onError: (err) => console.log(err),
      onSettled: () => {
        queryClient.invalidateQueries(filterKeys.presets())
      },
    },
  )
}

export { useDeletePresetMutation }
