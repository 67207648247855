import { styledTheme as theme } from '@/app/styles'

export const main = {
  backgroundColor: '#ffffff',
  margin: '0 auto',
  border: '1px solid #eaeaea',
  borderRadius: '5px',
}

export const header = {
  width: '100%',
  backgroundColor: theme.colors.brand,
  margin: '0 auto',
  padding: '8px',
}

export const container = {
  border: '1px solid #eaeaea',
  borderRadius: '5px',
  margin: '0 auto',
  padding: '0 8px',
  width: '100%',
}

export const text = {
  color: '#414243',
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: '14px',
  lineHeight: '24px',
}

export const price = {
  color: '#434343',
  fontSize: '18px',
}

export const productName = {
  textAlign: 'center' as const,
}

export const link = {
  color: theme.colors.brand,
  textDecoration: 'none',
}

export const product = {
  textTransform: 'uppercase' as const,
  fontWeight: 'bold',
  fontSize: 16,
}

export const table = {
  width: '100%',
  borderCollapse: 'collapse' as const,
  backgroundColor: '#F7F8FB',
  borderRadius: '5px',
  border: '1px solid #eee',
}

export const detailsTable = {
  ...table,
  border: 'none',
}

export const tableItem = {
  border: '1px solid #eaeaea',
  textAlign: 'left' as const,
  padding: '8px',
  color: '#333',
}

export const detailsItems = {
  ...tableItem,
  border: 'none',
  borderBottom: '1px solid #eee',
}

export const productDetail = {
  ...detailsItems,
  fontWeight: 'bold',
}

export const btn = {
  backgroundColor: theme.colors.brand,
  borderRadius: '5px',
  color: '#fff',
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '50px',
  textDecoration: 'none',
  textAlign: 'center' as const,
  padding: '12px 20px',
}

export const hr = {
  border: 'none',
  borderTop: '1px solid #eaeaea',
  margin: '26px 0',
  width: '100%',
}

export const footer = {
  color: '#666666',
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: '12px',
  lineHeight: '24px',
}

export const imgcontainer = {
  textAlign: 'center' as const,
}

export const productimg = {
  width: 'auto',
  height: 'auto',
  maxWidth: '200px',
  maxHeight: '150px',
  margin: 'auto auto',
}
