import { Tag } from 'antd'
import styled from 'styled-components'

export const UserTag = styled(Tag)`
  border-radius: 999999px;
  font-size: 14px;
  padding: 0 6px 0 26px;
  position: relative;
`

export const UserInitial = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brand};
  border-radius: 999999px;
  padding: 0 7px 0 6px;
`
