import styled from 'styled-components'

export const Container = styled.div`
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`
