import { useMutation, useQueryClient } from '@tanstack/react-query'

import { userKeys } from '../../../queries'

import type { User } from '@/common'
import { api } from '@/services'

type EditUserMutation = {
  name: string
  username: string
  phoneNumber: string
  oldGroup: string
  newGroup: string
}

const useEditUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (data: EditUserMutation) => {
      return api.put(`/user/${data.username}`, data)
    },
    {
      onMutate: async (newUserData) => {
        await queryClient.cancelQueries(userKeys.all)

        const previousUsers = queryClient.getQueryData<User[]>(userKeys.list())

        if (previousUsers) {
          queryClient.setQueryData(userKeys.list(), (old: any) => {
            return old.map((user: any) => {
              if (user.username === newUserData.username) {
                return {
                  ...user,
                  ...newUserData,
                }
              }

              return user
            })
          })
        }

        return previousUsers
      },
      onError: (error, _, previousUsers) => {
        console.log(error)

        if (previousUsers) {
          queryClient.setQueryData(userKeys.list(), previousUsers)
        }
      },
      onSettled: () => queryClient.invalidateQueries(userKeys.all),
    },
  )
}

export { useEditUserMutation }
