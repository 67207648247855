import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Form, FormInstance, Typography } from 'antd'

import { Header, CancelButton, SaveButton, Content } from './styles'

import { useToggle } from '@/common'
import type { User } from '@/common'
import { Modal } from '@/components'
import {
  NewUserCredentials,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUsers,
  CreateUserForm,
  UserListTable,
  EditUserForm,
  EditUserCredentials,
  useEditUserMutation,
} from '@/features/users'
import { PageLayout } from '@/layouts'

type ModalFooterProps = {
  onCancel: () => void
  handleSubmit: () => void
  isLoading: boolean
}

const DEFAULT_PHONE_NUMBER = '99999-9999'

const Users = () => {
  const navigate = useNavigate()
  const { data: users, isLoading } = useUsers()

  const createUserMutation = useCreateUserMutation()
  const deleteUserMutation = useDeleteUserMutation()
  const editUserMutation = useEditUserMutation()

  const [isCreateUserModalVisible, toggleCreateModal] = useToggle(false)
  const [isEditUserModalVisible, toggleEditModal] = useToggle(false)
  const [createUserForm] = Form.useForm()
  const [editUserForm] = Form.useForm()

  function submitForm(form: FormInstance<any>) {
    form.submit()
  }

  async function handleCreateUser(values: NewUserCredentials) {
    const data = {
      name: values.name,
      username: values.username,
      password: values.password,
      groups: [values.groups],
      phone_number: DEFAULT_PHONE_NUMBER,
    }

    const promise = createUserMutation.mutateAsync(data)

    await toast.promise(promise, {
      loading: 'Criando usuário...',
      success: 'Usuário criado com sucesso!',
      error: 'Erro ao criar usuário',
    })

    createUserForm.resetFields()
    toggleCreateModal()
  }

  function handleDeleteUser(username: string) {
    const promise = deleteUserMutation.mutateAsync(username)

    toast.promise(promise, {
      loading: 'Deletando usuário...',
      success: 'Usuário deletado com sucesso!',
      error: 'Erro ao deletar usuário',
    })
  }

  function handleStartEditing(user: User) {
    editUserForm.setFieldsValue(user)

    const userGroup = typeof user.groups === 'string' ? user.groups : user.groups[0]
    editUserForm.setFieldValue('old_group', userGroup)
    toggleEditModal()
  }

  async function handleEditUser(values: EditUserCredentials) {
    const data = {
      name: values.name,
      username: values.username,
      phoneNumber: DEFAULT_PHONE_NUMBER,
      oldGroup: editUserForm.getFieldValue('old_group') as string,
      newGroup: values.groups,
    }

    const promise = editUserMutation.mutateAsync(data)

    await toast.promise(promise, {
      loading: 'Editando usuário...',
      success: 'Usuário editado com sucesso!',
      error: 'Erro ao editar usuário',
    })

    toggleEditModal()
  }

  function closeModal(type: 'create' | 'edit') {
    if (type === 'create') {
      createUserForm.resetFields()
      toggleCreateModal()
      return
    }

    if (type === 'edit') {
      editUserForm.resetFields()
      toggleEditModal()
      return
    }
  }

  const ModalFooter = ({ onCancel, handleSubmit, isLoading }: ModalFooterProps) => (
    <>
      <CancelButton onClick={onCancel} disabled={isLoading}>
        Cancelar
      </CancelButton>
      <SaveButton type="primary" htmlType="submit" onClick={handleSubmit} loading={isLoading}>
        Salvar
      </SaveButton>
    </>
  )

  return (
    <PageLayout windowTitle="Usuários">
      <Header>
        <Typography.Title level={1}>Usuários</Typography.Title>

        <div>
          <Button type="link" onClick={() => navigate('/groups')}>
            Consultar Grupos
          </Button>

          <Divider
            type="vertical"
            orientation="center"
            style={{ margin: 'auto 0', borderColor: 'rgba(0,0,0,0.2)' }}
          />

          <Button type="link" onClick={toggleCreateModal} icon={<PlusOutlined />}>
            Criar Usuário
          </Button>
        </div>
      </Header>

      <Divider style={{ borderColor: 'rgba(0,0,0,0.2)', margin: '0 0 20px' }} />

      <UserListTable
        data={users as User[]}
        isLoading={isLoading}
        onDeleteUser={handleDeleteUser}
        handleEditUser={handleStartEditing}
      />

      {isCreateUserModalVisible && (
        <Modal
          title="Novo Usuário"
          open={isCreateUserModalVisible}
          onCancel={() => closeModal('create')}
          centered
          footer={
            <ModalFooter
              handleSubmit={() => submitForm(createUserForm)}
              onCancel={() => closeModal('create')}
              isLoading={createUserMutation.isLoading}
            />
          }
        >
          <Content>
            <CreateUserForm handleCreateUser={handleCreateUser} form={createUserForm} />
          </Content>
        </Modal>
      )}

      {isEditUserModalVisible && (
        <Modal
          title="Editar Usuário"
          open={isEditUserModalVisible}
          onCancel={() => closeModal('edit')}
          styles={{ body: { padding: '8px 24px' } }}
          footer={
            <ModalFooter
              handleSubmit={() => submitForm(editUserForm)}
              onCancel={() => closeModal('edit')}
              isLoading={editUserMutation.isLoading}
            />
          }
        >
          <EditUserForm
            form={editUserForm}
            handleEditUser={handleEditUser}
            isEditing={editUserMutation.isLoading}
          />
        </Modal>
      )}
    </PageLayout>
  )
}

export { Users }
