import { useNavigate } from 'react-router-dom'

import { Button, Typography } from 'antd'

import { Divider, Header } from './styles'

import { Group, GroupListTable, useGroups } from '@/features/users'
import { PageLayout } from '@/layouts'

const Groups = () => {
  const navigate = useNavigate()
  const { data: groups, isLoading } = useGroups()

  const sortedGroups = groups?.sort((a, b) => {
    return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
  })

  return (
    <PageLayout windowTitle="Grupos">
      <Header>
        <Typography.Title level={1}>Grupos</Typography.Title>
        <Button type="link" onClick={() => navigate('/users')}>
          Consultar Usuários
        </Button>
      </Header>

      <Divider />

      <GroupListTable data={sortedGroups as Group[]} isLoading={isLoading} />
    </PageLayout>
  )
}

export { Groups }
