import { Button, Typography, Divider as AntdDivider, Select as AntdSelect } from 'antd'
import styled from 'styled-components'

type SecondContainerProps = {
  $isRenderingList: boolean
}

export const BackButton = styled(Button)`
  color: #80c343;
  border-radius: 2px;
  border: 1px solid #80c343;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const FinishButton = styled(Button)`
  border-radius: 2px;
  background: #83b81a;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const FirstContainer = styled.div`
  padding: 10px 25px;

  .ant-typography {
    line-height: initial;
  }

  .ant-typography strong {
    color: #666;
    font-size: 22px;
    font-weight: 700;
  }
`

export const ShareButton = styled(Button)`
  padding: 0;
`

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export const Title = styled(Typography.Text)`
  color: #666;
  font-size: 20px;
  font-weight: 700;

  text-transform: capitalize;
`

export const DescriptionArea = styled.div`
  display: flex;

  padding: 40px 15px;
  gap: 20px;
`

export const ValueArea = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
`

export const Text = styled(Typography.Text)`
  color: #666;
  font-size: 14px;
`

export const ButtonArea = styled.div`
  display: flex;

  gap: 9px;
  padding: 22px 0 0;
`

export const ProductButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid #80c343;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const CategorizeButton = styled(Button)`
  color: #80c343;
  border-radius: 4px;
  border: 1px solid #80c343;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const Divider = styled(AntdDivider)`
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0;
`

export const SecondContainer = styled.div<SecondContainerProps>`
  padding: 28px 60px;

  height: ${({ $isRenderingList }) => `${$isRenderingList ? 380 : 260}px`};
  overflow-y: scroll;

  .ant-typography {
    line-height: initial;
  }

  .ant-typography strong {
    color: #666;
    font-size: 22px;
    font-weight: 700;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const ProductTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SimilarProductText = styled(Typography.Text)`
  font-size: 20px;
  color: #434343;
`

export const FindValue = styled(Typography.Text)`
  font-size: 20px;
  color: #4a91fd;
`

export const DropdownArea = styled.div`
  display: flex;
  align-items: center;

  gap: 7px;
  color: #434343;

  .ant-select .ant-select-arrow {
    color: #5ea320;
  }

  .ant-select-selector {
    border: 0 !important;
  }
`

export const Select = styled(AntdSelect)`
  width: 257px;
  border: 2px;

  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
`

export const ProductArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 40px;
`

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InformationArea = styled.div`
  display: flex;

  gap: 12px;
`

export const ProductDescriptionArea = styled.div`
  display: flex;
  flex-direction: column;

  width: 447px;
  gap: 8px;
`

export const ProductTextTitle = styled(Typography.Text)`
  color: #666;
  font-size: 14px;
  font-weight: 600;

  text-transform: capitalize;
`

export const PriceArea = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonsArea = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  align-self: flex-end;

  gap: 6px;
`

export const IconsArea = styled.div`
  display: flex;

  gap: 20px;
`

export const SkeletonFirstContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 25px;
  gap: 10px;
`

export const SkeletonTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 8px;
`

export const SkeletonTitleArea = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 50px;
  padding-bottom: 30px;
`

export const SkeletonInformationArea = styled.div`
  display: flex;

  padding-left: 22px;
  gap: 30px;
`

export const SkeletonText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;
`

export const SkeletonSecondContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 60px 25px;
  gap: 10px;
`

export const SkeletonSecondTitle = styled.div`
  display: flex;
  justify-content: space-around;

  padding: 0 0 35px;
  gap: 150px;
`

export const SkeletonSecondInformation = styled.div`
  display: flex;

  gap: 30px;
`

export const SkeletonButton = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  // teste
`
