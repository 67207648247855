import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Table, Typography, Modal } from 'antd'
import type { ColumnsType } from 'antd/lib/table'

import { TableActionIcon, TableContainer, Text } from './styles'

import { User } from '@/common'
import { TableAction } from '@/components'

type UserListTableProps = {
  data: User[]
  onDeleteUser: (username: string) => void
  isLoading: boolean
  handleEditUser: (user: User) => void
}

const UserListTable = ({ data, onDeleteUser, handleEditUser, isLoading }: UserListTableProps) => {
  const columns: ColumnsType<User> = [
    { title: 'Nome', dataIndex: 'name' },
    {
      title: 'Email',
      dataIndex: 'username',
    },
    {
      title: 'Grupo',
      dataIndex: 'groups',
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      align: 'center',
      width: 150,
      render: (enabled) => {
        return enabled ? <Typography>Ativo</Typography> : <Typography>Inativo</Typography>
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'userCreateDate',
      align: 'center',
      width: 200,
      render: (value) => new Date(value).toLocaleDateString('pt-BR'),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 150,
      render: (_, user) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <Button icon={<EditOutlined />} type="link" onClick={() => handleEditUser(user)}>
                Editar
              </Button>
            ),
          },
          {
            key: '2',
            label: (
              <Button
                danger
                icon={<DeleteOutlined />}
                type="link"
                onClick={() => confirmDeletion(user.username)}
              >
                Deletar
              </Button>
            ),
          },
        ]

        return <TableAction icon={<TableActionIcon />} dropdownOptions={dropdownOptions} />
      },
    },
  ]

  function confirmDeletion(username: string) {
    Modal.confirm({
      title: 'Você quer deletar este usuário?',
      centered: true,
      icon: <ExclamationCircleFilled />,
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        onDeleteUser(username)
      },
    })
  }

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        showHeader
        pagination={false}
        rowKey="username"
        rowClassName={(_, index) => (index % 2 === 0 ? 'light-row' : 'dark-row')}
        footer={() => ''}
      />
    </TableContainer>
  )
}

export { UserListTable }
