import toast from 'react-hot-toast'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'

import * as S from './styles'

type DismissibleToastProps = {
  text: string
}

const DismissibleToast = ({ text }: DismissibleToastProps) => {
  return toast.loading((t) => (
    <S.Container>
      <Typography>{text}</Typography>

      <Button type="link" onClick={() => toast.dismiss(t.id)} icon={<CloseOutlined />} />
    </S.Container>
  ))
}

export { DismissibleToast }
