import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import type { ProductDetails } from '../../types'
import { productKeys } from '../query-keys'

import { api } from '@/services'

async function getProduct(productId: string) {
  const { data } = await api.post<ProductDetails>(`/produto/${productId}`, {
    ordenacao: {
      coluna: 'a_vista',
      ordem: 'ASC',
    },
    page: 1,
  })

  return data
}

async function fetchProduct({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['detail']>>) {
  const [, , productId] = queryKey

  const { data } = await api.post<ProductDetails>(`/produto/${productId}`, {
    ordenacao: {
      coluna: 'a_vista',
      ordem: 'ASC',
    },
    page: 1,
  })

  return data
}

const useProduct = (productId: string) => {
  return useQuery(productKeys.detail(productId), fetchProduct)
}

export { useProduct, getProduct }
