import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { FiTrash } from 'react-icons/fi'

import { useQueryClient } from '@tanstack/react-query'
import { Space } from 'antd'

import * as S from './styles'

import { HandleTableCheckedItems, useToggle, useUserGroup } from '@/common'
import { CustomPagination, Loading } from '@/components'
import {
  NotificationType,
  WebsocketNotificationConfig,
  useUserNotifications,
  useDeleteNotificationMutation,
  HandleDeleteNotificationParams,
  UserNotificationsListTable,
  NotificationModal,
  formatNotificationData,
  NotificationForm,
  ViewNotificationModal,
  notificationKeys,
  fetchUserNotifications,
} from '@/features/notifications'
import { PageLayout } from '@/layouts'

const ITEMS_PER_PAGE = 30

const NotificationList = () => {
  const { isAdmin } = useUserGroup()
  const queryClient = useQueryClient()
  const [isEditNotificationModalOpen, toggleEditNotificationModal] = useToggle(false)
  const [isViewNotificationModalOpen, toggleViewNotificationModal] = useToggle(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { data: notifications, isLoading, isFetching } = useUserNotifications(currentPage)

  const deleteNotificationsMutation = useDeleteNotificationMutation()

  const [checkedNotifications, setCheckedNotifications] = useState<React.Key[]>([])

  useEffect(() => {
    const hasNextPage = notifications && currentPage < notifications?.info.pages

    if (hasNextPage) {
      queryClient.prefetchQuery(notificationKeys.userList(currentPage + 1), fetchUserNotifications)
    }
  }, [currentPage, notifications, queryClient])

  function handleCheckedNotifications({
    selectedRows,
    selectedRowKeys,
    info,
  }: HandleTableCheckedItems<NotificationType<WebsocketNotificationConfig>[]>) {
    setCheckedNotifications(selectedRowKeys)
  }

  function handleCheckAll() {
    if (checkedNotifications.length === ITEMS_PER_PAGE) {
      return handleCheckedNotifications({
        selectedRowKeys: [],
        selectedRows: [],
        info: { type: 'none' },
      })
    }

    const allNotifications = notifications?.results.map(
      (notification) => notification.notificacao.id,
    ) as React.Key[]

    return handleCheckedNotifications({
      selectedRowKeys: allNotifications,
      selectedRows: notifications?.results!,
      info: { type: 'all' },
    })
  }

  async function handleDeleteNotifications({
    notifications,
    multiple = false,
  }: HandleDeleteNotificationParams) {
    const promise = deleteNotificationsMutation.mutateAsync({ notifications, multiple })

    await toast.promise(promise, {
      loading: 'Deletando notificações...',
      success: 'Notificações deletadas com sucesso!',
      error: 'Erro ao deletar notificações!',
    })

    handleCheckedNotifications({
      selectedRowKeys: [],
      selectedRows: [],
      info: { type: 'none' },
    })
  }

  const [selectedNotification, setSelectedNotification] = useState<
    NotificationType<WebsocketNotificationConfig> | undefined
  >(undefined)
  const [editNotificationForm, setEditNotificationForm] = useState<NotificationForm | undefined>(
    undefined,
  )

  function handleSelectNotification(
    notification: NotificationType<WebsocketNotificationConfig>,
    action: 'edit' | 'view',
  ) {
    setSelectedNotification(notification) // get notification from table

    if (action === 'view') {
      toggleViewNotificationModal()
      return
    }

    if (action === 'edit') {
      toggleEditNotificationModal()

      const formValues = formatNotificationData(notification.notificacao)

      // set form values to edit
      setEditNotificationForm(formValues)
    }
  }

  function handleEditViewingNotification() {
    if (!selectedNotification) return

    const notificationConfig = {
      ...selectedNotification.notificacao,
      id: (selectedNotification.notificacao as WebsocketNotificationConfig).id_alerta,
    }

    const formValues = formatNotificationData(notificationConfig)

    setEditNotificationForm(formValues)

    toggleViewNotificationModal()
    toggleEditNotificationModal()
  }

  const PageHeader = () => (
    <S.HeaderContainer>
      <Space style={{ minHeight: '25px' }}>
        <S.Title>Listagem de notificações</S.Title>
        <S.Divider thickness={2} type="vertical" />
        <S.TotalNotificationsValue>{notifications?.info.total}</S.TotalNotificationsValue>
        <S.TotalNotifications>{notifications && `encontradas`}</S.TotalNotifications>
        {(isLoading || isFetching) && <Loading />}
      </Space>

      {notifications && notifications.results.length > 0 && (
        <S.HeaderActions>
          <Space style={{ minHeight: '25px' }}>
            <Space size="small">
              <S.HeaderText>Selecionados:</S.HeaderText>
              <S.TotalNotificationsValue>{checkedNotifications.length}</S.TotalNotificationsValue>
            </Space>

            <S.Divider color="#9D9D9D" type="vertical" />

            <S.CheckAllButton type="link" onClick={handleCheckAll} disabled={isLoading}>
              {checkedNotifications.length === ITEMS_PER_PAGE
                ? 'Deselecionar todos'
                : 'Selecionar todos'}
            </S.CheckAllButton>
          </Space>

          {checkedNotifications.length > 0 && (
            <S.DeleteAllButton
              icon={<FiTrash />}
              onClick={() =>
                handleDeleteNotifications({
                  notifications: checkedNotifications as string[],
                  multiple: true,
                })
              }
            >
              Deletar notificações
            </S.DeleteAllButton>
          )}
        </S.HeaderActions>
      )}
    </S.HeaderContainer>
  )

  return (
    <PageLayout windowTitle="Notificações">
      <PageHeader />

      {isAdmin && isEditNotificationModalOpen && (
        <NotificationModal
          isOpen={isEditNotificationModalOpen}
          closeModal={toggleEditNotificationModal}
          productId={selectedNotification?.notificacao.productId as string}
          initialValues={editNotificationForm}
          notificationId={selectedNotification?.notificacao.id_alerta}
        />
      )}

      {isViewNotificationModalOpen && (
        <ViewNotificationModal
          data={selectedNotification as NotificationType<WebsocketNotificationConfig>}
          isOpen={isViewNotificationModalOpen}
          onClose={toggleViewNotificationModal}
          onEdit={handleEditViewingNotification}
        />
      )}

      <S.NotificationsContainer>
        <UserNotificationsListTable
          data={notifications?.results as NotificationType<WebsocketNotificationConfig>[]}
          isLoading={isLoading}
          checkedNotifications={checkedNotifications}
          onDeleteNotifications={handleDeleteNotifications}
          handleCheckedNotifications={handleCheckedNotifications}
          handleSelectNotification={handleSelectNotification}
        />

        <CustomPagination
          scrollToTop
          pageSize={ITEMS_PER_PAGE}
          page={currentPage}
          total={notifications?.info.total}
          totalPages={notifications?.info.pages as number}
          isLoading={isLoading}
          updatePage={setCurrentPage}
        />
      </S.NotificationsContainer>
    </PageLayout>
  )
}

export { NotificationList }
