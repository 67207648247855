import { useMutation, useQueryClient } from '@tanstack/react-query'

import { categoryKeys, productKeys } from '@/features/product'
import { api } from '@/services'

type NewCategoryMutation = {
  categoria: string[]
  product: string[]
}

const useAddCategoryMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ categoria, product }: NewCategoryMutation) => {
      return api.post('/categories', { categoria, product })
    },
    {
      onMutate: async () => await queryClient.cancelQueries(categoryKeys.all),
      onSettled: async () => {
        await queryClient.invalidateQueries(productKeys.all)
        queryClient.invalidateQueries(categoryKeys.all)
      },
    },
  )
}

export { useAddCategoryMutation }
