import { FC } from 'react'

import * as S from './styles'

export type PaginationCustomProps = {
  page: number | undefined
  pageSize: number | undefined
  total: number | undefined
  totalPages: number
  isLoading: boolean
  updatePage: React.Dispatch<React.SetStateAction<number>>
  scrollToTop?: boolean
  size?: 'small' | 'default'
}

const CustomPagination: FC<PaginationCustomProps> = ({
  page,
  pageSize,
  total,
  totalPages,
  isLoading,
  updatePage,
  scrollToTop,
  size = 'default',
}) => {
  const onSearchForPageNumber = (page: number) => {
    if (page > 0) {
      const pageNumber = page > totalPages ? totalPages : page
      updatePage(pageNumber)

      if (scrollToTop) {
        window.scrollTo(0, 0)
      }
    }
  }

  return (
    <S.Pagination
      defaultCurrent={page}
      defaultPageSize={pageSize}
      current={page}
      total={total}
      size="default"
      locale={{
        items_per_page: '/ página',
        jump_to: 'Ir para',
        jump_to_confirm: 'Confirma ir para',
        page: 'Página',
        prev_page: 'Página anterior',
        next_page: 'Página seguinte',
      }}
      showTotal={(total: any, range: any) => {
        // console.log('total: ', total, 'range: ', range)
        if (isLoading) return <></>

        return (
          <S.PaginationRange>{`Exibindo ${range[0]} - ${range[1]} do total de ${total} registros`}</S.PaginationRange>
        )
      }}
      onChange={(page, pageSize) => onSearchForPageNumber(page)}
      disabled={isLoading}
      showSizeChanger={false}
      showQuickJumper
      $size={size}
    />
  )
}

export { CustomPagination }
