import { AiOutlineSwapRight } from 'react-icons/ai'

import { Divider as AntdDivider, Typography } from 'antd'
import { Space as AntdSpace } from 'antd'
import styled from 'styled-components'

export const Divider = styled(AntdDivider)`
  color: #414243;
  border-left: 1.5px solid #414243;
  border-color: #414243;
  height: 15px;
  margin: 0 4px;
`
export const Space = styled(AntdSpace)`
  gap: 4px !important;

  .ant-typography {
    margin-bottom: 0;
    font-weight: 500;
    line-height: inherit;
  }
`

export const FoundProducts = styled(Typography.Text)`
  strong {
    font-weight: 700;
    color: #4a91fd;
    font-size: 15px;
  }
`

export const Description = styled(Typography.Text)`
  line-height: inherit;
`

export const GreyText = styled(Typography.Text)`
  color: #999999;
`

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 2px;

  .ant-typography {
    color: #666666;
  }
`

export const ArrowRight = styled(AiOutlineSwapRight)`
  margin: 0px 26px;
`

export const ProductName = styled(Typography.Text)`
  color: #5ea320;
`

export const VariationContainer = styled.div`
  font-weight: 600;
`

export const TableContainer = styled.div`
  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    padding: 16px 9px;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    padding: 10px;
    border: none;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.6);
  }

  .ant-table-wrapper .ant-table.ant-table-middle .ant-table-footer {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background-color: rgba(240, 242, 245, 0.5);
    border: none;
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: rgba(240, 242, 245, 0.5);
  }
`
