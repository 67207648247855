import jwtDecode from 'jwt-decode'

import { authStore as useAuthStore } from '@/app'

const useUserGroup = (): { userGroup: string | null; isAdmin: boolean } => {
  const accessToken = useAuthStore((s) => s.accessToken)

  if (!accessToken) {
    return { userGroup: null, isAdmin: false }
  }

  const decodedToken = jwtDecode<{ 'cognito:groups': string[] }>(accessToken)

  const userGroup = decodedToken['cognito:groups']?.[0]

  const isAdmin = userGroup === 'admin'

  return { userGroup, isAdmin }
}

export { useUserGroup }
