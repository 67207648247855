import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { PriceHistoryQuery, ProductHistory } from '../../types'
import { productKeys } from '../query-keys'

import { api } from '@/services'

async function fetchHistory({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['history']>>) {
  const [, , productId, data_inicio, data_fim] = queryKey

  const params = new URLSearchParams()
  if (data_inicio) params.append('data_inicio', data_inicio)
  if (data_fim) params.append('data_fim', data_fim)

  const { data } = await api.get<ProductHistory[]>(
    `/produto/${productId}/historico_preco?${params.toString()}`,
  )

  return data
}

const usePriceHistory = ({ productId, params }: PriceHistoryQuery) => {
  return useQuery(productKeys.history({ productId, params }), fetchHistory)
}

export { usePriceHistory }
