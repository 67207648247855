import { useQuery } from '@tanstack/react-query'

import { Category } from '../../types'
import { categoryKeys } from '../query-keys'

import { ApiResponse } from '@/common'
import { api } from '@/services'

async function fetchCategories() {
  const { data } = await api.get<ApiResponse<Category[]>>(`/categories`)

  return data.result
}

const useCategories = () => {
  return useQuery(categoryKeys.list(), fetchCategories, {
    keepPreviousData: true,
    retry: 2,
  })
}

export { useCategories }
