import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Card, Select, SelectProps, Typography } from 'antd'
import { Divider as AntdDivider } from 'antd'
import styled from 'styled-components'

type CategorySelectProps = SelectProps

export const BackButton = styled(Button)`
  color: #80c343;
  border-radius: 2px;
  border: 1px solid #80c343;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const SubmitButton = styled(Button)`
  border-radius: 2px;
  background: #83b81a;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  .ant-input-affix-wrapper {
    border-radius: 0px;
    font-size: 15px;
    padding: 4px 6px;
    color: #3d3c3c;
  }

  .ant-select-show-arrow .ant-select-selector {
    border-radius: 2px;
    height: 40px;
  }

  .ant-select-selection-overflow {
    height: 20px;
    gap: 6px;
  }

  .ant-select-arrow {
    visibility: hidden;
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    height: 24px;
    width: 82px;

    background: #fff;
    border: 1px solid #80c343;
    border-radius: 12px;
  }

  .ant-select-selection-item-remove svg {
    color: #80c343;
  }

  .ant-select-selection-item-content {
    color: #434343;
    font-size: 14px;
  }
`

export const ProductArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 18px;
  padding: 18px 24px 24px;

  border-radius: 5px;

  .ant-typography {
    margin-bottom: 0;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  width: 466px;
`

export const SelectedProductsArea = styled.div`
  display: flex;
  flex-direction: column;

  padding: 17px 24px 16px 23px;
  gap: 4px;

  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
`

export const TitleProduct = styled(Typography.Text)`
  color: #666666;
  font-weight: 300;
  font-size: 20px;
  line-height: normal;
`

export const ValueProduct = styled(Typography.Text)`
  display: flex;
  justify-content: flex-start;
  gap: 20px;

  font-size: 14px;

  .ant-typography {
    color: #999;
  }

  .ant-typography strong {
    color: #666;
  }
`

export const CategoryArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const AreaTitle = styled(Typography.Text)<{ fontSize?: number }>`
  display: flex;
  align-items: center;

  font-size: ${({ fontSize }) => (fontSize ? fontSize : 20)}px;
  color: #434343;
  font-weight: 700;

  gap: 8px;
`

export const ProductCard = styled(Card)`
  width: fit-content;
  margin: 24px 24px 0px;

  .ant-card-body {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
`

export const SelectedProducts = styled(Typography.Text)`
  color: #ff8b27;
  font-size: 24px;
  font-weight: 700;
`

export const QuestionMark = styled(QuestionCircleOutlined)`
  color: #80c343;
  font-size: 15px;
`

export const InputArea = styled.div`
  display: flex;

  .ant-select-selector {
    border-radius: 2px 0 0 2px !important;
    width: 240px;
  }

  .ant-select-selection-item {
    display: none;
  }
`

export const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  height: auto;
  max-height: 40px;

  font-size: 16px;
  padding: 0px 16px;
  border-radius: 0px 2px 2px 0px;
  background: #80c343;
  border: 1px solid transparent;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const Divider = styled(AntdDivider)`
  margin: 0;
  margin: 24px 0;
`

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 24px 54px;
  gap: 20px;
`

export const SelectArea = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`

export const CategoriesList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 8px;
`

export const CategoriesText = styled(Typography.Text)`
  display: flex;

  font-size: 14px;
  color: #6c6c6c;
  font-weight: 300;

  gap: 2px;
`

export const CategorySelect = styled(Select)<CategorySelectProps>`
  width: 240px;

  .ant-select-selector {
    border-radius: 2px 0 0 2px !important;
  }

  .ant-select-selection-item {
    display: none;
  }
`

export const SkeletonContent = styled.div`
  padding: 24px;
`

export const SkeletonFirstContainer = styled.div`
  display: flex;

  padding-bottom: 40px;
  gap: 30px;
`

export const SkeletonImageArea = styled.div`
  padding-left: 24px;
`

export const SkeletonTextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;
`

export const SkeletonSecondContainer = styled.div`
  display: flex;
`

export const SkeletonAddCategoryArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;
`
