import { createGlobalStyle } from 'styled-components'

import AcerFocoBlack from '../../assets/fonts/AcerFoco-Black.ttf'
import AcerFocoBold from '../../assets/fonts/AcerFoco-Bold.ttf'
import AcerFocoLigth from '../../assets/fonts/AcerFoco-Light.ttf'
import AcerFocoRegular from '../../assets/fonts/AcerFoco-Regular.ttf'
import AcerFocoSemiBold from '../../assets/fonts/AcerFoco-Semibold.ttf'

import { hexToRgba } from '@/common'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoLigth}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'AcerFoco', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ul, li {
    list-style: none;
  }

  button {
    cursor: pointer;
    border: none;
  }

  .ant-dropdown-menu-vertical{
      overflow-y: auto;
      height: 100%;
      max-height: 70vh;
  }

  .ant-table-thead>tr>th {
    color: #414243;
    font-weight: 400 !important;
  }

  //centralização da seta no submenu de compartilhamento de produto
  .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  .modal-confirm-button {
    background-color: ${({ theme }) => theme.colors.brand};

    :hover {
      background-color: ${({ theme }) => hexToRgba(theme.colors.brand, 0.8)} !important;
    }
  }

  .modal-cancel-button {
    :hover {
      border-color: ${({ theme }) => theme.colors.brand} !important;
      color: ${({ theme }) => theme.colors.brand} !important;
    }
  }

  /* .ant-spin {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colors.brand};
  }

  .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-dropdown-menu-submenu-expand-icon {
    top: 8px;
  }

  .ant-input {
    :hover,
    :focus {
      border-color: ${({ theme }) => theme.colors.brand};
      box-shadow: 0 0 0 2px rgba(128,195,67,.2);
    }
  }

  .ant-btn {
    transition: all 0.2s ease;
  }

  .ant-btn-default, .ant-btn-dashed {
    :hover, :focus {
      border-color: ${({ theme }) => theme.colors.brand};
      color: ${({ theme }) => theme.colors.brand};
    }
  }

  .ant-btn-dangerous {
    :hover, :focus {
      border-color:  #ff4d4f;
      color:  #ff4d4f;
    }
  }
  .ant-table-thead>tr>th {
    color: #414243;
    font-weight: 400;
  }

  .ant-table-tbody>tr>td {
    color: #414243;
  } */

  .ant-modal .ant-modal-close {
    color: ${({ theme }) => theme.colors.brand};
    transition: all 0.2s ease;

    :hover {
      color: ${({ theme }) => theme.colors.brand};
      filter: brightness(1.2);
    }
  }
`
