import { AiFillPlusCircle } from 'react-icons/ai'

import { Card, Typography, Input as AntdInput, Button, Radio, DatePicker, InputNumber } from 'antd'
import styled, { css } from 'styled-components'

// Modal styles

export const Container = styled.div`
  width: 724px;
`

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ReturnButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brand};
`

// Steps styles

export const Title = styled(Typography.Title)`
  &.ant-typography {
    color: #404040;
    margin: 0;
  }
`
export const ProductCard = styled(Card)`
  img {
    height: 100%;
    width: 100%;
    max-height: 120px;
    max-width: 154px;
  }

  .ant-card-body {
    padding: 14px 24px;
    display: flex;
  }
`

export const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  gap: 8px;

  strong {
    color: #404040;
  }

  span:first-child {
    font-size: 12px;
  }

  span:last-child {
    align-self: flex-end;
    margin-top: auto;

    .price {
      font-size: 18px;
    }
  }
`

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 28px;
  align-items: flex-start;

  .ant-form-item:first-child {
    flex: 1;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .anticon {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const Input = styled(AntdInput)<{ $hasError?: boolean }>`
  .ant-input,
  &.ant-input {
    height: 40px;
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      .ant-input,
      &.ant-input {
        border: 1px solid #ff4d4f;

        :hover {
          border-color: #ffa39e;
        }
      }
    `}
`

export const AddonInput = styled(InputNumber)`
  .ant-input,
  .ant-input-number,
  &.ant-input-number,
  .ant-input-number-input,
  &.ant-input-number-input,
  &.ant-input {
    height: 38px;
  }

  .ant-input-number-group-addon {
    padding: 0;
    border: none;
  }

  .ant-radio-button-wrapper {
    line-height: 38px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0;
  }

  .ant-input-number-handler-wrap,
  &.ant-input-number-handler-wrap {
    display: none;
  }
`

export const SwitchInputContainer = styled.div`
  width: 100%;
  max-width: 358px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .ant-radio-button-wrapper:last-child {
    border-radius: 0;
  }

  .ant-input {
    border-radius: 0 5px 5px 0;
    flex: 1;
  }

  .ant-form-item {
    .ant-row {
      width: 100%;
    }

    .ant-radio-group {
      width: fit-content;
    }

    .ant-form-item-control-input-content {
      display: flex;
    }
  }
`

export const RadioSwitch = styled(Radio.Group)`
  height: 40px;
  padding: 0;
  border: none;
  display: flex;
  width: 100%;

  .ant-radio-button-wrapper {
    line-height: 38px;
  }

  .ant-radio-button-wrapper {
    height: 40px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0;
  }
`

export const DatePickerArea = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 354px;

  .anticon-swap-right {
    font-size: 18px;
    margin: auto 8px;
    padding-top: 4px;
  }
`

export const DateInput = styled(DatePicker)<{ $hasError: boolean }>`
  height: 40px;
  width: 160px;

  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid #ff4d4f;

      :hover {
        border-color: #ffa39e;
      }
    `}
`

export const AlertRulesCard = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  border: 1px solid #ffe58f;
  border-radius: 2px;
  background-color: #fffbe6;

  padding: 8px 16px;

  width: 100%;
  max-width: 358px;
  margin-left: auto;

  margin-top: -16px;

  svg {
    color: #faad14;
  }
`

export const SelectIcon = styled(AiFillPlusCircle)`
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  pointer-events: none;
`
