import { useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CalendarOutlined } from '@ant-design/icons'
import { Col, DatePicker, Row, Select, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import {
  DashboardCard,
  SmallCardsContainer,
  ByMarketplaceGraphCard,
  ByBrandsGraphCard,
  CardLoading,
  ByBrandsGraphLoading,
  ByMarketplaceGraphLoading,
  RankingTableCard,
  RankingCardLoading,
  SkeletonContainer,
  TypographyCard,
  Tabs,
  RakingCardHeader,
  FilterContainer,
} from './styles'

import { clearFilters, resetFilters, filterStore as useFilterStore } from '@/app'
import { formatAmounts } from '@/common'
import {
  useDashboardCards,
  useOffersByBrandsGraph,
  useOffersByMarketplaceGraph,
  BrandsGraph,
  MarketplaceGraph,
  useHighlights,
  RankingMercadoLivre,
  RankingAmazon,
  ActiveGraphType,
  HighlightedMarketplaces,
} from '@/features/dashboard'
import { PageLayout } from '@/layouts'

type GraphFilter = 'week' | 'fortnight' | 'month' | 'custom'

type MarketplaceFilterParams = {
  data_min: string
  data_max: string
}

type State = {
  customFilterLabel: string
  datePickerValue: Dayjs | null
  isDatePickerOpen: boolean
  filterValue: GraphFilter
  graphParams: MarketplaceFilterParams
}

type MarketplaceState = {
  mercado_livre: State
  amazon: State
}

type MarketplaceActions = { marketplace: HighlightedMarketplaces } & (
  | { type: 'SET_FILTER_LABEL'; payload: string }
  | { type: 'SET_DATE_PICKER_VALUE'; payload: Dayjs | null }
  | { type: 'SET_DATE_PICKER_OPEN_STATE'; payload: boolean }
  | { type: 'SET_FILTER_VALUE'; payload: GraphFilter }
  | { type: 'SET_GRAPH_PARAMS'; payload: MarketplaceFilterParams }
)

const INITIAL_DATE_LABEL = 'Semana Personalizada'

const INITIAL_MARKETPLACE_STATE: State = {
  customFilterLabel: INITIAL_DATE_LABEL,
  datePickerValue: null,
  isDatePickerOpen: false,
  filterValue: 'week',
  graphParams: {
    data_min: dayjs().subtract(7, 'day').format('DD-MM-YYYY'),
    data_max: dayjs().format('DD-MM-YYYY'),
  },
}

const Dashboard = () => {
  const navigate = useNavigate()
  const setActiveSorting = useFilterStore((s) => s.setActiveSorting)

  const { data: productCards, isLoading: isProductCardsLoading } = useDashboardCards()

  const offersByMarketplaceQuery = useOffersByMarketplaceGraph()

  const offersByBrandQuery = useOffersByBrandsGraph()

  const [offersByMarketplaceSelect, setOffersByMarketplaceSelect] =
    useState<ActiveGraphType>('ofertas_sem_seller')

  const [offersByBrandSelect, setOffersByBrandSelect] =
    useState<ActiveGraphType>('ofertas_nao_oficiais')

  const handleAction = (
    state: MarketplaceState,
    action: MarketplaceActions,
    property: keyof State,
  ): MarketplaceState => {
    const { marketplace, payload } = action

    return {
      ...state,
      [marketplace]: { ...state[marketplace], [property]: payload },
    }
  }

  const [marketplaceState, marketplaceDispatch] = useReducer(
    (state: MarketplaceState, action: MarketplaceActions) => {
      switch (action.type) {
        case 'SET_FILTER_LABEL':
          return handleAction(state, action, 'customFilterLabel')

        case 'SET_DATE_PICKER_VALUE':
          return handleAction(state, action, 'datePickerValue')

        case 'SET_DATE_PICKER_OPEN_STATE':
          return handleAction(state, action, 'isDatePickerOpen')

        case 'SET_FILTER_VALUE':
          return handleAction(state, action, 'filterValue')

        case 'SET_GRAPH_PARAMS':
          return handleAction(state, action, 'graphParams')

        default:
          return state
      }
    },
    {
      mercado_livre: INITIAL_MARKETPLACE_STATE,
      amazon: INITIAL_MARKETPLACE_STATE,
    },
  )

  const {
    isDatePickerOpen: mlDatePickerState,
    datePickerValue: mlDatePickerValue,
    customFilterLabel: mlFilterLabel,
    filterValue: mlFilterValue,
    graphParams: mlGraphParams,
  } = marketplaceState.mercado_livre

  const {
    isDatePickerOpen: amazonDatePickerState,
    datePickerValue: amazonDatePickerValue,
    customFilterLabel: amazonFilterLabel,
    filterValue: amazonFilterValue,
    graphParams: amazonGraphParams,
  } = marketplaceState.amazon

  const mercadoLivreHighlightsQuery = useHighlights('mercado_livre', mlGraphParams)
  const amazonHighlightsQuery = useHighlights('amazon', amazonGraphParams)

  function navigateToAllProducts() {
    clearFilters()
    navigate('/produtos')
  }

  function navigateToAvailableProducts() {
    resetFilters()
    navigate('/produtos')
  }

  function navigateToExclusiveProducts() {
    setActiveSorting({
      coluna: 'ofertas',
      ordem: 'ASC',
    })
    navigate('/produtos')
  }

  function handleTabClick(tabKey: string, graph: 'marketplace' | 'brand') {
    if (graph === 'marketplace') {
      if (tabKey === '1') {
        setOffersByMarketplaceSelect('ofertas_sem_seller')
      } else {
        setOffersByMarketplaceSelect('ofertas_com_seller')
      }
    }

    if (graph === 'brand') {
      if (tabKey === '1') {
        setOffersByBrandSelect('ofertas_nao_oficiais')
      } else if (tabKey === '2') {
        setOffersByBrandSelect('ofertas_sem_seller')
      } else {
        setOffersByBrandSelect('ofertas_com_seller')
      }
    }
  }

  function handleFilterHistory(value: GraphFilter, marketplace: HighlightedMarketplaces) {
    marketplaceDispatch({
      type: 'SET_FILTER_VALUE',
      payload: value || 'month',
      marketplace,
    })

    if (value === 'custom') {
      marketplaceDispatch({
        type: 'SET_DATE_PICKER_OPEN_STATE',
        payload: true,
        marketplace,
      })
      return
    }

    if (value === 'week') {
      marketplaceDispatch({
        type: 'SET_GRAPH_PARAMS',
        payload: {
          data_min: dayjs().subtract(7, 'day').format('DD-MM-YYYY'),
          data_max: dayjs().format('DD-MM-YYYY'),
        },
        marketplace,
      })
    }

    if (value === 'fortnight') {
      marketplaceDispatch({
        type: 'SET_GRAPH_PARAMS',
        payload: {
          data_min: dayjs().subtract(15, 'day').format('DD-MM-YYYY'),
          data_max: dayjs().format('DD-MM-YYYY'),
        },
        marketplace,
      })
    }

    if (value === 'month' || value === undefined) {
      marketplaceDispatch({
        type: 'SET_GRAPH_PARAMS',
        payload: {
          data_min: dayjs().subtract(30, 'day').format('DD-MM-YYYY'),
          data_max: dayjs().format('DD-MM-YYYY'),
        },
        marketplace,
      })
    }

    // reset custom date label & value
    marketplaceDispatch({
      type: 'SET_FILTER_LABEL',
      payload: INITIAL_DATE_LABEL,
      marketplace,
    })

    marketplaceDispatch({
      type: 'SET_DATE_PICKER_VALUE',
      payload: null,
      marketplace,
    })

    marketplaceDispatch({
      type: 'SET_DATE_PICKER_OPEN_STATE',
      payload: false,
      marketplace,
    })
  }

  function handleSelectDate(date: Dayjs | null, marketplace: HighlightedMarketplaces) {
    if (date) {
      const startOfWeek = dayjs(date).startOf('week').format('DD-MM-YYYY')
      const endOfWeek = dayjs(date).endOf('week').format('DD-MM-YYYY')
      const filterLabel = `Semana do dia ${date.format('DD/MM')}`

      marketplaceDispatch({
        type: 'SET_DATE_PICKER_VALUE',
        payload: date,
        marketplace,
      })

      marketplaceDispatch({
        type: 'SET_FILTER_LABEL',
        payload: filterLabel,
        marketplace,
      })

      marketplaceDispatch({
        type: 'SET_GRAPH_PARAMS',
        payload: {
          data_min: startOfWeek,
          data_max: endOfWeek,
        },
        marketplace,
      })

      marketplaceDispatch({
        type: 'SET_DATE_PICKER_OPEN_STATE',
        payload: false,
        marketplace,
      })
    }
  }

  function disableFilterDates(date: Dayjs) {
    // disable future weeks and prior 90 days
    return date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(90, 'days'))
  }

  function handleDatePickerOpenState(isOpen: boolean, marketplace: HighlightedMarketplaces) {
    const marketplaceDatePickerValue =
      marketplace === 'mercado_livre' ? mlDatePickerValue : amazonDatePickerValue

    if (!isOpen && !marketplaceDatePickerValue) {
      marketplaceDispatch({
        type: 'SET_FILTER_VALUE',
        payload: 'month',
        marketplace,
      })
    }

    marketplaceDispatch({
      type: 'SET_DATE_PICKER_OPEN_STATE',
      payload: isOpen,
      marketplace,
    })
  }

  const SkeletonPage = () => (
    <>
      <Row gutter={16}>
        <Col span={4}>
          <SmallCardsContainer>
            <CardLoading active shape={'square'} />
            <CardLoading active shape={'square'} />
            <CardLoading active shape={'square'} />
          </SmallCardsContainer>
        </Col>
        <Col span={20}>
          <SkeletonContainer>
            <ByMarketplaceGraphLoading active shape={'square'} />
          </SkeletonContainer>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <SkeletonContainer>
            <ByBrandsGraphLoading active shape={'square'} />
          </SkeletonContainer>
        </Col>

        <Col span={8}>
          <SkeletonContainer>
            <RankingCardLoading active shape={'square'} />
          </SkeletonContainer>
        </Col>

        <Col span={8}>
          <SkeletonContainer>
            <RankingCardLoading active shape={'square'} />
          </SkeletonContainer>
        </Col>
      </Row>
    </>
  )

  const isLoading =
    isProductCardsLoading ||
    offersByMarketplaceQuery.isInitialLoading ||
    offersByBrandQuery.isInitialLoading
  // (mercadoLivreHighlightsQuery.isLoading && !mercadoLivreHighlightsQuery.isFetched) ||
  // (amazonHighlightsQuery.isLoading && !amazonHighlightsQuery.isFetched)

  return (
    <PageLayout windowTitle="Dashboard">
      {isLoading ? (
        <SkeletonPage />
      ) : (
        <>
          <Row gutter={16}>
            <Col span={4}>
              <SmallCardsContainer>
                <DashboardCard>
                  <Typography.Title level={2} onClick={navigateToAllProducts}>
                    {formatAmounts(productCards?.total)}
                  </Typography.Title>
                  <TypographyCard>Produtos</TypographyCard>
                </DashboardCard>

                <DashboardCard>
                  <Typography.Title level={2} onClick={navigateToAvailableProducts}>
                    {formatAmounts(productCards?.disponiveis)}
                  </Typography.Title>
                  <TypographyCard>Produtos Disponíveis</TypographyCard>
                </DashboardCard>

                <DashboardCard>
                  <Typography.Title level={2} onClick={navigateToExclusiveProducts}>
                    {formatAmounts(productCards?.exclusivos)}
                  </Typography.Title>
                  <TypographyCard>Produtos Exclusivos</TypographyCard>
                </DashboardCard>
              </SmallCardsContainer>
            </Col>

            <Col span={20}>
              <ByMarketplaceGraphCard>
                <header>
                  <Typography.Title level={3}>Ofertas por Lojas</Typography.Title>

                  <Tabs
                    defaultActiveKey="1"
                    onTabClick={(tabKey: string) => handleTabClick(tabKey, 'marketplace')}
                    items={[
                      { key: '1', label: 'Sem Seller' },
                      { key: '2', label: 'Com Seller' },
                    ]}
                  />
                </header>
                <MarketplaceGraph
                  query={offersByMarketplaceQuery}
                  activeData={offersByMarketplaceSelect}
                />
              </ByMarketplaceGraphCard>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <ByBrandsGraphCard>
                <header>
                  <Typography.Title level={3}>Ofertas por Marcas</Typography.Title>

                  <Tabs
                    defaultActiveKey="1"
                    onTabClick={(tabKey: string) => handleTabClick(tabKey, 'brand')}
                    items={[
                      { key: '1', label: 'Sem Loja da Marca' },
                      { key: '2', label: 'Sem Seller' },
                      { key: '3', label: 'Com Seller' },
                    ]}
                  />
                </header>

                <BrandsGraph query={offersByBrandQuery} activeData={offersByBrandSelect} />
              </ByBrandsGraphCard>
            </Col>

            <Col span={8}>
              <RankingTableCard>
                <RakingCardHeader>
                  <Typography.Title level={5}>Ranking Mercado Livre</Typography.Title>

                  <FilterContainer>
                    <Select
                      style={{ width: 176 }}
                      value={mlFilterValue}
                      options={[
                        { value: 'week', label: 'Últimos 7 Dias' },
                        { value: 'fortnight', label: 'Últimos 15 Dias' },
                        { value: 'month', label: 'Últimos 30 Dias' },
                        { value: 'custom', label: mlFilterLabel },
                      ]}
                      allowClear={mlFilterValue === 'custom'}
                      onClear={() => handleFilterHistory('month', 'mercado_livre')}
                      onSelect={(value: GraphFilter) => handleFilterHistory(value, 'mercado_livre')}
                      suffixIcon={<CalendarOutlined />}
                    />

                    <DatePicker
                      disabledDate={disableFilterDates}
                      value={mlDatePickerValue}
                      picker="week"
                      onChange={(selectedDate) => handleSelectDate(selectedDate, 'mercado_livre')}
                      open={mlDatePickerState}
                      onOpenChange={(isOpen) => handleDatePickerOpenState(isOpen, 'mercado_livre')}
                    />
                  </FilterContainer>
                </RakingCardHeader>

                <RankingMercadoLivre query={mercadoLivreHighlightsQuery} />
              </RankingTableCard>
            </Col>

            <Col span={8}>
              <RankingTableCard>
                <RakingCardHeader>
                  <Typography.Title level={5}>Ranking Amazon</Typography.Title>

                  <FilterContainer>
                    <Select
                      style={{ width: 176 }}
                      value={amazonFilterValue}
                      options={[
                        { value: 'week', label: 'Últimos 7 Dias' },
                        { value: 'fortnight', label: 'Últimos 15 Dias' },
                        { value: 'month', label: 'Últimos 30 Dias' },
                        { value: 'custom', label: amazonFilterLabel },
                      ]}
                      allowClear={amazonFilterValue === 'custom'}
                      onClear={() => handleFilterHistory('month', 'amazon')}
                      onSelect={(value: GraphFilter) => handleFilterHistory(value, 'amazon')}
                      suffixIcon={<CalendarOutlined />}
                    />

                    <DatePicker
                      disabledDate={disableFilterDates}
                      value={amazonDatePickerValue}
                      picker="week"
                      onChange={(selectedDate) => handleSelectDate(selectedDate, 'amazon')}
                      open={amazonDatePickerState}
                      onOpenChange={(isOpen) => handleDatePickerOpenState(isOpen, 'amazon')}
                    />
                  </FilterContainer>
                </RakingCardHeader>

                <RankingAmazon query={amazonHighlightsQuery} />
              </RankingTableCard>
            </Col>
          </Row>
        </>
      )}
    </PageLayout>
  )
}

export { Dashboard }
