import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MailOutlined } from '@ant-design/icons'
import { Form, Input, Button } from 'antd'

import { ConfirmForgotPasswordCredentials } from '@/features/auth'

type ConfirmForgotPasswordFormProps = {
  handleConfirmForgotPassword: (values: ConfirmForgotPasswordCredentials) => void
  isLoading: boolean
  userEmail: string | undefined
}

const ConfirmForgotPasswordForm = ({
  handleConfirmForgotPassword,
  isLoading,
  userEmail,
}: ConfirmForgotPasswordFormProps) => {
  return (
    <Form onFinish={handleConfirmForgotPassword} layout="vertical">
      <Form.Item
        name="email"
        initialValue={userEmail}
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Por favor, insira um email válido!',
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Email" disabled={!!userEmail} />
      </Form.Item>

      <Form.Item
        name="confirmationCode"
        label="Código de Confirmação"
        rules={[
          {
            required: true,
            message: 'Por favor, insira o código de confirmação enviado para seu email!',
          },
        ]}
      >
        <Input prefix={'#'} placeholder="Código de confirmação" autoComplete="off" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Nova Senha"
        rules={[{ required: true, message: 'Por favor, insira uma senha', min: 8 }]}
        hasFeedback
      >
        <Input.Password
          placeholder="Nova Senha"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          prefix={<LockOutlined />}
          autoComplete="off"
        />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={isLoading}>
        Trocar Senha
      </Button>
    </Form>
  )
}

export { ConfirmForgotPasswordForm }
