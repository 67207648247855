import { MinusCircleOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import styled from 'styled-components'

export const CancelButton = styled(Button)`
  color: #80c343;
  border-radius: 2px;
  border: 1px solid #80c343;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const SendButton = styled(Button)`
  border-radius: 2px;
  background: #83b81a;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const Content = styled.div`
  padding: 12px 24px;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #a0a0a0;
`

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
  }

  .ant-form-item-margin-offset {
    display: none;
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Item = styled(Form.Item)`
  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: 0;

  div {
    display: flex;
    align-items: center;
    width: 100%;
  }
`

export const AddItemButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
`

export const RemoveIcon = styled(MinusCircleOutlined)`
  color: #ff5b5b;
`
