import { Form, Select } from 'antd'

import { UserTag } from '../../../UserTag'
import * as S from '../styles'

import { InputLabel } from '@/components'
import { getStringInitials } from '@/features/product'
import { useUsers } from '@/features/users'

const SecondStep = () => {
  const { data: users, isLoading } = useUsers()

  const selectUsers = users?.map((user) => ({
    value: user.username,
    label: user.name,
  }))

  return (
    <S.Content>
      <S.Title level={5}>Quem vai receber notificação pelo sistema?</S.Title>

      <Form.Item
        label={
          <InputLabel
            label="Alerta por notificação do sistema"
            tooltip="Usuários que receberão notificação dentro do sistema"
          />
        }
        name="notificacao_usuarios"
        required={false}
        rules={[
          {
            required: true,
            message: 'Por favor, selecione pelo menos um usuário para receber a notificação.',
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder="Usuários"
          size="large"
          tagRender={(props) => (
            <UserTag userInitial={getStringInitials(props.value, 1)} {...props} />
          )}
          suffixIcon={!isLoading ? <S.SelectIcon size={20} /> : undefined}
          // onChange={handleChange}
          options={selectUsers}
          loading={isLoading}
        />
      </Form.Item>
    </S.Content>
  )
}

export { SecondStep }
