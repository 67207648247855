import { HiFire } from 'react-icons/hi'

import { StopOutlined } from '@ant-design/icons'
import styled, { css } from 'styled-components'

type TableTextProps = {
  isDisabled?: boolean
  isBold?: boolean
}

export const TableText = styled.span<TableTextProps>`
  color: #000000d9;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  cursor: default;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #999999;
    `}
`

export const TooltipText = styled.span`
  font-size: 14px;
  color: #000000d9;
`

export const StoreLink = styled.a<TableTextProps>`
  color: ${({ theme }) => theme.colors.brand};
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #598b2c;

      :hover {
        color: #598b2c;
        filter: brightness(1);
      }
    `}
`

export const TableContainer = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    border: 1px solid var(--conditional-divider, rgba(0, 0, 0, 0.06));
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    background: #f4f4f4;
  }

  .ant-table-wrapper .ant-table-footer {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: #efefef;
  }

  .disabled-row {
    background-color: #e2e2e2;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child,
  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-radius: 0;
  }
`

export const MarketplaceContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const FireIcon = styled(HiFire)`
  font-size: 20px;
  color: #4a91fd;
`

export const ConditionsContainer = styled.div`
  display: flex;
  gap: 27px;
  justify-content: center;
  font-size: 20px;
  color: #4a91fd;
`
