import { useEffect, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { CheckboxOptionType, Form, Input } from 'antd'

import { useCollapsePanels, useTextSearchFilter } from '../../hooks'
import { useFilters } from '../../queries'
import { CheckboxFilter, RadioFilter } from '../Filters'
import { FilterSidebar } from '../FilterSidebar'
import { Collapse, CollapsePanel, Divider, CloseIcon, OpenIcon } from './styles'

import { filterStore as useFilterStore } from '@/app'

type PanelsKeys = Record<string, number>

const panelKeys: PanelsKeys = {
  marca: 1,
  marketplace: 2,
  disponivel: 3,
  text_search: 4,
  ram: 5,
  hd: 5,
  processador: 6,
  processador_serie: 6,
  sistema_operacional: 6,
  placa_video: 7,
  tela: 7,
  seller: 8,
  tags: 9,
  categorias: 10,
  cashback: 11,
  frete_gratis: 11,
  patrocinado: 11,
  cupom: 11,
  novos: 12,
  review: 13,
}

const ProductFilters = () => {
  const [activePanels, setActivePanels] = useCollapsePanels()
  const activeFilters = useFilterStore((s) => s.activeFilters)
  const [openPanels, setOpenPanels] = useState<string[]>(['1'])
  const { data: filters, isLoading, isError } = useFilters()
  const [textSearchInput, setTextSearchInput] = useTextSearchFilter()

  function handlePanels(activePanels: string | string[]) {
    setOpenPanels(activePanels as string[])
  }

  useEffect(() => {
    const hasActiveFilters = Object.keys(activeFilters).length > 0

    if (hasActiveFilters) {
      const filterKeys = Object.keys(activeFilters)

      const activePanelKeys = filterKeys.map((key) => String(panelKeys[key]))

      setActivePanels(activePanelKeys)
      setOpenPanels(activePanelKeys)
    } else {
      setActivePanels([])
    }
  }, [activeFilters, setActivePanels])

  const availabilityFilter: CheckboxOptionType[] = [
    {
      label: 'Todos',
      value: '',
    },
    {
      label: 'Disponíveis',
      value: true,
    },
    {
      label: 'Indisponíveis',
      value: false,
    },
  ]

  const specialConditionsFilter: CheckboxOptionType[] = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
  ]

  return (
    <FilterSidebar isLoading={isLoading} isError={isError}>
      <Collapse
        bordered={false}
        defaultActiveKey={'1'}
        onChange={(panels) => handlePanels(panels)}
        activeKey={openPanels}
        accordion
        expandIconPosition="end"
        expandIcon={({ isActive }) => (isActive ? <CloseIcon /> : <OpenIcon />)}
      >
        <CollapsePanel key={'1'} header="Marcas" $isOpen={activePanels?.includes('1')}>
          <Form.Item name="marca">
            <CheckboxFilter
              name="marca"
              originalData={filters?.marca as string[]}
              tooltipDescription="Aqui voce pode filtrar por marcas."
            />
          </Form.Item>
        </CollapsePanel>

        <CollapsePanel key={'2'} header="Lojas" $isOpen={activePanels?.includes('2')}>
          <Form.Item name="marketplace">
            <CheckboxFilter
              name="marketplace"
              originalData={filters?.marketplace as string[]}
              tooltipDescription="Aqui voce pode filtrar por lojas."
            />
          </Form.Item>
        </CollapsePanel>

        <CollapsePanel key={'8'} header="Sellers" $isOpen={activePanels?.includes('8')}>
          <Form.Item name="seller">
            <CheckboxFilter
              name="seller"
              originalData={filters?.seller as string[]}
              tooltipDescription="Aqui voce pode filtrar por sellers."
            />
          </Form.Item>
        </CollapsePanel>

        {filters?.tags && filters?.tags.length > 0 && (
          <CollapsePanel key={'9'} header="Tags" $isOpen={activePanels?.includes('9')}>
            <Form.Item name="tags">
              <CheckboxFilter
                name="tags"
                originalData={filters?.tags as string[]}
                tooltipDescription="Aqui voce pode filtrar por tags associadas a produtos."
              />
            </Form.Item>
          </CollapsePanel>
        )}

        {filters?.categorias && filters?.categorias.length > 0 && (
          <CollapsePanel key={'10'} header="Categorias" $isOpen={activePanels?.includes('10')}>
            <Form.Item name="categorias">
              <CheckboxFilter
                name="categorias"
                originalData={filters?.categorias as string[]}
                tooltipDescription="Aqui voce pode filtrar por categorias associadas a produtos."
              />
            </Form.Item>
          </CollapsePanel>
        )}

        <CollapsePanel key={'12'} header="Produtos novos" $isOpen={activePanels?.includes('12')}>
          <Form.Item name="novos">
            <RadioFilter
              name="novos"
              data={specialConditionsFilter}
              tooltipDescription="Aqui voce pode filtrar pelos produtos recém absorvidos."
            />
          </Form.Item>
        </CollapsePanel>

        <CollapsePanel key={'13'} header="Possui review" $isOpen={activePanels?.includes('13')}>
          <Form.Item name="review">
            <RadioFilter
              name="review"
              data={specialConditionsFilter}
              tooltipDescription="Aqui voce pode filtrar por produtos que possuem review."
            />
          </Form.Item>
        </CollapsePanel>

        <CollapsePanel
          key={'11'}
          header="Condições especiais"
          $isOpen={activePanels?.includes('11')}
        >
          <Form.Item name="frete_gratis">
            <RadioFilter
              title="Frete Grátis"
              name="frete_gratis"
              data={specialConditionsFilter}
              tooltipDescription="Aqui voce pode filtrar por produtos com frete gratis."
              initialValue={false as unknown as CheckboxOptionType}
            />
          </Form.Item>

          <Divider />

          <Form.Item name="cupom">
            <RadioFilter
              title="Cupom de desconto"
              name="cupom"
              data={specialConditionsFilter}
              tooltipDescription="Aqui voce pode filtrar por produtos com cupom de desconto."
              initialValue={false as unknown as CheckboxOptionType}
            />
          </Form.Item>

          <Divider />

          <Form.Item name="cashback">
            <RadioFilter
              title="Cashback"
              name="cashback"
              data={specialConditionsFilter}
              tooltipDescription="Aqui voce pode filtrar por produtos com cashback."
              initialValue={false as unknown as CheckboxOptionType}
            />
          </Form.Item>

          <Divider />

          <Form.Item name="patrocinado">
            <RadioFilter
              title="Ofertas Patrocinadas"
              name="patrocinado"
              data={specialConditionsFilter}
              tooltipDescription="Aqui voce pode filtrar por produtos com ofertas patrocinadas."
              initialValue={false as unknown as CheckboxOptionType}
            />
          </Form.Item>
        </CollapsePanel>

        <CollapsePanel key={'3'} header="Disponibilidade" $isOpen={activePanels?.includes('3')}>
          <Form.Item name="disponivel">
            <RadioFilter
              name="disponivel"
              data={availabilityFilter}
              tooltipDescription="Aqui voce pode filtrar pela disponibilidade dos produtos."
              initialValue={true as unknown as CheckboxOptionType}
            />
          </Form.Item>
        </CollapsePanel>

        {/* text_search input is created as a controlled component to make it easier to reset it after clearing it through ActiveFiltersDropdown */}
        <CollapsePanel key={'4'} header="Palavra Chave" $isOpen={activePanels?.includes('4')}>
          <Input
            addonAfter={<SearchOutlined />}
            value={textSearchInput}
            onChange={(e) => setTextSearchInput(e.target.value)}
          />
        </CollapsePanel>

        <CollapsePanel
          key={'5'}
          header="Memorias e Armazenamento"
          $isOpen={activePanels?.includes('5')}
        >
          <Form.Item name="ram">
            <CheckboxFilter
              name="ram"
              originalData={filters?.ram as string[]}
              title="memoria ram"
              tooltipDescription="Aqui voce pode filtrar por memorias ram."
            />
          </Form.Item>

          <Divider />

          <Form.Item name="tipo_hd">
            <CheckboxFilter
              name="tipo_hd"
              originalData={filters?.tipo_hd as string[]}
              title="Tipo de armazenamento"
              tooltipDescription="Aqui voce pode filtrar por tipos de armazenamento."
            />
          </Form.Item>

          <Divider />

          <Form.Item name="hd">
            <CheckboxFilter
              name="hd"
              originalData={filters?.hd as string[]}
              title="armazenamento"
              tooltipDescription="Aqui voce pode filtrar HDs"
            />
          </Form.Item>
        </CollapsePanel>

        <CollapsePanel
          key={'6'}
          header="Processador e Sistema Operacional"
          $isOpen={activePanels?.includes('6')}
        >
          <Form.Item name="processador">
            <CheckboxFilter
              name="processador"
              originalData={filters?.processador as string[]}
              title="processador"
              tooltipDescription="Aqui voce pode filtrar por processadores."
            />
          </Form.Item>

          <Divider />

          <Form.Item name="processador_serie">
            <CheckboxFilter
              name="processador_serie"
              originalData={filters?.processador_serie as string[]}
              title="processador_serie"
              tooltipDescription="Aqui voce pode filtrar por linhas de processadores."
            />
          </Form.Item>

          <Divider />

          <Form.Item name="velocidade_processador">
            <CheckboxFilter
              name="velocidade_processador"
              originalData={filters?.velocidade_processador as string[]}
              title="Velocidade do processador"
              tooltipDescription="Aqui voce pode filtrar por velocidade de processadores."
            />
          </Form.Item>

          <Divider />

          <Form.Item name="sistema_operacional">
            <CheckboxFilter
              name="sistema_operacional"
              originalData={filters?.sistema_operacional as string[]}
              title="sistema operacional"
              tooltipDescription="Aqui voce pode filtrar por sistemas operacionais"
            />
          </Form.Item>
        </CollapsePanel>

        <CollapsePanel
          key={'7'}
          header="Placa de Video e Tela"
          $isOpen={activePanels?.includes('7')}
        >
          <Form.Item name="placa_video">
            <CheckboxFilter
              name="placa_video"
              originalData={filters?.placa_video as string[]}
              title="Placa de video"
              tooltipDescription="Aqui voce pode filtrar por placas de video."
            />
          </Form.Item>

          <Divider />

          <Form.Item name="tela">
            <CheckboxFilter
              name="tela"
              originalData={filters?.tela as string[]}
              title="tela"
              tooltipDescription="Aqui voce pode filtrar por tamanhos de tela."
            />
          </Form.Item>

          <Divider />

          <Form.Item name="resolucao_tela">
            <CheckboxFilter
              name="resolucao_tela"
              originalData={filters?.resolucao_tela as string[]}
              title="Resolução"
              tooltipDescription="Aqui voce pode filtrar por resoluções de tela."
            />
          </Form.Item>
        </CollapsePanel>
      </Collapse>
    </FilterSidebar>
  )
}

export { ProductFilters }
