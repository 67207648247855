import { useCallback } from 'react'

import autoAnimate from '@formkit/auto-animate'

const useAutoAnimateRef = (options = {}) => {
  const callbackRef = useCallback(
    (el: any) => el instanceof HTMLElement && autoAnimate(el, options),
    [options],
  )

  return [callbackRef]
}

export { useAutoAnimateRef }
