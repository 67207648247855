import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import type { Tag } from '../../types'
import { tagKeys } from '../query-keys'

import { ApiResponse } from '@/common'
import { api } from '@/services'

type TagResponse = ApiResponse<Tag[]>

async function fetchTags({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof tagKeys)['product']>>) {
  const [, , productId] = queryKey

  try {
    const { data } = await api.get<TagResponse>(`/tag?produto=${productId}`)

    return data.result
  } catch (err) {
    return []
  }
}

const useProductTags = (productId: string) => {
  return useQuery(tagKeys.product(productId), fetchTags, {
    keepPreviousData: true,
    retry: false,
  })
}

export { useProductTags }
