import { Form, FormInstance, Input, Select } from 'antd'

import { useGroups } from '../../../queries'

type EditUserFormProps = {
  form: FormInstance<any>
  handleEditUser: (values: any) => void
  isEditing: boolean
}

const EditUserForm = ({ form, handleEditUser, isEditing }: EditUserFormProps) => {
  const { data: groups, isLoading } = useGroups()

  const selectGroups = groups?.map((group) => ({
    value: group.groupName,
    label: group.groupName,
  }))

  return (
    <Form
      form={form}
      disabled={isEditing}
      layout="vertical"
      name="register"
      initialValues={{
        name: '',
        username: '',
        email: '',
        old_group: '',
        groups: '',
      }}
      onFinish={handleEditUser}
      scrollToFirstError
    >
      <Form.Item name="name" label="Nome">
        <Input placeholder="Nome" autoComplete="off" />
      </Form.Item>

      <Form.Item name="username" label="Email">
        <Input placeholder="Email" autoComplete="off" disabled />
      </Form.Item>

      <Form.Item name="groups" label="Grupo">
        <Select placeholder="Grupo" loading={isLoading} options={selectGroups} />
      </Form.Item>
    </Form>
  )
}

export { EditUserForm }
