import { Dropdown, MenuProps } from 'antd'

import { DropdownTrigger, SettingsIcon } from './styles'

interface TableActionProps {
  dropdownOptions: MenuProps['items']
  icon?: JSX.Element
}

const TableAction = ({ dropdownOptions, icon }: TableActionProps) => {
  return (
    <Dropdown menu={{ items: dropdownOptions }} trigger={['click']} arrow={{ pointAtCenter: true }}>
      <DropdownTrigger onClick={(e) => e.preventDefault()}>
        {icon ? icon : <SettingsIcon />}
      </DropdownTrigger>
    </Dropdown>
  )
}

export { TableAction }
