import { useCallback, useMemo } from 'react'

import { Divider, Typography } from 'antd'
import dayjs from 'dayjs'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import * as S from './styles'

import { formatCurrency } from '@/common'
import { ProductHistory, Offers, History } from '@/features/product/types'

type PriceHistoryGraphicProps = {
  data: ProductHistory[]
}

type FormattedHistory = {
  original_date: Date
  data_hora: string
  preco: number
  seller: string
}

type FormattedProductHistory = {
  marketplace: string
  historico: FormattedHistory[]
}

type Entry = {
  original_date: Date
  data_hora: string
  preco: number
  seller: string
}

type Result = {
  [key: string]: string | number
  date: string
}

type TooltipPayload = {
  color: string
  dataKey: string
  name: string // Marketplace
  value: number
  payload: {
    [key: string]: string | number // Marketplace | Date
  }
}

type CustomTooltip = {
  active: boolean
  payload: TooltipPayload[]
  label: string
}

type LegendPayload = {
  color: string
  value: string
}

type CustomLegend = {
  payload: LegendPayload[]
}

const MARKETPLACE_COLORS = new Map([
  ['amazon', '#FA9902'], // #FFF
  ['mercado livre', '#FDE541'],
  ['casas bahia', '#1133C6'],
  // ['americanas', '#F80232'],
  ['acer store', '#83C141'],
  ['ponto frio', '#212121'],
  ['fast shop', '#E73340'],
  ['magazine luiza', '#2686FF'],
  // ['submarino', '#1733FF'],
  ['extra', '#C11A26'],
  ['carrefour', '#1E5BC6'],
])

const DEFAULT_COLOR = '#367100'

const PriceHistoryGraphic = ({ data }: PriceHistoryGraphicProps) => {
  const CustomTooltip = useCallback(
    ({ active, payload, label: tooltipDate }: CustomTooltip) => {
      const currentServerItems = data.reduce((acc, item) => {
        payload.map((payloadItem) => {
          if (item.marketplace === payloadItem.name) {
            acc.push(item)
          }
        })
        return acc
      }, [] as ProductHistory[])

      if (active && payload && payload.length) {
        return (
          <S.TooltipContainer>
            <S.TooltipDate>{tooltipDate}</S.TooltipDate>
            <Divider style={{ margin: 0 }} />
            {payload.map((entry) => {
              const currentMarketplace = currentServerItems.find(
                (item) => item.marketplace === entry.name,
              )

              if (!currentMarketplace) return null

              const currentMarketplaceOffers = currentMarketplace.ofertas
                .flatMap((offer) =>
                  offer.historico.map((history) => ({
                    original_date: new Date(history.data_hora),
                    data: dayjs(history.data_hora).format('DD/MM'),
                    hora: dayjs(history.data_hora).format('HH:mm'),
                    preco: formatCurrency({ value: history.preco }),
                    seller: offer.seller,
                  })),
                )
                .sort((a, b) => (dayjs(a.original_date).isAfter(dayjs(b.original_date)) ? 1 : -1))

              const currentDayOffers = currentMarketplaceOffers.filter(
                (offer) => offer.data === tooltipDate,
              )

              return (
                <S.TooltipList key={entry.name} divider>
                  <S.TooltipMarketplace color={entry.color || DEFAULT_COLOR}>
                    {entry.name}
                  </S.TooltipMarketplace>
                  {currentDayOffers.map((offer) => (
                    <S.TooltipItem key={crypto.randomUUID()}>
                      <S.TooltipOffer>
                        <S.TooltipSeller>{offer.seller || entry.name}</S.TooltipSeller>
                        <S.TooltipPriceContainer>
                          <S.OfferTime>{offer.hora}</S.OfferTime>
                          <S.OfferPrice>{offer.preco}</S.OfferPrice>
                        </S.TooltipPriceContainer>
                      </S.TooltipOffer>
                    </S.TooltipItem>
                  ))}
                </S.TooltipList>
              )
            })}
          </S.TooltipContainer>
        )
      }
      return null
    },
    [data],
  )

  const CustomLegend = useCallback(({ payload }: CustomLegend) => {
    return (
      <S.LegendList>
        {payload.map((entry, index) => (
          <S.LegendItem key={`item-${index}`}>
            <S.Legend color={entry.color} />
            <Typography.Text>{entry.value}</Typography.Text>
          </S.LegendItem>
        ))}
      </S.LegendList>
    )
  }, [])

  function formatData(data: ProductHistory[]): Result[] {
    const serverFormat: FormattedProductHistory[] = data.map((item: ProductHistory) => {
      const historico: FormattedHistory[] = item.ofertas.flatMap((offer: Offers) =>
        offer.historico.map((entry: History) => ({
          original_date: new Date(entry.data_hora),
          data_hora: dayjs(entry.data_hora).format('DD/MM'),
          preco: entry.preco,
          seller: offer.seller,
        })),
      )

      const sortedHistorico = historico
        .sort((a, b) => a.preco - b.preco)
        .sort((a, b) => a.original_date.getTime() - b.original_date.getTime())

      return {
        marketplace: item.marketplace,
        historico: sortedHistorico,
      }
    })

    const formatDays: FormattedProductHistory[] = serverFormat.map((item) => {
      const { historico } = item

      const reducedHistorico: Entry[] = historico.reduce((acc: Entry[], entry: Entry) => {
        const existingEntry = acc.find((accEntry) =>
          dayjs(accEntry.original_date).isSame(entry.original_date, 'day'),
        )

        if (!existingEntry) {
          acc.push(entry)
        } else if (existingEntry.preco > entry.preco) {
          existingEntry.preco = entry.preco
        }

        return acc
      }, [])

      return {
        ...item,
        historico: reducedHistorico,
      }
    })

    const graphFormat: Result[] = formatDays.reduce(
      (acc: Result[], item: FormattedProductHistory) => {
        item.historico.forEach((entry: Entry) => {
          const existingEntry = acc.find((e) =>
            dayjs(e.date).isSame(dayjs(entry.original_date), 'day'),
          )

          if (existingEntry) {
            existingEntry[item.marketplace] = entry.preco
          } else {
            const obj: Result = {
              date: entry.original_date.toISOString(),
              [item.marketplace]: entry.preco,
            }
            acc.push(obj)
          }
        })

        return acc
      },
      [],
    )

    return graphFormat
      .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
      .map((item) => ({
        ...item,
        date: dayjs(item.date).format('DD/MM'),
      }))
  }

  function generateLines(data: Result[]) {
    const lines = Array.from(new Set(data.flatMap((item) => Object.keys(item)))).filter(
      (key) => key !== 'date',
    )

    return lines
  }

  const graphData = useMemo(() => formatData(data), [data])

  const lines = useMemo(() => generateLines(graphData), [graphData])

  return (
    <ResponsiveContainer width="100%" height={385}>
      <LineChart data={graphData} key={crypto.randomUUID()}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          content={({ active, payload, label }) => (
            <CustomTooltip
              active={active as boolean}
              payload={payload as TooltipPayload[]}
              label={label}
            />
          )}
          position={{ y: -100 }}
        />
        <XAxis interval={0} dataKey="date" dx={-15} angle={-45} tickMargin={20} allowDataOverflow />
        <YAxis tickFormatter={(v) => formatCurrency({ value: v, type: 'decimal' })} />
        <Legend content={({ payload }) => <CustomLegend payload={payload as LegendPayload[]} />} />
        {lines.map((line) => {
          const color = MARKETPLACE_COLORS.get(line.toLowerCase())

          return (
            <Line
              key={line}
              dataKey={line}
              stroke={color || DEFAULT_COLOR}
              connectNulls
              strokeWidth={3}
              dot={{ strokeWidth: 9 }}
            />
          )
        })}
      </LineChart>
    </ResponsiveContainer>
  )
}

export { PriceHistoryGraphic }
