import { Link } from 'react-router-dom'

import styled, { CSSProperties } from 'styled-components'

type ContainerProps = Pick<CSSProperties, 'gap'>

export const TableColumnContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  gap: ${({ gap }) => `${gap}px`};
`

export const NotificationDate = styled.span`
  font-size: 10px;
  font-weight: 300;
  color: #666;
`

export const ProductLink = styled(Link)`
  max-width: 600px;
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 600;
  text-transform: capitalize;

  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }
`

export const ProductTitle = styled.h2`
  max-width: 600px;
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;

  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }
`

export const NotificationPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Text = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #666;
`

export const ReferencePrice = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`

export const ReferencePriceText = styled(ReferencePrice)`
  font-weight: 400;
`

export const NotificationPrice = styled(Text)<{ $color?: string }>`
  font-weight: 700;
  color: ${({ color }) => (color ? color : '#666')};
  display: flex;
  align-items: center;
  gap: 4px;

  .anticon {
    font-size: 14px;
  }
`

export const DropdownOptionsContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`
