import { Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table'

import type { Group } from '../../../types'
import { Description, TableContainer, Text } from './styles'

type GroupListTableProps = {
  data: Group[]
  isLoading: boolean
}

const GroupListTable = ({ data, isLoading }: GroupListTableProps) => {
  const columns: ColumnsType<Group> = [
    {
      title: 'Nome',
      dataIndex: 'groupName',
      width: 100,
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      width: 600,
      render: (_, record) =>
        record.groupName === 'admin' ? (
          <Description>
            Acesso as telas: Listagem de produtos, Dashboard, Usuários, Grupos, Categorização de
            produtos e Criação de notificações.
          </Description>
        ) : (
          <Description>
            Acesso as telas: Listagem de produtos, Dashboard e Ver notificações.
          </Description>
        ),
    },
    // {
    //   title: 'Criado em',
    //   dataIndex: 'creationDate',
    //   align: 'center',
    //   width: 200,
    //   render: (value) => new Date(value).toLocaleDateString('pt-BR'),
    // },
    // {
    //   title: 'Ultima atualização',
    //   dataIndex: 'lastModifiedDate',
    //   align: 'center',
    //   width: 150,
    //   render: (value) => new Date(value).toLocaleDateString('pt-BR'),
    // },
  ]

  return (
    <TableContainer>
      <Table
        rowKey={'groupName'}
        columns={columns}
        dataSource={data}
        loading={isLoading}
        showHeader
        pagination={false}
        footer={() => ''}
      />
    </TableContainer>
  )
}

export { GroupListTable }
