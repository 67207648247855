import { Divider as AntdDivider, Button, Typography } from 'antd'
import styled from 'styled-components'

import { hexToRgba } from '@/common'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h2`
  font-size: 18px;
  color: #414243;
`

export const TotalNotifications = styled(Title)`
  color: #434343;
  font-weight: 700;
`
export const TotalNotificationsValue = styled(Title)`
  color: #3d85c6;
  font-weight: 700;
`

export const Divider = styled(AntdDivider)<{ color?: string; thickness?: number }>`
  color: ${({ color }) => (color ? color : '#414243')};
  border-left: ${({ thickness }) => (thickness ? `${thickness}px` : '1px')} solid
    ${({ color }) => (color ? color : '#414243')};
  border-color: #414243;
  height: 18px;
  margin: 0 4px;
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const HeaderText = styled(Typography.Text)`
  font-size: 16px;
  color: #434343;
`

export const CheckAllButton = styled(Button)`
  font-size: 16px;
  padding: 0;
  color: ${({ theme }) => theme.colors.brand};

  :hover {
    color: ${({ theme }) => hexToRgba(theme.colors.brand, 0.7)} !important;
  }
`

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 16px solid #f0f2f5;
  }
`

export const DeleteAllButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: #fff;
  border-color: ${({ theme }) => theme.colors.brand}; // darkGreen
  color: ${({ theme }) => theme.colors.brand}; // darkGreen

  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => hexToRgba(theme.colors.brand, 0.7)} !important; // darkGreen
    border-color: ${({ theme }) => hexToRgba(theme.colors.brand, 0.7)} !important; // darkGreen
  }
`
