import { useQuery } from '@tanstack/react-query'

import { api } from '../../../../services'
import { DashboardCards } from '../../types'
import { dashboardKeys } from '../query-keys'

const fetchCards = async () => {
  const { data } = await api.get<DashboardCards>('/dashboard')

  return data.info
}

const useDashboardCards = () => useQuery(dashboardKeys.cards(), fetchCards, { retry: 2 })

export { useDashboardCards }
