import { ActiveFilters, ActiveSorting } from '../../../common'
import { PriceHistoryQuery } from '../types'

export const productKeys = {
  all: ['products'] as const,
  lists: () => [...productKeys.all, 'list'] as const,
  list: (page: number, activeFilters: ActiveFilters, activeSorting: ActiveSorting) =>
    [...productKeys.lists(), { page, activeFilters, activeSorting }] as const,
  details: () => [...productKeys.all, 'detail'] as const,
  detail: (productId: string) => [...productKeys.details(), productId] as const,
  history: ({ productId, params: { data_inicio, data_fim } }: PriceHistoryQuery) =>
    [...productKeys.detail(productId), data_inicio, data_fim, 'history'] as const,
  marketplaces: (productId: string) => [...productKeys.detail(productId), 'marketplaces'] as const,
  marketplace: (productId: string, page: number, tableSort: ActiveSorting) =>
    [...productKeys.marketplaces(productId), { page, tableSort }] as const,
  similars: () => [...productKeys.all, 'similar'] as const,
  similar: (productId: string) => [...productKeys.similars(), productId] as const,
}

export const filterKeys = {
  all: ['filters'] as const,
  list: () => [...filterKeys.all, 'list'] as const,
  presets: () => [...filterKeys.all, 'preset'] as const,
  preset: (presetId: string) => [...filterKeys.presets(), presetId] as const,
}

export const tagKeys = {
  all: ['tags'] as const,
  list: () => [...tagKeys.all, 'list'] as const,
  product: (productId: string) => [...tagKeys.list(), productId] as const,
}

export const categoryKeys = {
  all: ['categories'] as const,
  list: () => [...categoryKeys.all, 'list'] as const,
  product: (productId: string) => [...categoryKeys.list(), productId] as const,
}
