import axios, { AxiosError } from 'axios'

import { signOut } from '@/app'
import authStore from '@/app/stores/auth'
import filterStore from '@/app/stores/filters'

const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/v0`,
})

// set header with fresh token
api.defaults.headers.common.Authorization = `Bearer ${authStore.getState().idToken}`

let isRefreshing = false
let failedRequestQueue: any[] = []

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // error with token
    if (error.response?.status === 401) {
      const refreshToken = authStore.getState().refreshToken
      const originalConfig = error.config

      if (!isRefreshing) {
        isRefreshing = true

        api
          .post('/auth/refresh_token', {
            refresh_token: refreshToken,
          })
          .then((res) => {
            const { AccessToken, IdToken } = res.data

            authStore.setState(
              {
                accessToken: AccessToken,
                idToken: IdToken,
                isUserAuthenticated: true,
              },
              false,
              'refresh-token',
            )

            api.defaults.headers.common.Authorization = `Bearer ${IdToken}`

            failedRequestQueue.forEach((req) => req.onSuccess(IdToken))
            failedRequestQueue = []
          })
          .catch((err) => {
            failedRequestQueue.forEach((req) => req.onFailure(err))
            failedRequestQueue = []

            filterStore.setState({ lastActivePreset: null }, false, 'refresh-token')
            filterStore.getState().resetFilters()
            signOut()
          })
          .finally(() => {
            isRefreshing = false
          })
      }

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: (token: string) => {
            originalConfig!.headers.Authorization = `Bearer ${token}`

            resolve(api(originalConfig!))
          },
          onFailure: (err: AxiosError) => {
            reject(err)
          },
        })
      })
    }

    return Promise.reject(error)
  },
)

export { api }
