import { HiFire } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { TagOutlined } from '@ant-design/icons'
import { Divider as AntdDivider, Button, Tag } from 'antd'
import styled, { css } from 'styled-components'

import { hexToRgba } from '@/common'

type TooltipTableTextProps = {
  isDisabled: boolean
}

type TableTextProps = {
  $isBold?: boolean
  color?: string
}

type ProductLinkProps = {
  $isBold?: boolean
  $isDark?: boolean
}

export const Title = styled.h2`
  font-weight: 700;
  font-size: 18px;
  color: #414243;
`

export const TotalProducts = styled(Title)`
  color: #414243;
`

export const Divider = styled(AntdDivider)`
  color: #414243;
  border-left: 2px solid #414243;
  border-color: #414243;
  height: 18px;
  margin: 0 4px;
`

export const TableContainer = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background-color: rgba(240, 242, 245, 0.5);
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: #efefef;
  }

  .ant-table-wrapper .ant-table-column-sort {
    background: none;
  }

  .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 0;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border: 1px solid var(--conditional-divider, rgba(0, 0, 0, 0.06));
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
    background-color: #f4f4f4;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border: 0;
  }

  /* .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  } */

  /* .ant-table-tbody > tr.light-row.ant-table-row:hover > td {
    background: #fff;
  }
  .ant-table-tbody > tr.dark-row.ant-table-row:hover > td {
    background: #efefef;
  }*/
`

export const ExpandedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  img {
    width: 130px;
    height: 100px;
  }

  > div {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    row-gap: 4px;

    .ant-tag {
      height: fit-content;
      text-transform: capitalize;
    }
  }
`

export const TableText = styled.span<TableTextProps>`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: ${({ $isBold }) => ($isBold ? 700 : 400)};
  color: ${({ color }) => (color ? color : '#000000d9')};
`

export const TooltipText = styled.span`
  font-size: 14px;
  color: #000000d9;
`

export const Text = styled.span`
  text-transform: capitalize;
  font-weight: 600;
`

export const StoreLink = styled.a<TooltipTableTextProps>`
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #d9d9d9;
      cursor: not-allowed;

      :hover {
        color: #d9d9d9;
        filter: brightness(1);
      }
    `}
`

export const TooltipTableText = styled.span<TooltipTableTextProps>`
  color: #000000d9;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #d9d9d9;
      cursor: not-allowed;
    `}
`

export const TooltipTableFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  a {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.brand};
    margin-left: auto;
    padding: 0;
  }
`

export const StoreIcon = styled(TagOutlined)`
  color: ${({ theme }) => theme.colors.darkGreen};
  font-size: 16px;
  transform: rotateY(180deg);
`

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
`

export const TagNewProduct = styled(Tag)`
  color: #6ea5f8;
  background-color: rgba(110, 165, 248, 0.1);
  border: 1px solid #6ea5f8;

  border-radius: 2px;
  margin: 0;
`

export const ProductLink = styled(Link)<ProductLinkProps>`
  color: ${({ theme, $isDark }) => ($isDark ? theme.colors.darkGreen : theme.colors.brand)};
  font-weight: ${({ $isBold }) => ($isBold ? 700 : 400)};
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease;

  max-width: 500px;

  :hover {
    color: ${({ theme, $isDark }) =>
      $isDark ? hexToRgba(theme.colors.darkGreen, 0.7) : theme.colors.brand};
    filter: brightness(0.8);
  }
`

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const CategoryTooltip = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: #fff;
  background-color: #ff8b27;
  border-radius: 9999px;
`

export const CategoryButton = styled(Button)`
  text-transform: capitalize;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGreen};
  padding: 0;

  :hover {
    color: ${({ theme }) => hexToRgba(theme.colors.darkGreen, 0.7)} !important;
  }
`

export const MarketplaceContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const FireIcon = styled(HiFire)`
  font-size: 20px;
  color: #4a91fd;
`

export const ConditionsContainer = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  padding: 0;
  border: none;
  cursor: pointer;

  font-size: 20px;
  color: #4a91fd;
`

export const SimilarProductButton = styled(Button)``

export const ReviewButton = styled(Button)`
  display: flex;
  align-items: center;
`

export const ReviewArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const TagReview = styled(Tag)`
  color: #faad14;
  background-color: rgba(255, 251, 230, 1);
  border: 1px solid #ffe58f;

  margin: 0;
  border-radius: 2px;
`
