import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Form } from 'antd'

import * as S from './styles'

import {
  ConfirmForgotPasswordCredentials,
  ConfirmForgotPasswordForm,
  ForgotPasswordCredentials,
  ForgotPasswordForm,
  useConfirmForgotPasswordMutation,
  useForgotPasswordMutation,
} from '@/features/auth'
import { AccessLayout } from '@/layouts'

const ForgotPasswordPage = () => {
  const navigate = useNavigate()

  const forgotPasswordMutation = useForgotPasswordMutation()
  const confirmChangePasswordMutation = useConfirmForgotPasswordMutation()

  const [codeSent, setCodeSent] = useState(false)
  const [form] = Form.useForm<ForgotPasswordCredentials>()

  const description = codeSent
    ? 'Insira o código recebido no email'
    : 'Insira seu email para receber o código de redefinição da senha'

  function handleSendCode({ email }: ForgotPasswordCredentials) {
    forgotPasswordMutation.mutate({ email, setCodeSent })
  }

  function handleChangePassword({
    email,
    password,
    confirmationCode,
  }: ConfirmForgotPasswordCredentials) {
    confirmChangePasswordMutation.mutate({ email, newPassword: password, confirmationCode })
  }

  return (
    <AccessLayout title="Recuperar Senha" description={description}>
      <S.Container>
        {!codeSent ? (
          <>
            <ForgotPasswordForm
              form={form}
              handleSubmit={handleSendCode}
              isLoading={forgotPasswordMutation.isLoading}
            />

            <Button
              htmlType="button"
              type="text"
              onClick={() => navigate('/auth/login')}
              disabled={forgotPasswordMutation.isLoading}
            >
              Cancelar
            </Button>
          </>
        ) : (
          <>
            <ConfirmForgotPasswordForm
              userEmail={form.getFieldValue('email')}
              handleConfirmForgotPassword={handleChangePassword}
              isLoading={confirmChangePasswordMutation.isLoading}
            />

            <Button
              type="link"
              htmlType="button"
              onClick={() => setCodeSent(false)}
              disabled={confirmChangePasswordMutation.isLoading}
            >
              Voltar
            </Button>
          </>
        )}
      </S.Container>
    </AccessLayout>
  )
}

export { ForgotPasswordPage }
