import { useQuery } from '@tanstack/react-query'

import { OffersByMarketplaceGraph } from '../../types'
import { dashboardKeys } from '../query-keys'

import { api } from '@/services'

const fetchOffersByMarketplace = async () => {
  const { data } = await api.get<OffersByMarketplaceGraph[]>('/dashboard/graphs/marcas_loja')

  return data
}

const useOffersByMarketplaceGraph = () =>
  useQuery(dashboardKeys.offersByMarketplace(), fetchOffersByMarketplace, { retry: 2 })

export { useOffersByMarketplaceGraph }
