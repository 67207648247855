import styled from 'styled-components'

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
`

export const TooltipTitle = styled.strong``

export const TooltipItem = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`
