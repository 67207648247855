import { Link } from 'react-router-dom'

import { Button, Typography, Divider as AntdDivider, Select as AntdSelect } from 'antd'
import styled from 'styled-components'

import { hexToRgba } from '@/common'

type SecondContainerProps = {
  $isRenderingList: boolean
}

type ProductLinkProps = {
  $isBold?: boolean
  $isDark?: boolean
}

export const FinishButton = styled(Button)`
  border-radius: 2px;
  background: #83b81a;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const FirstContainer = styled.div`
  .ant-typography {
    line-height: initial;
  }

  .ant-typography strong {
    color: #666;
    font-size: 22px;
    font-weight: 700;
  }
`

// export const ShareButton = styled(Button)`
//   padding: 0;
// `

export const DescriptionArea = styled.div`
  display: flex;

  padding: 4px 46px 12px;
  gap: 35px;
`

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 0 0;

  gap: 26px;
`

export const Title = styled(Typography.Text)`
  color: #666;
  font-size: 16px;
  font-weight: 700;

  width: 636px;

  text-transform: capitalize;
`

export const ProductButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid #80c343;

  width: 102px;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const Divider = styled(AntdDivider)`
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0;
`

export const SecondContainer = styled.div<SecondContainerProps>`
  padding: 28px 60px;

  height: ${({ $isRenderingList }) => `${$isRenderingList ? 380 : 300}px`};
  overflow-y: scroll;

  .ant-typography {
    line-height: initial;
  }

  .ant-typography strong {
    color: #666;
    font-size: 22px;
    font-weight: 700;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const ProductTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SimilarProductText = styled(Typography.Text)`
  font-size: 20px;
  color: #434343;
`

export const FindValue = styled(Typography.Text)`
  font-size: 20px;
  color: #4a91fd;
`

export const DropdownArea = styled.div`
  display: flex;
  align-items: center;

  gap: 7px;
  color: #434343;

  .ant-select .ant-select-arrow {
    color: #5ea320;
  }

  .ant-select-selector {
    border: 0 !important;
  }
`

export const Select = styled(AntdSelect)`
  width: 257px;
  border: 2px;

  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
`

export const ProductArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 10px;
`

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ProductDescriptionArea = styled.div`
  display: flex;
  flex-direction: column;

  color: #666;

  width: 740px;
  gap: 16px;
`

export const FirstArea = styled.div`
  display: flex;
  align-items: center;

  gap: 12px;
`

export const Title2 = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 300;

  text-transform: uppercase;
`

export const Data = styled(Typography.Text)`
  color: rgba(102, 102, 102, 0.69);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-style: italic;
`

export const ReviewName = styled(Typography.Text)`
  color: #80c343;
  font-size: 16px;
  font-weight: 600;
`

export const ReviewDescription = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 400;
`

export const LinkArea = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2px;

  color: #666;
  font-weight: 400;
  font-style: italic;
`

export const ReviewsTitle = styled.div`
  color: rgba(65, 66, 67, 0.88);
  font-size: 18px;

  padding-top: 30px;
`

export const ProductLink = styled(Link)<ProductLinkProps>`
  color: ${({ theme, $isDark }) => ($isDark ? theme.colors.darkGreen : theme.colors.brand)};
  font-weight: ${({ $isBold }) => ($isBold ? 700 : 400)};
  text-transform: lowercase;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: initial;
  font-size: 16px;

  :hover {
    color: ${({ theme, $isDark }) =>
      $isDark ? hexToRgba(theme.colors.darkGreen, 0.7) : theme.colors.brand};
    filter: brightness(0.8);
  }
`

export const NoButton = styled.span`
  display: block;
`

export const SkeletonFirstContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 25px;
  gap: 10px;
`

export const SkeletonInformationArea = styled.div``

export const SkeletonTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 4px;
`

export const SkeletonText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 26px;
`

export const SkeletonSecondContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 60px 25px;
  gap: 10px;
`

export const SkeletonSecondTitle = styled.div`
  display: flex;
  justify-content: space-around;

  padding: 0 0 35px;
  gap: 400px;
`

export const SkeletonSecondInformation = styled.div`
  display: flex;

  gap: 30px;
`

export const SkeletonReviewArea = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 4px;
`

export const SkeletonLink = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 16px;
  gap: 4px;
`
