import { DefaultTheme } from 'styled-components'

export const antdTheme = {
  token: {
    colorPrimary: '#80C343',
    colorLink: '#80C343',
    colorLinkActive: '#5f9c2d',
    colorLinkHover: '#80C343b3',
    darkGreen: '#5ea320',
    colorTextBase: '#414243',
    colorBgLayout: '#f0f2f5',
    colorWhite: '#fff',
    fontFamily: 'AcerFoco',
    borderRadius: 5,
  },
}

export const styledTheme: DefaultTheme = {
  colors: {
    white: antdTheme.token.colorWhite,
    brand: antdTheme.token.colorPrimary,
    darkGreen: antdTheme.token.darkGreen,
    text: antdTheme.token.colorTextBase,
    black: '#000',
  },
  breakpoints: {
    xl: 'only screen and (max-width: 1279px)',
    lg: 'only screen and (max-width: 1023px)',
    md: 'only screen and (max-width: 767px)',
    sm: 'only screen and (max-width: 639px)',
  },
}

/**
 * @example
  @media ${({ theme }) => theme.breakpoints.xl} {}
  @media ${({ theme }) => theme.breakpoints.lg} {}
  @media ${({ theme }) => theme.breakpoints.md} {}
  @media ${({ theme }) => theme.breakpoints.sm} {}
 */
