import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip, Typography } from 'antd'

import { LabelContainer } from './styles'

type InputLabelProps = {
  label: string
  tooltip?: string
}

const InputLabel = ({ label, tooltip }: InputLabelProps) => {
  return (
    <LabelContainer>
      <Typography.Text>{label}</Typography.Text>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <QuestionCircleOutlined />
        </Tooltip>
      ) : null}
    </LabelContainer>
  )
}

export { InputLabel }
