import { Button } from 'antd'
import styled from 'styled-components'

export const CancelButton = styled(Button)`
  color: #80c343;
  border-radius: 2px;
  border: 1px solid #80c343;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const AddButton = styled(Button)`
  border-radius: 2px;
  background: #83b81a;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #a0a0a0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;
`

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 0 16px;

  form {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  input {
    height: 32px;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px 0;
  }
`
