import { CloseCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
  MinusOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Card, Input, Tag, Skeleton, Button, Typography, Tabs as AntdTabs } from 'antd'
import styled from 'styled-components'

import { hexToRgba } from '@/common'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    padding: 0;
  }
`

export const BackButton = styled(Button)`
  width: 32px;
  font-size: 16px;
  background: transparent;
  box-shadow: none;
  color: #80c343;
  border: 0;
  padding: 0;

  :hover {
    color: ${hexToRgba('#80c343', 0.7)};
    background: transparent;
  }
`

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: initial;
  color: #000000;
`

export const UpdateDayTime = styled(Typography)`
  line-height: initial;
  color: #000000;

  strong {
    font-weight: 800;
  }
`
export const AddText = styled(Typography)`
  font-weight: 700;
  line-height: initial;
  color: #414243;
`

export const ProductContainer = styled.div`
  display: flex;
  width: auto;
  height: auto;
  gap: 15px;
  padding: 0px;
`

export const ProductImageCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 280px;
  height: auto;
  border-radius: 5px;
  padding: 0px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 280px;
    height: 100%;

    padding: 0px;
  }

  img {
    width: 220px;
    max-width: 220px;
    max-height: 300px;
    object-fit: contain;
  }

  .ant-image {
    margin: auto 0;
  }
`

export const ActionsContainer = styled.div`
  padding: 8px;
  border-top: 1px solid #d9d9d9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  a,
  button {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.brand};
    background: transparent;
    transition: all 0.2s ease;

    :hover {
      filter: brightness(0.8);
    }
  }

  > button {
    height: 18px;
    width: 18px;
  }
`

export const ProductImageLoading = styled(Skeleton.Image)`
  .ant-skeleton-image {
    border-radius: 5px !important;
    width: 280px !important;
    height: 344px !important;
  }
`

export const ProductInformationCard = styled(Card)`
  width: 100%;
  height: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .table-title {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
  }

  .ant-card-body {
    width: 100%;
    padding: 8px 0;
  }

  &.isLoading {
    .ant-skeleton-input {
      max-width: 100%;
      margin: 0;
    }

    .ant-card-body {
      width: 95%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`

export const MarketplacesInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 355px;
  gap: 15px;
`

export const PricesCardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  gap: 15px;
`

export const PricesCardLoading = styled(Skeleton.Button)`
  .ant-skeleton-button {
    height: 100% !important;
    width: 170px !important;
    border-radius: 5px;
  }
`

export const BorderRadiusCard = styled(Card)`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }

  &.green-bordered {
    position: relative;
    border: 1px solid #80c343;
    cursor: pointer;

    &:hover {
      /* background-color: ${hexToRgba('#80c343', 0.3)}; */
      transition: all 0.2s ease;
    }
  }

  &.inactive {
    cursor: not-allowed;
    border: 1px solid #d9d9d9;
    background: #f5f5f5;

    span {
      color: #d9d9d9;
    }

    .ant-typography {
      color: #d9d9d9;
      font-weight: 700;
    }
  }
`

export const RemoveSuggestedPriceButton = styled(CloseCircleOutlined)`
  position: absolute;
  top: -2px;
  right: -2px;
  color: ${({ theme }) => theme.colors.brand};
  font-size: 20px;
  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    filter: brightness(0.8);
  }
`

export const InactiveBorderRadiusCard = styled(Card)`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 47.5%;
  height: 133px;
  padding: 0px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }

  &.green-bordered {
    border: 1px solid #80c343;
    cursor: not-allowed;
  }
`

export const ChepeastIcon = styled(ArrowDownOutlined)`
  font-size: 30px;
  color: #6ea5f8;
`

export const MostExpensiveIcon = styled(ArrowUpOutlined)`
  font-size: 30px;
  color: #ff5b5b;
`

export const MediumPriceIcon = styled(MinusOutlined)`
  font-size: 30px;
  color: #ff9900;
`

export const SuggestedPriceIcon = styled(EditOutlined)`
  font-size: 30px;
  color: #80c343;
`

export const Price = styled.span<{ color?: string }>`
  font-size: 24px;
  font-weight: 700;
  color: ${({ color }) => color || '#414243'};
`

export const LowerContainer = styled.div`
  display: flex;
  gap: 15px;
  padding: 0px;

  .loadingTags {
    min-width: 280px;
  }
`

export const TagsAndCategoriesContainer = styled.div`
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const NewCategoryArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CategoryList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const Category = styled(Button)`
  font-size: 16px;
  text-transform: capitalize;
`

export const PriceTableCard = styled(Card)`
  width: 100%;
  border-radius: 5px;
  padding: 16px 16px 24px 24px;
  height: 508px;

  .ant-tabs {
    width: 100%;
  }

  .ant-card-body {
    padding: 8px 0 0 0;
  }

  .ant-table-wrapper {
    padding-top: 8px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background-color: ${hexToRgba('#f0f2f5', 0.7)};
  }

  .ant-table-wrapper .ant-table-column-sort {
    background: none;
  }

  &.isLoading {
    width: 100%;
    text-transform: capitalize;

    .loadingHeader {
      display: flex;
      justify-content: space-between;
    }

    .ant-skeleton-input {
      max-width: 100%;
      margin: 0;
    }

    .ant-card-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      gap: 16px;
    }
  }

  .ant-select .ant-select-arrow .anticon > svg {
    color: #5ea320;
    font-size: initial;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-weight: 700;
    color: #434343;
  }

  .ant-select-selector {
    border-radius: 0;
  }
`

export const StoreLink = styled.a`
  color: #70ad47;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    color: #70ad47;
    filter: brightness(0.8);
  }
`

export const CurrencyInput = styled(Input)`
  font-size: 24px;
  margin: 0 auto;
  width: 100%;
  padding: 0;

  text-align: center;
  font-weight: 900;
  padding: 0;
`

export const ProductTag = styled(Tag)`
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.brand};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.brand};
  text-transform: capitalize;

  background: transparent;
  border: 1px solid #cfcfcf;
  color: #666666;
  position: relative;
`

export const NewTagArea = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #414243;
    font-weight: 700;
  }

  .ant-form-item {
    margin: 4px 0;
    .ant-row {
      margin-top: 0 !important;
    }
  }

  button {
    width: 40px;
    padding: 0;

    span {
      display: flex !important;
      justify-content: center;
      color: white;
      font-size: 24px;
      font-weight: bolder;
    }
  }

  .ant-input:hover,
  .ant-input:focus {
    border-color: ${({ theme }) => theme.colors.brand};
    box-shadow: none;
  }
`

export const CreatedTagsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px 4px;
`

export const NotAvailableIcon = styled(StopOutlined)`
  color: #ff5b5b;
  font-size: 18px;
`
export const TooltipText = styled.span`
  font-size: 14px;
  color: #000000d9;
`
export const TypographyS = styled(Typography)`
  font-weight: 700;
`

export const SwitchTitleArea = styled.div`
  display: flex;
  gap: 8px;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DataNotFoundContainer = styled(Card)`
  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }
`

export const GraphLoadingContainer = styled(DataNotFoundContainer)`
  .ant-card-body {
    height: 385px;
  }
`

export const Tabs = styled(AntdTabs)`
  .ant-tabs-tab-btn {
    color: #80c343;
    margin-right: 8px;
    font-size: 20px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #434343;
  }

  .ant-tabs-ink-bar {
    background: #434343;
  }

  .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-tab-btn:focus {
    color: #434343;
  }

  .ant-tabs-tab:hover {
    color: #80c343;

    transition: all 0.2s ease;
    filter: brightness(0.8);
  }
`

export const ActiveTabButton = styled.div`
  .ant-picker {
    margin: 0;
    width: 0;
    padding: 0;
    visibility: hidden;
  }

  .ant-btn {
    font-size: 16px;
    padding: 0 8px;
  }
`

export const AddIcon = styled(PlusCircleFilled)`
  font-size: 24px;
`

export const TagNewProdut = styled(Tag)`
  color: #6ea5f8;
  background-color: rgba(110, 165, 248, 0.1);
  border: 1px solid #6ea5f8;

  border-radius: 2px;
  margin: 0;
`

export const TagReview = styled(Tag)`
  color: #faad14;
  background-color: rgba(255, 251, 230, 1);
  border: 1px solid #ffe58f;

  margin: 0;
  border-radius: 2px;
`
