function getStringInitials(word: string | null, initialsLength = 2) {
  if (!word) return undefined

  const initials = word
    .split(' ')
    .map((letter) => letter[0].toUpperCase())
    .join('')

  if (initialsLength && initials.length > initialsLength) {
    return initials.slice(0, initialsLength)
  }

  return initials
}

export { getStringInitials }
