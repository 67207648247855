import { useState } from 'react'

import { Form, Input, Select } from 'antd'

import { UserTag } from '../../../UserTag'
import * as S from '../styles'

import { InputLabel } from '@/components'
import { getStringInitials } from '@/features/product'

type ThirdStepProps = {
  selectedUsers: string[]
}

const ThirdStep = ({ selectedUsers }: ThirdStepProps) => {
  const form = Form.useFormInstance()

  const [systemUsersValeu, setSystemUsersValeus] = useState(selectedUsers)
  const [externalUsersValeu, setExternalUsersValeus] = useState(() => {
    const externalUsers = form.getFieldValue('email_usuarios_externos')

    if (externalUsers) {
      return externalUsers
    } else {
      return []
    }
  })

  const selectUsers = selectedUsers?.map((user) => ({
    value: user,
    label: user,
  }))

  const labels = {
    system_users: (
      <InputLabel
        label="Enviar para usuários do sistema"
        tooltip="Usuários do sistema selecionados na etapa anterior que irão receber a notificação também por e-mail"
      />
    ),
    external_users: (
      <InputLabel
        label="Enviar e-mail para externos"
        tooltip="Usuários externos que irão receber a notificação"
      />
    ),
    email_message: (
      <InputLabel
        label="Mensagem do e-mail"
        tooltip="Mensagem que será anexada ao e-mail de notificação"
      />
    ),
  }

  return (
    <S.Content>
      <S.Title level={5}>Alerta por e-mail</S.Title>

      <Form.Item
        label={labels.system_users}
        name="email_usuarios_sistema"
        initialValue={selectedUsers}
        shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
      >
        <Select
          mode="multiple"
          placeholder="Usuários"
          size="large"
          tagRender={(props) => {
            return <UserTag userInitial={getStringInitials(props.value, 1)} {...props} />
          }}
          suffixIcon={<S.SelectIcon size={20} />}
          defaultValue={selectedUsers}
          options={selectUsers}
          value={systemUsersValeu}
          onChange={(v) => setSystemUsersValeus(v)}
        />
      </Form.Item>

      <Form.Item
        label={labels.external_users}
        name="email_usuarios_externos"
        rules={[
          () => ({
            validator(_, values) {
              if (values?.length > 0) {
                const actualValues = values.filter((value: string | undefined | null) => !!value)

                const validEmails = actualValues?.filter((email: string) => email.includes('@'))

                if (validEmails?.length !== actualValues?.length) {
                  return Promise.reject(new Error('Por favor, insira um e-mail válido!'))
                }

                return Promise.resolve()
              }

              return Promise.resolve()
            },
          }),
        ]}
      >
        <Select
          mode="tags"
          placeholder="E-mails"
          size="large"
          tagRender={(props) => {
            if (!props.value) return <></>

            return <UserTag userInitial={getStringInitials(props.value, 1)} {...props} />
          }}
          showArrow={false}
          notFoundContent={null}
          value={externalUsersValeu}
          onChange={(v) => setExternalUsersValeus(v)}
        />
      </Form.Item>

      <Form.Item label={labels.email_message} name="mensagem_email">
        <Input.TextArea
          rows={4}
          disabled={systemUsersValeu?.length === 0 && externalUsersValeu?.length === 0}
        />
      </Form.Item>
    </S.Content>
  )
}

export { ThirdStep }
