import { useMutation, useQueryClient } from '@tanstack/react-query'

import { userKeys } from '../../../queries'

import { useSignOut, type User } from '@/common'
import { getUserEmail } from '@/features/auth'
import { api } from '@/services'

const useDeleteUserMutation = () => {
  const queryClient = useQueryClient()
  const { signOut } = useSignOut()

  return useMutation(
    async (username: string) => {
      return api.delete(`/user/${username}`)
    },
    {
      onMutate: async (username) => {
        await queryClient.cancelQueries(userKeys.all)

        const previousUsers = queryClient.getQueryData<User[]>(userKeys.list())

        if (previousUsers) {
          const updatedUsers = previousUsers.filter((user) => user.username !== username)

          queryClient.setQueryData(userKeys.list(), updatedUsers)
        }

        return previousUsers // context for rollback
      },
      onSuccess: (_, username) => {
        const email = getUserEmail()

        if (email === username) {
          signOut()
        }
      },
      onError: (error, _, previousUsers) => {
        console.log(error)

        if (previousUsers) {
          queryClient.setQueryData(userKeys.list(), previousUsers)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(userKeys.all)
      },
    },
  )
}

export { useDeleteUserMutation }
