import jwtDecode from 'jwt-decode'

import { authStore } from '@/app'
import { IdTokenType } from '@/common'

export const getUserEmail = () => {
  try {
    const token = authStore.getState().idToken

    if (token) {
      const email = jwtDecode<IdTokenType>(token).email

      return email
    }

    return undefined
  } catch (error) {
    console.log(error)
    return undefined
  }
}
