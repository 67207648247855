import toast from 'react-hot-toast'

import { useMutation } from '@tanstack/react-query'

import { ApiError } from '@/common'
import { api } from '@/services'

type ForgotPasswordMutation = {
  email: string
  setCodeSent: React.Dispatch<React.SetStateAction<boolean>>
}

const useForgotPasswordMutation = () => {
  return useMutation(
    async ({ email }: ForgotPasswordMutation) => {
      return api.post<{ message: string }>('/auth/forgot_password', { email })
    },
    {
      onSuccess: (res, props) => {
        if (res.data.message === 'User does not exist.') {
          return toast.error('Usuario não existe.')
        }

        props.setCodeSent(true)
        toast.success('Codigo de redifinição de senha enviado com sucesso!')
      },
      onError: (error: ApiError<string>) => {
        if (error.response?.data.message === 'User does not exist.') {
          return toast.error('Usuario não existe.')
        }

        toast.error(error.message)
      },
    },
  )
}

export { useForgotPasswordMutation }
