import { useMutation, useQueryClient } from '@tanstack/react-query'

import { notificationKeys } from '../../queries'

import { api } from '@/services'

type DeleteNotificationMutation = {
  notifications: string[]
  multiple?: boolean
}

const useDeleteNotificationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ notifications, multiple }: DeleteNotificationMutation) => {
      console.log({ notifications, multiple })

      const notificationId = notifications[0]
      let params = ''

      if (multiple) {
        params = `other_ids=${notifications.join(',')}`
      }

      return api.delete(`/notifications/${notificationId}?${params}`)
    },
    {
      onMutate: async () => await queryClient.cancelQueries(notificationKeys.all),
      onSettled: () => queryClient.invalidateQueries(notificationKeys.all),
    },
  )
}

export { useDeleteNotificationMutation }
