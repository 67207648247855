import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(tz)

function formatDate(date: string | undefined) {
  if (!date) return ''

  return dayjs(date).tz('America/Sao_Paulo', true).subtract(3, 'hour').format('DD/MM/YYYY HH:mm')
}

export { formatDate }
