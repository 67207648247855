import { ProductDetails } from '../../../types'
import { Container } from './styles'

type ProductInformationTableProps = {
  product: ProductDetails
}

const ProductInformationTable = ({ product }: ProductInformationTableProps) => {
  return (
    <Container>
      <tbody>
        <tr>
          <td className="title">Marca:</td>
          <td className="info">{product?.marca}</td>

          <td className="title">Referência:</td>
          <td className="info">{product?.informacoes.referencia}</td>
        </tr>

        <tr>
          <td className="title">CPU:</td>
          <td className="info">{product?.informacoes.processador}</td>

          <td className="title">Velocidade da CPU:</td>
          <td className="info">{product?.informacoes.velocidade_processaor}</td>
        </tr>

        <tr>
          <td className="title">Sistema Operacional:</td>
          <td className="info">{product?.informacoes.sistema_operacional}</td>

          <td className="title">Memoria RAM:</td>
          <td className="info">{product?.informacoes.memoria_ram}</td>
        </tr>

        <tr>
          <td className="title">Armazenamento:</td>
          <td className="info">{product?.informacoes.tipo_hd}</td>

          <td className="title">Capacidade:</td>
          <td className="info">{product?.informacoes.hd}</td>
        </tr>

        <tr>
          <td className="title">GPU:</td>
          <td className="info">{product?.informacoes.placa_de_video}</td>

          <td className="title">Tipo de GPU:</td>
          <td className="info">{product?.informacoes.gpu}</td>
        </tr>

        <tr>
          <td className="title">Tela:</td>
          <td className="info">{product?.informacoes.tela}</td>

          <td className="title">Resolução da Tela:</td>
          <td className="info">{product?.informacoes.resolucao_tela}</td>
        </tr>
      </tbody>
    </Container>
  )
}

export { ProductInformationTable }
