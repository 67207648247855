interface GetDecimalsString {
  value: number
  shouldDivideBy100?: boolean
}

export function getDecimalsString({ value, shouldDivideBy100 = true }: GetDecimalsString) {
  if (shouldDivideBy100) return (value / 100).toFixed(2)

  return value.toFixed(2)
}
