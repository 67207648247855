import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { NotificationType, WebsocketNotificationConfig } from '@/features/notifications'

type NotificationStoreState = {
  newNotifications: NotificationType<WebsocketNotificationConfig>[]

  setNotifications: (notifications: NotificationType<WebsocketNotificationConfig>[]) => void
  setNewNotifications: (notifications: NotificationType<WebsocketNotificationConfig>[]) => void
  viewNotifications: () => void
  clearNotifications: () => void
}

const notificationStore = create<NotificationStoreState>()(
  devtools(
    (set, get) => ({
      newNotifications: [],

      setNotifications: (notifications) => {
        set({ newNotifications: [...notifications] }, false, 'set-notifications')
      },

      setNewNotifications: (notifications) => {
        set(
          { newNotifications: [...notifications, ...get().newNotifications] },
          false,
          'set-new-notifications',
        )
      },

      viewNotifications: () => {
        const viewedNotifications = get().newNotifications.map((notification) => ({
          ...notification,
          viewed: true,
        }))

        set({ newNotifications: viewedNotifications }, false, 'view-notifications')
      },

      clearNotifications: () => {
        set({ newNotifications: [] }, false, 'clear-new-notifications')
      },
    }),
    {
      name: 'notification-store',
    },
  ),
)

export const setNotifications = notificationStore.getState().setNotifications
export const setNewNotifications = notificationStore.getState().setNewNotifications
export const clearNotifications = notificationStore.getState().clearNotifications
export const viewNotifications = notificationStore.getState().viewNotifications

export default notificationStore
