import { useEffect, useMemo, useReducer, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineSave } from 'react-icons/ai'

import {
  DownloadOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  RadarChartOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Dropdown, Space } from 'antd'
import { Key, RowSelectMethod } from 'antd/es/table/interface'
import isEqual from 'lodash.isequal'

import {
  Title,
  TotalProducts,
  TotalProductsValue,
  Divider,
  ActiveFiltersArea,
  HeaderContainer,
  HeaderActions,
  CategorizeButton,
  HeaderText,
  CheckAllButton,
  SaveButton,
  FilterContent,
  Preset,
  ReportButton,
} from './styles'

import { filterStore as useFilterStore } from '@/app'
import { formatAmounts, useToggle, useUserGroup } from '@/common'
import { CustomPagination, Loading } from '@/components'
import { NotificationModal } from '@/features/notifications'
import {
  usePagination,
  useProducts,
  ProductFilters,
  ProductsListTable,
  productKeys,
  fetchProducts,
  ActiveFiltersDropdown,
  ShareProductModal,
  AddTagModal,
  CategoryModal,
  useUpdatePresetMutation,
  SimilarProductsModal,
  useDownloadReportMutation,
  ProductReviewModal,
} from '@/features/product'
import type { Product, Products, ReportType } from '@/features/product'
import { PageLayout } from '@/layouts'

const itemsPerPage = 30

export type HandleCheckedProductsType = {
  selectedRowKeys: React.Key[]
  selectedRows: Product[]
  info: { type: RowSelectMethod }
}

export type SelectProductActions = {
  productId: string
  type:
    | 'SHARE_PRODUCT'
    | 'ADD_CATEGORY'
    | 'ADD_TAG'
    | 'CREATE_NOTIFICATION'
    | 'SIMILAR_PRODUCTS'
    | 'PRODUCT_REVIEW'
}

const ProductList = () => {
  const { isAdmin } = useUserGroup()

  const activeFilters = useFilterStore((s) => s.activeFilters)
  const activeSorting = useFilterStore((s) => s.activeSorting)
  const lastActivePreset = useFilterStore((s) => s.lastActivePreset)

  const queryClient = useQueryClient()
  const [currentPage, setCurrentPage] = usePagination()

  const { data: products, isLoading, isFetching } = useProducts(currentPage)

  const updatePresetMutation = useUpdatePresetMutation()
  const downloadReportMutation = useDownloadReportMutation()

  const [isEmailModalOpen, toggleEmailModal] = useToggle(false)
  const [isTagModalOpen, toggleTagModal] = useToggle(false)
  const [isCategoryModalOpen, toggleCategoryModal] = useToggle(false)
  const [isNotificationModalOpen, toggleNotificationModal] = useToggle(false)
  const [isSimilarProductsModalOpen, toggleSimilarProductsModal] = useToggle(false)
  const [isProductReviewModalOpen, toogleProductReviewModal] = useToggle(false)

  const [checkedProducts, setCheckedProducts] = useState<Key[]>([])

  // const isPresetFiltersActive = isEqual(activeFilters, lastActivePreset?.filters)
  const isPresetFiltersActive = useMemo(() => {
    if (lastActivePreset?.filters) {
      return isEqual(activeFilters, lastActivePreset.filters)
    }

    return false
  }, [activeFilters, lastActivePreset?.filters])

  const [selectedProduct, selectProductDispatch] = useReducer(
    (state: string, action: SelectProductActions) => {
      switch (action.type) {
        case 'ADD_CATEGORY':
          toggleCategoryModal()
          return action.productId

        case 'SHARE_PRODUCT':
          toggleEmailModal()
          return action.productId

        case 'ADD_TAG':
          toggleTagModal()
          return action.productId

        case 'CREATE_NOTIFICATION':
          toggleNotificationModal()
          return action.productId

        case 'SIMILAR_PRODUCTS':
          toggleSimilarProductsModal()
          return action.productId

        case 'PRODUCT_REVIEW':
          toogleProductReviewModal()
          return action.productId

        default:
          return ''
      }
    },
    '',
  )

  const reportOptions = [
    {
      key: 'listagem',
      label: (
        <ReportButton
          icon={<UnorderedListOutlined />}
          type="link"
          onClick={() => handleDownloadReport('listagem')}
        >
          Listagem atual
        </ReportButton>
      ),
    },
    {
      key: 'completo',
      label: (
        <ReportButton
          icon={<MenuUnfoldOutlined />}
          type="link"
          onClick={() => handleDownloadReport('completo')}
        >
          Completo
        </ReportButton>
      ),
    },
    {
      key: 'alcance',
      label: (
        <ReportButton
          icon={<RadarChartOutlined />}
          type="link"
          onClick={() => handleDownloadReport('alcance')}
        >
          Alcance
        </ReportButton>
      ),
    },
    {
      key: 'seller',
      label: (
        <ReportButton
          icon={<ShoppingOutlined />}
          type="link"
          onClick={() => handleDownloadReport('seller')}
        >
          Seller
        </ReportButton>
      ),
    },
  ]

  useEffect(() => {
    const hasNextPage = products && currentPage < products?.info.pages

    if (hasNextPage) {
      queryClient.prefetchQuery(
        productKeys.list(currentPage + 1, activeFilters, activeSorting),
        fetchProducts,
      )
    }
  }, [activeFilters, activeSorting, currentPage, products, queryClient])

  function handleCheckedProducts({ selectedRowKeys }: HandleCheckedProductsType) {
    setCheckedProducts(selectedRowKeys)
  }

  function handleCheckAll() {
    if (checkedProducts.length === itemsPerPage) {
      return handleCheckedProducts({
        selectedRowKeys: [],
        selectedRows: [],
        info: { type: 'all' },
      })
    }

    const allProducts = products?.results.map((product) => product.id) as React.Key[]
    handleCheckedProducts({
      selectedRowKeys: allProducts,
      selectedRows: products?.results as Product[],
      info: { type: 'all' },
    })
  }

  function handleClearCheckedProducts() {
    setCheckedProducts([])
  }

  async function handleUpdatePreset() {
    if (!lastActivePreset) return

    const promise = updatePresetMutation.mutateAsync({
      presetId: lastActivePreset.id,
      filters: activeFilters,
    })

    await toast.promise(promise, {
      loading: 'Atualizando atalho...',
      success: 'Atalho atualizado com sucesso!',
      error: 'Ocorreu um erro ao atualizar o atalho.',
    })
  }

  async function handleDownloadReport(reportType: ReportType) {
    downloadReportMutation.mutate(reportType)
  }

  const PageHeader = () => {
    return (
      <HeaderContainer>
        <Space style={{ minHeight: '25px' }}>
          <Title>Listagem de Produtos</Title>
          <Divider thickness={2} type="vertical" />
          {products && (
            <>
              <TotalProductsValue>{formatAmounts(products?.info.total)}</TotalProductsValue>
              <TotalProducts>
                {products.info.total > 1 ? `encontrados` : `encontrado`}
              </TotalProducts>
            </>
          )}
          {(isLoading || isFetching) && <Loading />}
        </Space>

        {isAdmin && products && products.results.length > 0 && (
          <HeaderActions>
            <Space style={{ minHeight: '25px' }}>
              <Space size="small">
                <HeaderText>Selecionados:</HeaderText>
                <TotalProductsValue>{checkedProducts.length}</TotalProductsValue>
              </Space>

              <Divider color="#9D9D9D" type="vertical" />

              <CheckAllButton type="link" onClick={handleCheckAll} disabled={isLoading}>
                {checkedProducts.length === itemsPerPage
                  ? 'Deselecionar todos'
                  : 'Selecionar todos'}
              </CheckAllButton>
            </Space>

            {checkedProducts.length > 0 && (
              <CategorizeButton icon={<OrderedListOutlined />} onClick={toggleCategoryModal}>
                Categorizar Produtos
              </CategorizeButton>
            )}

            <Dropdown menu={{ items: reportOptions }} trigger={['click']} placement="bottom" arrow>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                loading={downloadReportMutation.isLoading}
              >
                Relatórios
              </Button>
            </Dropdown>
          </HeaderActions>
        )}
      </HeaderContainer>
    )
  }

  return (
    <PageLayout sidebar={<ProductFilters />}>
      {isAdmin && isCategoryModalOpen && (
        <CategoryModal
          isOpen={isCategoryModalOpen}
          closeModal={() => selectProductDispatch({ type: 'ADD_CATEGORY', productId: '' })}
          productId={selectedProduct}
          products={checkedProducts}
          clearCheckedProducts={handleClearCheckedProducts}
        />
      )}

      {isAdmin && isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          closeModal={() => selectProductDispatch({ type: 'CREATE_NOTIFICATION', productId: '' })}
          productId={selectedProduct}
        />
      )}

      {isEmailModalOpen && (
        <ShareProductModal
          closeModal={() => selectProductDispatch({ type: 'SHARE_PRODUCT', productId: '' })}
          isOpen={isEmailModalOpen}
          productId={selectedProduct}
        />
      )}

      {isTagModalOpen && (
        <AddTagModal
          isOpen={isTagModalOpen}
          closeModal={() => selectProductDispatch({ type: 'ADD_TAG', productId: '' })}
          productId={selectedProduct}
        />
      )}

      {isSimilarProductsModalOpen && (
        <SimilarProductsModal
          isOpen={isSimilarProductsModalOpen}
          closeModal={() => selectProductDispatch({ type: 'SIMILAR_PRODUCTS', productId: '' })}
          productId={selectedProduct}
          navigateToProduct
        />
      )}

      {isProductReviewModalOpen && (
        <ProductReviewModal
          isOpen={isProductReviewModalOpen}
          closeModal={() => selectProductDispatch({ type: 'PRODUCT_REVIEW', productId: '' })}
          productId={selectedProduct}
          navigateToProduct
        />
      )}

      <PageHeader />

      <FilterContent>
        <ActiveFiltersArea>
          {Object.entries(activeFilters)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value]) => {
              return <ActiveFiltersDropdown key={key} filter={key} value={value} />
            })}
        </ActiveFiltersArea>

        {lastActivePreset?.filters && !isPresetFiltersActive ? (
          <SaveButton type="link" icon={<AiOutlineSave />} onClick={handleUpdatePreset}>
            Salvar no atalho de filtro: <Preset>{lastActivePreset.name}</Preset>
          </SaveButton>
        ) : null}
      </FilterContent>

      <ProductsListTable
        data={products as Products}
        isLoading={isLoading}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        handleSelectedProduct={selectProductDispatch}
        checkedProducts={checkedProducts}
        handleCheckedProducts={handleCheckedProducts}
      />

      {/* {products && (
        <OldCustomPagination
          itemsPerPage={itemsPerPage} //total de resultados
          page={currentPage} //página atual
          total={products?.info?.total || 0} //total de produtos
          totalPages={products?.info?.pages || 0} //total de páginas
          updatePage={setCurrentPage}
          scrollToTop={true}
        />
      )} */}

      <CustomPagination
        scrollToTop
        page={currentPage}
        pageSize={itemsPerPage}
        total={products?.info?.total}
        totalPages={products?.info?.pages as number}
        isLoading={isLoading}
        updatePage={setCurrentPage}
      />
    </PageLayout>
  )
}

export { ProductList }
