import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Divider, Image, Skeleton } from 'antd'

import * as S from './styles'

import { formatDate } from '@/common'
import { Modal } from '@/components'
import { useProduct } from '@/features/product/queries'

type ProductReviewProps = {
  isOpen: boolean
  closeModal: () => void
  productId: string
  navigateToProduct?: boolean
}

const SkeletonPage = () => {
  return (
    <>
      <S.SkeletonFirstContainer>
        <S.SkeletonInformationArea>
          <Skeleton.Image active />

          <S.SkeletonText>
            <S.SkeletonTitle>
              <Skeleton.Input active size="small" block style={{ width: '75%' }} />
              <Skeleton.Input active size="small" block style={{ width: '75%' }} />
            </S.SkeletonTitle>

            <Skeleton.Input active size="large" style={{ width: '22%' }} />
          </S.SkeletonText>

          {/* <S.ShareButton icon={<ShareAltOutlined />} type="link" disabled>
            Compartilhar
          </S.ShareButton> */}
        </S.SkeletonInformationArea>
      </S.SkeletonFirstContainer>

      <Divider />

      <S.SkeletonSecondContainer>
        <S.SkeletonSecondTitle>
          <Skeleton.Input active size="small" block />
          <Skeleton.Input active size="small" block />
        </S.SkeletonSecondTitle>

        <S.SkeletonSecondInformation>
          <Skeleton.Image active />

          <S.SkeletonReviewArea>
            <Skeleton.Input active size="small" style={{ width: '22%' }} />

            <Skeleton.Input active size="small" style={{ width: '65%' }} />

            <Skeleton.Input active size="small" style={{ width: '90%' }} />
            <Skeleton.Input active size="small" style={{ width: '90%' }} />

            <S.SkeletonLink>
              <Skeleton.Input active size="small" style={{ width: '30%' }} />
              <Skeleton.Input active size="small" style={{ width: '80%' }} />
              <Skeleton.Input active size="small" style={{ width: '80%' }} />
            </S.SkeletonLink>
          </S.SkeletonReviewArea>
        </S.SkeletonSecondInformation>
      </S.SkeletonSecondContainer>
    </>
  )
}

const ProductReviewModal = ({
  isOpen,
  closeModal,
  productId,
  navigateToProduct = false,
}: ProductReviewProps) => {
  const { data: product, isLoading: isProductLoading } = useProduct(productId)

  const navigate = useNavigate()

  return (
    <>
      <Modal
        title={`Review do produto`}
        open={isOpen}
        onCancel={closeModal}
        onOk={closeModal}
        width={880}
        footer={
          <>
            <S.NoButton />

            <S.FinishButton type="primary" onClick={closeModal}>
              Finalizar
            </S.FinishButton>
          </>
        }
      >
        {isProductLoading ? (
          <SkeletonPage />
        ) : (
          <>
            <S.FirstContainer>
              <S.DescriptionArea>
                <Image
                  style={{ objectFit: 'contain' }}
                  fallback="/src/assets/fallback-image.png"
                  src={product?.informacoes.url_imagem}
                  alt={product?.modelo}
                  width={138}
                  height={138}
                  preview={false}
                />

                <S.InfoArea>
                  <S.Title>{product?.modelo}</S.Title>

                  {navigateToProduct && (
                    <S.ProductButton
                      type="primary"
                      onClick={() => navigate(`/produtos/${product?.id}`)}
                    >
                      Ver produto
                    </S.ProductButton>
                  )}
                </S.InfoArea>

                {/* <S.ShareButton icon={<ShareAltOutlined />} type="link">
                  Compartilhar
                </S.ShareButton> */}
              </S.DescriptionArea>
            </S.FirstContainer>

            <S.Divider />

            <S.SecondContainer $isRenderingList={(product?.review.length as number) > 1}>
              <S.ProductTitleArea>
                <S.SimilarProductText>
                  Reviews | <S.FindValue>{product?.review.length}</S.FindValue> encontrados
                </S.SimilarProductText>

                {/* <S.DropdownArea>
                  <Typography.Text>Filtrar por:</Typography.Text>

                  <S.Select
                    defaultValue="Em qualquer data"
                    style={{ width: 174 }}
                    // onChange={handleChange}
                    options={[
                      { value: 'qualquer_data', label: 'Em qualquer data' },
                      { value: '24_horas', label: 'Últimas 24 horas' },
                      { value: 'ultima_semana', label: 'última semana' },
                      { value: 'ultimo_mes', label: 'Último mês' },
                      { value: 'ultimo_ano', label: 'Último ano' },
                    ]}
                  />
                </S.DropdownArea> */}
              </S.ProductTitleArea>

              <S.ReviewsTitle>
                {(product?.review.length as number) > 1
                  ? 'Links de reviews sobre o produto:'
                  : 'Link de review sobre o produto:'}
              </S.ReviewsTitle>

              <S.ProductArea>
                {product?.review.map((productReviews, i) => {
                  const shouldRenderDivider = i !== product.review.length - 1

                  return (
                    <React.Fragment key={i}>
                      <S.Card>
                        <S.ProductDescriptionArea>
                          <S.FirstArea>
                            <S.Title2>{productReviews.origem}</S.Title2>

                            <S.Data>{formatDate(productReviews.created_at)}</S.Data>
                          </S.FirstArea>

                          {/* <S.ReviewName>
                              Review Acer Nitro 5: notebook se destaca pelo bom desempenho com games
                            </S.ReviewName> */}

                          {productReviews.descricao && (
                            <S.ReviewDescription>{productReviews.descricao}</S.ReviewDescription>
                          )}

                          <S.LinkArea>
                            <S.ProductLink $isDark={true} to={productReviews.url} target="_blank">
                              {productReviews.url}
                            </S.ProductLink>
                          </S.LinkArea>
                        </S.ProductDescriptionArea>
                      </S.Card>

                      {shouldRenderDivider && <Divider />}
                    </React.Fragment>
                  )
                })}
              </S.ProductArea>
            </S.SecondContainer>
          </>
        )}
      </Modal>
    </>
  )
}

export { ProductReviewModal }
