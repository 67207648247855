import { Typography } from 'antd'
import styled from 'styled-components'

export const Percentage = styled(Typography.Text)<{ $isPositive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isPositive }) => ($isPositive ? '#83b81a' : '#ff4d4d')};
`

export const TableContainer = styled.div`
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 8px 8px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background-color: rgba(240, 242, 245, 0.5);
  }

  .light-row {
    background-color: #ffffff;
  }

  .dark-row {
    background-color: rgba(240, 242, 245, 0.5);
  }
`
