import { render } from '@react-email/components'
import { useMutation } from '@tanstack/react-query'

import { ProductEmail } from '../../../components'
import { getProduct } from '../../../queries'

import { api } from '@/services'

type ShareEmailMutation = {
  productId: string
  emails: string[]
}

const useShareEmailMutation = () => {
  return useMutation(async ({ productId, emails }: ShareEmailMutation) => {
    const product = await getProduct(productId)

    if (!product) throw new Error()

    const html = render(
      <ProductEmail product={product} marketplaces={product?.marketplaces?.results} />,
      {
        pretty: true,
      },
    )

    return api.post('/produto/email', {
      html,
      emails,
    })
  })
}

export { useShareEmailMutation }
