import { Card, Skeleton, Tabs as AntdTabs, Typography } from 'antd'
import styled from 'styled-components'

export const SmallCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
  min-width: 130px;
`

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const CardLoading = styled(Skeleton.Avatar)`
  width: 100%;
  height: 136px;
  padding: 0px;

  .ant-skeleton-avatar {
    height: 100% !important;
    width: 100% !important;
    border-radius: 5px;
  }
`

export const DashboardCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin: 0;
  padding: 12px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 12px;
  }

  h2 {
    font-size: 32px;
    color: #80c343;
    cursor: pointer;

    font-weight: 400;
    margin-bottom: 0;
    line-height: inherit;

    transition: all 0.2s ease;

    :hover {
      text-decoration: underline;
      filter: brightness(0.8);
    }
  }
`

export const TypographyCard = styled(Typography)`
  color: #434343;
  font-weight: 600;
`

export const ByBrandsGraphLoading = styled(Skeleton.Avatar)`
  width: 100%;
  height: 496px;
  padding: 0px;

  .ant-skeleton-avatar {
    height: 100% !important;
    width: 100% !important;
    border-radius: 5px;
  }
`

export const ByBrandsGraphCard = styled(Card)`
  height: 496px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 300px;
  height: 100%;
  border: 1px solid #ededed;
  border-radius: 5px;

  .ant-card-body {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
  }

  .view {
    color: #434343;
    font-weight: 600;
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3.ant-typography {
      color: #434343;
      font-size: 16px;
      span {
        color: #a2a2a2;
      }
    }

    .ant-btn.ant-btn-link {
      font-size: 14px;
      font-weight: 500;
      margin-right: -15px;
      color: #80c343;
    }
  }
`

export const ByMarketplaceGraphLoading = styled(Skeleton.Avatar)`
  width: 100%;
  height: 100%;
  padding: 0px;

  .ant-skeleton-avatar {
    height: 100% !important;
    width: 100% !important;
    border-radius: 5px;
  }
`

export const ByMarketplaceGraphCard = styled(Card)`
  height: 456px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #ededed;
  border-radius: 5px;

  .ant-card-body {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
  }

  .view {
    color: #434343;
    font-weight: 500;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3.ant-typography {
      color: #434343;
      font-size: 16px;

      span {
        color: #a2a2a2;
      }
    }

    .ant-btn.ant-btn-link {
      font-size: 14px;
      font-weight: 600;
      margin-right: -15px;
      color: #80c343;
    }
  }
`

export const RankingTableCard = styled(Card)`
  height: 100%;

  border: 1px solid #ededed;
  border-radius: 5px;

  .ant-card-body {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 16px;
  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 8px 8px 8px 0px;
    border: none;

    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    border-bottom: 6px solid transparent;
  }

  /* .ant-table-content {
    max-height: 410px;
    overflow-y: auto;
    overflow-x: hidden;
  } */
`

export const RankingCardLoading = styled(Skeleton.Avatar)`
  width: 100%;
  height: 496px;
  padding: 0px;

  .ant-skeleton-avatar {
    height: 100% !important;
    width: 100% !important;
    border-radius: 5px;
  }
`

export const Tabs = styled(AntdTabs)`
  color: #80c343;
  margin-right: 8px;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #434343;
  }

  .ant-tabs-ink-bar {
    background: #434343;
  }

  .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-tab-btn:focus {
    color: #434343;
  }

  .ant-tabs-tab:hover {
    color: #80c343;

    transition: all 0.2s ease;
    filter: brightness(0.8);
  }
`

export const RakingCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-typography {
    margin-bottom: 0;
  }

  .ant-select .ant-select-arrow .anticon > svg {
    color: #5ea320;
    font-size: initial;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-weight: 700;
    color: #434343;
  }

  .ant-select-selector {
    border-radius: 0;
  }
`

export const FilterContainer = styled.div`
  .ant-picker {
    margin: 0;
    width: 0;
    padding: 0;
    visibility: hidden;
    transform: translate(-20.5em, 0);
  }
`
