import toast from 'react-hot-toast'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'

import { useAutoAnimateRef } from '../../../hooks'
import { useShareEmailMutation } from '../../../mutations'
import * as S from './styles'

import { Modal } from '@/components'

type ShareProductModalProps = {
  isOpen: boolean
  closeModal: () => void
  productId: string
}

const ShareProductModal = ({ isOpen, closeModal, productId }: ShareProductModalProps) => {
  const [form] = Form.useForm()
  const [animateParentRef] = useAutoAnimateRef()
  const shareProductMutation = useShareEmailMutation()

  function handleCancel() {
    closeModal()
    form.resetFields()
  }

  async function handleSubmit(values: { emails: string[] }) {
    try {
      const promise = shareProductMutation.mutateAsync({ productId, emails: values.emails })

      await toast.promise(promise, {
        loading: 'Enviando...',
        success: 'Produto compartilhado com sucesso!',
        error: 'Erro ao compartilhar produto',
      })

      handleCancel()
    } catch {
      handleCancel()
    }
  }

  return (
    <Modal
      title="Compartilhar produto"
      open={isOpen}
      onCancel={closeModal}
      onOk={() => form.submit()}
      footer={
        <>
          <S.CancelButton onClick={handleCancel}>Cancelar</S.CancelButton>

          <S.SendButton type="primary" onClick={() => form.submit()}>
            Enviar
          </S.SendButton>
        </>
      }
    >
      <S.Content>
        <S.Text>Com quem você deseja compartilhar o produto?</S.Text>

        <S.FormContainer>
          <Form
            onFinish={(emails) => handleSubmit(emails)}
            form={form}
            disabled={shareProductMutation.isLoading}
          >
            <Form.List initialValue={['']} name="emails">
              {(fields, { add, remove }) => {
                return (
                  <S.ListContainer ref={animateParentRef}>
                    {fields.map((field, index) => (
                      <S.Item key={field.key} className="item">
                        <Form.Item
                          required
                          key={field.key}
                          validateTrigger={['onChange', 'onBlur']}
                        >
                          <Form.Item
                            {...field}
                            rules={[
                              {
                                required: true,
                                type: 'email',
                                message: 'Digite um e-mail válido.',
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder={`Email ${index + 1}`}
                              type="email"
                              autoComplete="off"
                              required
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <Button
                              type="text"
                              onClick={() => remove(field.name)}
                              icon={<S.RemoveIcon />}
                            />
                          ) : null}
                        </Form.Item>
                      </S.Item>
                    ))}
                    <S.AddItemButton type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                      Adicionar outro email
                    </S.AddItemButton>
                  </S.ListContainer>
                )
              }}
            </Form.List>
          </Form>
        </S.FormContainer>
      </S.Content>
    </Modal>
  )
}

export { ShareProductModal }
