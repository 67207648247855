const accentsMap: Record<string, string> = {
  a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
  e: 'é|è|ê|É|È|Ê',
  i: 'í|ì|î|Í|Ì|Î',
  o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
  u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
  c: 'ç|Ç',
  n: 'ñ|Ñ',
}

const sanitizeText = (text: string) => {
  const reducer = (acc: string, letter: string) =>
    acc.replace(new RegExp(accentsMap[letter], 'g'), letter)

  const removedAccents = Object.keys(accentsMap).reduce(reducer, text)

  const sanitizedText = removedAccents.toLowerCase()

  return sanitizedText
}

export { sanitizeText }
