import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { SimilarProducts } from '../../types'
import { productKeys } from '../query-keys'

import { api } from '@/services'

async function fetchSimilarProducts({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof productKeys)['similar']>>) {
  const [, , productId] = queryKey

  const { data } = await api.get<SimilarProducts[]>(`/produto/${productId}/similares`)

  return data
}

const useSimilarProducts = (productId: string) => {
  return useQuery(productKeys.similar(productId), fetchSimilarProducts)
}

export { useSimilarProducts }
