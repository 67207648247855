import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getUserEmail } from '../../utils'

import { api } from '@/services'

type ChangePasswordMutation = {
  email: string
  oldPassword: string
  newPassword: string
}

const useChangePasswordMutation = () => {
  const navigate = useNavigate()
  const userEmail = getUserEmail()

  return useMutation(
    async ({ oldPassword, newPassword }: ChangePasswordMutation) => {
      return api.post('/auth/reset_password', {
        email: userEmail,
        old_password: oldPassword,
        new_password: newPassword,
      })
    },
    {
      onSuccess: () => {
        toast.success('Senha trocada com sucesso!')
        navigate('/')
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 403) {
          return toast.error('Email ou senha incorretos')
        }

        toast.error('Algo deu errado')
        console.log(error)
      },
    },
  )
}

export { useChangePasswordMutation }
