import toast from 'react-hot-toast'

import { useMutation } from '@tanstack/react-query'

import { useDismissableToast } from '../../hooks'
import { ReportType } from '../../types'

import { filterStore as useFilterStore } from '@/app'
import { DismissibleToast } from '@/components'
import { api } from '@/services'

export const useDownloadReportMutation = () => {
  const activeFilters = useFilterStore((s) => s.activeFilters)
  const activeSorting = useFilterStore((s) => s.activeSorting)
  const [_dismissableToastId, setDismissibleToastId] = useDismissableToast()

  return useMutation(
    async (reportType: ReportType) => {
      if (reportType === 'listagem') {
        const hasSorting = Object.keys(activeSorting).length

        const payload = {
          filtros: activeFilters,
          ...(hasSorting && { ordenacao: activeSorting }), // only send sorting if it has a value
        }

        return api.post(`/reports?report=${reportType}`, payload)
      }

      return api.post(`/reports?report=${reportType}`)
    },
    {
      // onSuccess: ({ data }) => toast.success(data.message),
      onSuccess: ({ data }) => {
        const toastId = DismissibleToast({ text: data.message })
        // const toastId = toast.loading(data.message)

        setDismissibleToastId(toastId)
      },
      onError: (error) => {
        console.error(error)

        toast.error('Falha ao baixar relatório.')
      },
    },
  )
}
