import { useQuery } from '@tanstack/react-query'

import type { PresetFilter } from '../../types'
import { filterKeys } from '../query-keys'

import { api } from '@/services'

async function fetchPresetFilters() {
  const { data } = await api.get<PresetFilter[]>('/presetfilter')

  return data
}

const usePresetFilters = () => useQuery(filterKeys.presets(), fetchPresetFilters)

export { usePresetFilters }
