import { Navigate, Outlet } from 'react-router-dom'

import { authStore as useAuthStore } from '@/app'
import { useSignOut } from '@/common'
import { useUserGroup } from '@/common/hooks/useUserGroup'

type PrivateRouteProps = {
  allowedUsers?: string[]
}

const PrivateRoute = ({ allowedUsers }: PrivateRouteProps) => {
  const { userGroup } = useUserGroup()
  const isAuthenticated = useAuthStore((s) => s.isUserAuthenticated)
  const { signOut } = useSignOut()

  if (allowedUsers && isAuthenticated) {
    if (!userGroup) {
      signOut()
      return <Navigate to="/auth/login" replace />
    }

    const isAllowed = allowedUsers.includes(userGroup)

    if (!isAllowed) {
      return <Navigate to="/" replace />
    }

    return <Outlet />
  }

  if (isAuthenticated) {
    return <Outlet />
  }

  return <Navigate to="/auth/login" replace />
}

export { PrivateRoute }
