import { Typography } from 'antd'

import { ErrorContainer, ExclamationIcon } from './styles'

const ErrorMessage = ({
  message = 'Informação temporariamente indisponível.',
}: {
  message?: string
}) => (
  <ErrorContainer>
    <ExclamationIcon />
    <Typography>
      {message}
      <br />
      Tente novamente mais tarde.
    </Typography>
  </ErrorContainer>
)

export { ErrorMessage }
