import { AiOutlineClose } from 'react-icons/ai'
import { BsBellFill } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'

import { MenuOutlined } from '@ant-design/icons'
import { Layout, Button, Typography, Drawer as DrawerAntd } from 'antd'
import MenuItem from 'antd/es/menu/MenuItem'
import styled, { css } from 'styled-components'

import { hexToRgba } from '@/common'

export const Container = styled(Layout.Header)`
  height: 50px;
  padding: 0 12px;
  background-color: ${({ theme }) => theme.colors.brand};

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    cursor: pointer;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-typography {
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    cursor: default;
  }
`

export const MenuButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;

  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  &:hover,
  :not(:disabled):hover {
    color: ${({ theme }) => theme.colors.white};
  }

  &.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const NotificationButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0 8px;

  background-color: transparent;
  border: transparent;
  box-shadow: none;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

export const NavigationLink = styled(NavLink)`
  display: block;
  padding: 8px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  border-radius: 4px;
  background: transparent;
  width: 100%;
  transition: all 0.2s ease;

  &.logo {
    width: fit-content;
  }
`

export const MenuItems = styled(MenuItem)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.brand};

  &:hover {
    color: ${({ theme }) => theme.colors.white} !important;
    background: ${({ theme }) => hexToRgba(theme.colors.brand)} !important;
  }

  &.ant-menu-item.ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => hexToRgba(theme.colors.brand)};

    font-weight: 700;
  }

  span.ant-menu-title-content {
    transition: 0s;
  }
`

export const MenuContent = styled.div`
  .ant-menu-submenu-title {
    color: ${({ theme }) => theme.colors.brand};
    font-size: 16px;

    :hover {
      color: ${({ theme }) => theme.colors.white} !important;
      background: ${({ theme }) => hexToRgba(theme.colors.brand)} !important;
    }
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

export const MenuIcon = styled(MenuOutlined)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
`

export const MenuDrawer = styled(DrawerAntd)`
  .ant-drawer-footer {
    display: flex;
    justify-content: end;
  }

  .ant-btn-default {
    color: ${({ theme }) => theme.colors.brand};
    border: 1px solid ${({ theme }) => theme.colors.brand};
    border-radius: 0;

    :hover {
      color: #fff;
      background-color: ${({ theme }) => theme.colors.brand};
    }
  }

  .ant-drawer-extra {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const NotificationIcon = styled(BsBellFill)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
`

export const NotificationDrawer = styled(DrawerAntd)`
  .ant-drawer-body {
    padding: 8px;

    overflow-x: hidden;
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: space-between;

    padding: 16px 8px;
  }
`

export const Notification = styled.div<{ $isNewNotification?: boolean }>`
  height: 132px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid transparent;

  background-color: ${({ $isNewNotification }) => ($isNewNotification ? '#9999991A' : '#fdfdfd')};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  :hover {
    background-color: ${({ $isNewNotification }) =>
      $isNewNotification ? '#9999991A' : 'rgba(0, 0, 0, 0.02)'};
    border-color: #80c343;
  }

  ${({ $isNewNotification }) =>
    $isNewNotification &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        height: 12px;
        width: 12px;
        border-radius: 999999999px;
        background-color: #ff4d4d;
      }
    `}
`

export const CloseIcon = styled(AiOutlineClose)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 20px;

  position: absolute;
  top: 9px;
  right: 9px;

  :hover {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 4px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  max-width: 100px;
`

export const InfoArea = styled.div`
  height: 80px;
  width: 208px;

  color: #666666;
  font-size: 12px;
  font-weight: 700;

  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const PriceArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ProductArea = styled.div`
  display: flex;
  flex-direction: column;
`

export const NotificationPercentage = styled.span<{ $isPositive: boolean }>`
  color: ${({ $isPositive, theme }) => ($isPositive ? theme.colors.brand : '#FF4D4D')};
  font-size: 14px;
  display: flex;
  align-items: center;
`

export const NotificationDate = styled(Typography.Text)`
  display: flex;
  align-items: center;

  color: #666666;
  font-size: 10px;
  font-weight: 300;
`

export const ProductName = styled(Typography.Text)`
  display: block;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 16px;
  color: #666666;
`

export const ReferencePrice = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 400;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ButtonDelete = styled(Button)`
  border-radius: 2px;
  background-color: #ffffff;
  color: #5ea320;
  border-color: #5ea320;
`

export const ButtonMore = styled(Button)`
  border-radius: 2px;
  background-color: #5ea320;
`
