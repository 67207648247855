/* eslint-disable react/display-name */
import { forwardRef } from 'react'

import * as S from './styles'

const TooltipIcon = forwardRef<HTMLDivElement, { icon: React.ReactNode }>(
  ({ icon, ...props }, forwardedRef) => {
    return (
      <S.Container {...props} ref={forwardedRef}>
        {icon}
      </S.Container>
    )
  },
)

export { TooltipIcon }
