import {
  Button,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Link,
  Preview,
  Section,
  Text,
} from '@react-email/components'

import { MarketplaceResult, ProductDetails } from '../../../types'
import * as css from './styles'

import { formatCurrency } from '@/common'
import { getUserEmail } from '@/features/auth'

type ProductEmailProps = {
  product: ProductDetails
  marketplaces: MarketplaceResult[]
}

const ProductEmail = ({ product, marketplaces }: ProductEmailProps) => {
  const topMarketplaces = marketplaces.length > 0 && marketplaces.slice(0, 10)

  return (
    <Html>
      <Head />
      <Preview>{`Veja um resumo das informações deste produto no PTT`}</Preview>

      <Section style={css.main}>
        <Container style={css.header}>
          <Img
            src={`${window.location.origin}/images/email/acer-logo.png`}
            width="80"
            height="24"
            alt="Acer logo"
          />
        </Container>
        <Container style={css.container}>
          <Section
            style={{
              marginTop: '32px',
            }}
          >
            <Img
              src={product?.informacoes?.url_imagem}
              width="150"
              height="150"
              alt={product?.modelo}
              style={{ margin: '0 auto' }}
            />
          </Section>

          <Text style={css.text}>
            <Link href={`${window.location.origin}/produtos/${product?.id}`} style={css.link}>
              <strong style={css.product}>{product?.modelo}</strong>
            </Link>{' '}
          </Text>
          <Text style={css.text}>Confira os dados do produto:</Text>
          <table style={css.detailsTable}>
            <tr>
              <td style={css.detailsItems}>Marca:</td>
              <td style={css.productDetail}>{product?.marca}</td>
            </tr>
            <tr>
              <td style={css.detailsItems}>Referência:</td>
              <td style={css.productDetail}>{product?.informacoes?.referencia}</td>
            </tr>
            <tr>
              <td style={css.detailsItems}>CPU:</td>
              <td style={css.productDetail}>{product?.informacoes?.processador}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>Velocidade da CPU:</td>
              <td style={css.productDetail}>{product?.informacoes?.velocidade_processaor}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>Sistema Operacional:</td>
              <td style={css.productDetail}>{product?.informacoes?.sistema_operacional}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>Memoria RAM:</td>
              <td style={css.productDetail}>{product?.informacoes?.memoria_ram}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>Armazenamento:</td>
              <td style={css.productDetail}>{product?.informacoes?.tipo_hd}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>Capacidade:</td>
              <td style={css.productDetail}>{product?.informacoes?.hd}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>GPU:</td>
              <td style={css.productDetail}>{product?.informacoes?.placa_de_video}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>Tipo de GPU:</td>
              <td style={css.productDetail}>{product?.informacoes?.gpu}</td>
            </tr>

            <tr>
              <td style={css.detailsItems}>Tela:</td>
              <td style={css.productDetail}>{product?.informacoes?.tela}</td>
            </tr>

            <tr>
              <td style={{ ...css.detailsItems, borderColor: 'transparent' }}>
                Resolução da Tela:
              </td>
              <td style={{ ...css.productDetail, borderColor: 'transparent' }}>
                {product?.informacoes?.resolucao_tela}
              </td>
            </tr>
          </table>

          {topMarketplaces && (
            <>
              <Text style={css.text}>
                Infomações sobre as {topMarketplaces?.length} melhores ofertas e suas lojas/sellers:
              </Text>

              <table style={css.table}>
                <tr>
                  <th style={css.tableItem}>Loja</th>
                  <th style={css.tableItem}>Seller</th>
                  <th style={css.tableItem}>Preço a vista</th>
                  <th style={css.tableItem}>Preco a prazo</th>
                </tr>

                {topMarketplaces?.map((marketplace) => (
                  <tr key={marketplace?.webprice_id}>
                    <td style={css.tableItem}>
                      <Link style={css.link} href={marketplace?.url}>
                        {marketplace?.loja}
                      </Link>
                    </td>
                    <td style={css.tableItem}>{marketplace?.seller || '-'}</td>
                    <td style={{ ...css.tableItem, textAlign: 'right' }}>
                      R$ {formatCurrency({ value: marketplace?.a_vista, type: 'decimal' })}
                    </td>
                    <td style={{ ...css.tableItem, textAlign: 'right' }}>
                      R$ {formatCurrency({ value: marketplace?.a_prazo, type: 'decimal' })}
                    </td>
                  </tr>
                ))}
              </table>
            </>
          )}

          <Section style={{ textAlign: 'center', marginTop: '32px' }}>
            <Button
              pX={20}
              pY={12}
              style={css.btn}
              href={`${window.location.origin}/produtos/${product?.id}`}
            >
              Acessar Produto no PTT
            </Button>
          </Section>

          <Hr style={css.hr} />
          <Text style={css.footer}>
            Este e-mail foi enviado por <strong>{getUserEmail()}</strong>. Se você não estava
            esperando este e-mail, você pode ignorá-lo.
          </Text>
        </Container>
      </Section>
    </Html>
  )
}

export { ProductEmail }
