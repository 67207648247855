import { useNavigate } from 'react-router-dom'

import { Button } from 'antd'

import * as S from './styles'

import { LoginCredentials, LoginForm, useSignInMutation } from '@/features/auth'
import { AccessLayout } from '@/layouts'

const LoginPage = () => {
  const navigate = useNavigate()
  const signInMutation = useSignInMutation()

  async function handleSignIn({ email, password }: LoginCredentials) {
    signInMutation.mutateAsync({ email, password })
  }

  function navigateForgotPassword() {
    navigate('/auth/forgot-password')
  }

  return (
    <AccessLayout title="Seja bem-vindo!">
      <S.Container>
        <LoginForm handleSignIn={handleSignIn} isLoading={signInMutation.isLoading} />

        <Button
          type="link"
          htmlType="button"
          onClick={navigateForgotPassword}
          disabled={signInMutation.isLoading}
        >
          Esqueci minha senha
        </Button>
      </S.Container>
    </AccessLayout>
  )
}

export { LoginPage }
