import { HiCurrencyDollar } from 'react-icons/hi'
import { HiMiniTicket, HiMiniTruck } from 'react-icons/hi2'

import { Tooltip } from 'antd'
import Table from 'antd/es/table'
import type { ColumnsType } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'

import { InternalMarketplaces, MarketplaceResult } from '../../../types'
import { TooltipIcon } from '../../TooltipIcon'
import {
  TableText,
  StoreLink,
  TooltipText,
  TableContainer,
  MarketplaceContainer,
  FireIcon,
  ConditionsContainer,
} from './styles'

import { formatCurrency } from '@/common'
import { TableTooltipTitle } from '@/features/product'

type ProductMarketplacesTableProps = {
  data: InternalMarketplaces
  isLoading: boolean
  currentPage: number
  itemsPerPage: number
  handleSort: (sorter: SorterResult<MarketplaceResult>) => void
}

const ProductMarketplacesTable = ({
  data,
  isLoading,
  currentPage,
  itemsPerPage,
  handleSort,
}: ProductMarketplacesTableProps) => {
  const UnavailableTooltip = ({ children }: { children: React.ReactNode }) => {
    return (
      <Tooltip color={'#fbfbfb'} title={<TooltipText>Produto indisponível</TooltipText>}>
        {children}
      </Tooltip>
    )
  }

  const columns: ColumnsType<MarketplaceResult> = [
    {
      title: 'nº',
      dataIndex: 'id',
      width: 50,
      align: 'center',
      render: (_, marketplace, index) => {
        const isUnavailable = !marketplace.disponivel

        const rank = (currentPage - 1) * itemsPerPage + index + 1

        if (isUnavailable)
          return (
            <UnavailableTooltip>
              <TableText isDisabled>{rank}</TableText>
            </UnavailableTooltip>
          )

        return <TableText>{rank}</TableText>
      },
    },
    {
      title: 'Loja',
      dataIndex: 'loja',
      width: '20%',
      render: (_, marketplace) => {
        const isUnavailable = !marketplace.disponivel

        if (isUnavailable) {
          return (
            <UnavailableTooltip>
              <StoreLink isDisabled href={marketplace.url} target="_blank" rel="noreferrer">
                {marketplace.loja}
              </StoreLink>
            </UnavailableTooltip>
          )
        }

        return (
          <MarketplaceContainer>
            {marketplace.patrocinado && (
              <Tooltip
                title={'Oferta patrocinada'}
                placement="topLeft"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon icon={<FireIcon />} />
              </Tooltip>
            )}

            <StoreLink
              isDisabled={isUnavailable}
              href={marketplace.url}
              target="_blank"
              rel="noreferrer"
            >
              {marketplace.loja}
            </StoreLink>
          </MarketplaceContainer>
        )
      },
    },
    {
      title: 'Seller',
      dataIndex: 'seller',
      render: (value, marketplace) => {
        const isUnavailable = !marketplace.disponivel
        const isSameSeller = marketplace.seller === marketplace.loja

        const text = isSameSeller ? '-' : value ? value : '-'

        if (isUnavailable)
          return (
            <UnavailableTooltip>
              <TableText isDisabled>{text}</TableText>
            </UnavailableTooltip>
          )

        return <TableText>{text}</TableText>
      },
    },
    {
      title: 'À vista',
      dataIndex: 'a_vista',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      width: '18%',
      align: 'center',
      render: (value: number, marketplace) => {
        const isUnavailable = !marketplace.disponivel

        if (isUnavailable) {
          return (
            <UnavailableTooltip>
              <TableText isDisabled>{formatCurrency({ value })}</TableText>
            </UnavailableTooltip>
          )
        }

        return <TableText isBold>{formatCurrency({ value })}</TableText>
      },
    },
    {
      title: 'À prazo',
      dataIndex: 'a_prazo',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      width: '18%',
      align: 'center',
      render: (value: number, marketplace) => {
        const isUnavailable = !marketplace.disponivel

        if (isUnavailable) {
          return (
            <UnavailableTooltip>
              <TableText isDisabled>{formatCurrency({ value })}</TableText>
            </UnavailableTooltip>
          )
        }

        return <TableText isBold>{formatCurrency({ value })}</TableText>
      },
    },
    {
      title: 'Condições especiais',
      dataIndex: 'disponivel',
      width: 120,
      align: 'center',
      render: (available, marketplace) => {
        const isUnavailable = !available

        const noItems = !marketplace.frete_gratis && !marketplace.cashback && !marketplace.cupom

        if (isUnavailable) {
          return (
            <UnavailableTooltip>
              <TableText isDisabled>-</TableText>
            </UnavailableTooltip>
          )
        }

        if (noItems) return <TableText isDisabled={isUnavailable}>-</TableText>

        return (
          <ConditionsContainer>
            {marketplace.frete_gratis && (
              <Tooltip
                title={`Possui frete grátis`}
                placement="topLeft"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon icon={<HiMiniTruck />} />
              </Tooltip>
            )}

            {marketplace.cashback && (
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={
                  <TableTooltipTitle
                    type="cashback"
                    value={marketplace.cashback}
                    valueWithDiscount={marketplace.discount_with_cashback as number}
                  />
                }
                placement="top"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon icon={<HiCurrencyDollar />} />
              </Tooltip>
            )}

            {marketplace.cupom && (
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={
                  <TableTooltipTitle
                    type="cupom"
                    valueType={marketplace.cupom.cupom_type}
                    value={marketplace.cupom.value}
                    valueWithDiscount={marketplace.discount_with_cupom as number}
                  />
                }
                placement="topRight"
                arrow={{ pointAtCenter: true }}
              >
                <TooltipIcon icon={<HiMiniTicket />} />
              </Tooltip>
            )}
          </ConditionsContainer>
        )
      },
    },
  ]

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={data?.results}
        loading={isLoading}
        showHeader
        rowKey={(record) => `${record.webprice_id}-${crypto.randomUUID()}`}
        rowClassName={(record, index) => {
          const isDisabled = !record.disponivel

          if (isDisabled) return 'disabled-row'

          return index % 2 === 0 ? 'light-row' : 'dark-row'
        }}
        pagination={false}
        onChange={(_, __, sorter) => handleSort(sorter as SorterResult<MarketplaceResult>)}
        footer={() => ''}
      />
    </TableContainer>
  )
}

export { ProductMarketplacesTable }
