// Senha correta -> 200 -> {AccessToken, RefreshToken, IdToken}
// Senha errada -> 403 -> "message": "Incorrect username or password."
// Primeiro Login -> 403 -> "message": "NEW_PASSWORD_REQUIRED"
// Usuario desativado -> 403 -> "message": "User is disabled."

import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'

import { authStore } from '@/app'
import { AccessTokens, ApiError } from '@/common'
import { api } from '@/services'

type SignInMutation = {
  email: string
  password: string
}

const errorMessages = new Map([
  ['User is disabled', 'Usuário desativado'],
  ['User does not exist.', 'Email ou senha incorreto'],
  ['Incorrect username or password.', 'Email ou senha incorreto'],
])

const useSignInMutation = () => {
  const navigate = useNavigate()

  return useMutation(
    async ({ email, password }: SignInMutation) => {
      return api.post<AccessTokens>('/auth/login', {
        username: email,
        password,
      })
    },
    {
      onSuccess: ({ data }) => {
        const { AccessToken, IdToken, RefreshToken } = data

        api.defaults.headers.common.Authorization = `Bearer ${IdToken}` // set fresh idToken to headers

        // save tokens in store
        authStore.setState(
          {
            accessToken: AccessToken,
            idToken: IdToken,
            refreshToken: RefreshToken,
            isUserAuthenticated: true,
          },
          false,
          'sign-in',
        )

        navigate('/')
      },
      onError: (error: ApiError<string>, { email }) => {
        if (error.response?.status === 403) {
          // First user login (has temporary password)
          if (error.response?.data.message === 'NEW_PASSWORD_REQUIRED') {
            return navigate(`/auth/reset-password?first=${email}`)
          }

          // User trying to login with forgot-password process in progress (has to change password with email code)
          if (error.response?.data.message === 'Password reset required for the user') {
            toast.success('É necessário criar uma nova senha')
            return navigate('/auth/forgot-password')
          }

          // other possible errors
          console.log(error)
          toast.error(errorMessages.get(error.response?.data.message) || 'Algo deu errado')
        } else {
          console.log(error) // unknown errors
        }
      },
    },
  )
}

export { useSignInMutation }
