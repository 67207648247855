import { Modal as AntdModal } from 'antd'
import styled from 'styled-components'

export const Modal = styled(AntdModal)`
  display: flex;

  .ant-modal-title {
    color: #434343;
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  }

  .ant-modal-content {
    border-radius: 0;
    padding: 0;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px 22px 10px 24px;
    margin: 0;

    background: var(--neutral-1, #fff);
    box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
  }
`
