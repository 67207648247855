import { useEffect } from 'react'

import { RadioChangeEvent, CheckboxOptionType, Space } from 'antd'

import { Container, Title, RadioGroup } from './styles'

import { filterStore as useFilterStore } from '@/app'

interface RadioFilterProps {
  title?: string
  initialValue?: CheckboxOptionType | string | number
  data: Array<CheckboxOptionType | string | number>
  tooltipDescription?: string
  value?: any
  onChange?: (event: RadioChangeEvent) => void
  name: string
}

const RadioFilter = ({ title, data, initialValue, onChange, value, name }: RadioFilterProps) => {
  const activeFilters = useFilterStore((s) => s.activeFilters)

  useEffect(() => {
    const activeFilterValues = activeFilters[name]

    if (!activeFilterValues) {
      onChange?.('' as any)
    } else {
      onChange?.(activeFilterValues as any)
    }
    // exhaustive-deps is disabled because we don't want to re-run this effect when onChange changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, name])

  useEffect(() => {
    if (initialValue) {
      onChange?.(initialValue as any)
    }
  }, [initialValue])

  function handleRadioChange({ target: { value } }: RadioChangeEvent) {
    onChange?.(value)
  }

  return (
    <Container>
      {title && (
        <Space size={6}>
          <Title>{title}</Title>
        </Space>
      )}

      <RadioGroup
        options={data}
        onChange={handleRadioChange}
        value={value}
        defaultValue={initialValue}
      />
    </Container>
  )
}

export { RadioFilter }
