import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Collapse as AntdCollapse, Divider as AntdDivider } from 'antd'
import styled from 'styled-components'

export const Collapse = styled(AntdCollapse)`
  background-color: ${({ theme }) => theme.colors.white};
`

export const CollapsePanel = styled(AntdCollapse.Panel)<{ $isOpen: boolean }>`
  font-size: 16px;
  font-weight: 600;
  background-color: ${({ $isOpen }) =>
    $isOpen ? '#fafafa' : '#FFF'}; // $ prefix AKA transient props in styled-components
  border-radius: 5px 5px 0 0;
`

export const OpenIcon = styled(PlusOutlined)`
  color: ${({ theme }) => theme.colors.brand};
  transition: all 0.3s ease-in-out;
`

export const CloseIcon = styled(MinusOutlined)`
  color: ${({ theme }) => theme.colors.brand};
  transition: all 0.3s ease-in-out;
`

export const Divider = styled(AntdDivider)`
  margin: 24px 0 16px;
`
