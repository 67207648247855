import { Typography } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  margin: auto;

  form {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .ant-card {
    width: 500px;
    border-radius: 5px;
  }
`
export const TypographyText = styled(Typography)`
  margin-bottom: 11px;
`
