import toast from 'react-hot-toast'

import { Form } from 'antd'

import { useAddTagMutation, useRemoveProductTagMutation } from '../../../mutations'
import { useProductTags } from '../../../queries'
import { ProductTag } from '../../ProductTag'
import { TagInput } from '../../TagInput'
import * as S from './styles'

import { Modal } from '@/components'

type AddTagModalProps = {
  isOpen: boolean
  closeModal: () => void
  productId: string
}

const AddTagModal = ({ isOpen, closeModal, productId }: AddTagModalProps) => {
  const { data: productTags } = useProductTags(productId)

  const addTagMutation = useAddTagMutation()
  const removeProductTagMutation = useRemoveProductTagMutation()

  const [form] = Form.useForm()

  function handleCancel() {
    closeModal()
    form.resetFields()
  }

  async function handleSubmit({ tag }: { tag: string }) {
    if (tag.length > 10) {
      return toast.error('O tamanho máximo de uma tag é de 10 caracteres')
    }

    try {
      const promise = addTagMutation.mutateAsync({
        productId,
        tagName: tag,
      })
      await toast.promise(promise, {
        loading: 'Adicionando tag...',
        success: 'Tag adicionada com sucesso!',
        error: (error) => error.response.data.message || 'Erro ao adicionar tag',
      })
      closeModal()
    } catch (error) {
      form.resetFields()
    }
  }

  async function handleRemoveTag(tagId: string) {
    const promise = removeProductTagMutation.mutateAsync({
      tagId,
      productId,
    })

    toast.promise(promise, {
      loading: 'Removendo tag...',
      success: 'Tag removida com sucesso!',
      error: 'Erro ao remover tag',
    })
  }

  return (
    <Modal
      title="Adicionar Tag"
      open={isOpen}
      onCancel={closeModal}
      onOk={() => form.submit()}
      footer={
        <>
          <S.CancelButton onClick={handleCancel}>Cancelar</S.CancelButton>

          <S.AddButton type="primary" onClick={() => form.submit()}>
            Adicionar
          </S.AddButton>
        </>
      }
    >
      <S.Content>
        <S.FormContainer>
          <Form
            onFinish={(values) => handleSubmit(values)}
            form={form}
            disabled={addTagMutation.isLoading}
          >
            <TagInput productTags={productTags} />
          </Form>
        </S.FormContainer>

        <S.TagsContainer>
          {productTags && productTags.length > 0 ? (
            <>
              <S.Text>Tags deste produto: </S.Text>

              <div>
                {productTags?.map((tag) => (
                  <ProductTag
                    key={tag.id}
                    tagId={tag.id}
                    closable
                    handleRemoveTag={handleRemoveTag}
                  >
                    {tag.tag}
                  </ProductTag>
                ))}
              </div>
            </>
          ) : (
            <S.Text>Este produto ainda não possui tags.</S.Text>
          )}
        </S.TagsContainer>
      </S.Content>
    </Modal>
  )
}

export { AddTagModal }
