import { useQuery } from '@tanstack/react-query'

import type { Tag } from '../../types'
import { tagKeys } from '../query-keys'

import { ApiResponse } from '@/common'
import { api } from '@/services'

type TagResponse = ApiResponse<Tag[]>

async function fetchTags() {
  try {
    const { data } = await api.get<TagResponse>(`/tag`)

    return data.result
  } catch (err) {
    return []
  }
}

const useUserTags = () => {
  return useQuery(tagKeys.list(), fetchTags, {
    keepPreviousData: true,
  })
}

export { useUserTags }
