import { Typography } from 'antd'

import * as S from './styles'

type DataNotFoundProps = {
  message: string
  icon?: React.ReactNode
}

const DataNotFound = ({ message, icon = <S.ExclamationIcon /> }: DataNotFoundProps) => (
  <S.Container>
    {icon}
    <Typography>{message}</Typography>
  </S.Container>
)

export { DataNotFound }
