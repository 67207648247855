import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'

import { resetFilters } from './filters'

type AuthStoreState = {
  isUserAuthenticated: boolean
  accessToken: string | undefined
  refreshToken: string | undefined
  idToken: string | undefined
  signOut: () => void
}

const authStore = create<AuthStoreState>()(
  devtools(
    persist(
      (set, get) => ({
        accessToken: undefined,
        refreshToken: undefined,
        idToken: undefined,
        isUserAuthenticated: false,

        signOut: () => {
          resetFilters()

          set(
            {
              accessToken: undefined,
              refreshToken: undefined,
              idToken: undefined,
              isUserAuthenticated: false,
            },
            false,
            'sign-out',
          )
        },
      }),
      { name: 'auth-store' },
    ),
  ),
)

export const signOut = authStore.getState().signOut

export default authStore
