import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Category } from '../../types'
import { categoryKeys } from '../query-keys'

import { ApiResponse } from '@/common'
import { api } from '@/services'

async function fetchProductCategories({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof categoryKeys)['product']>>) {
  const [, , productId] = queryKey

  const { data } = await api.get<ApiResponse<Category[]>>(`/categories?produto=${productId}`)

  return data.result
}

const useProductCategories = (productId: string) => {
  return useQuery(categoryKeys.product(productId), fetchProductCategories, {
    keepPreviousData: true,
    retry: 0,
  })
}

export { useProductCategories }
