import { GraphFilterParams, HighlightedMarketplaces } from '../types'

export const dashboardKeys = {
  all: ['dashboard'] as const,
  cards: () => [...dashboardKeys.all, 'cards'] as const,
  graphs: () => [...dashboardKeys.all, 'graphs'] as const,
  offersByBrand: () => [...dashboardKeys.graphs(), 'offersByBrand'] as const,
  offersByMarketplace: () => [...dashboardKeys.graphs(), 'offersByMarketplace'] as const,
  highlights: () => [...dashboardKeys.cards(), 'highlights'] as const,
  highlight: (marketplace: HighlightedMarketplaces, params: GraphFilterParams) =>
    [...dashboardKeys.cards(), marketplace, params] as const,
}
