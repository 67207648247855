import { AiFillExclamationCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'

import { Button, Modal, Typography } from 'antd'
import styled from 'styled-components'

export const InfoModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 0;
  }

  .ant-modal-footer {
    margin-top: 0;
    box-shadow: inset 0px 1px 0px #f0f0f0;
  }
`

export const CloseButton = styled(Button)`
  border-radius: 0;
  background-color: #ffffff;
  color: #80c343;
  border-color: #80c343;
  margin-bottom: 10px;
  margin-right: 22px;
  margin-top: 10px;

  .ant-modal-footer {
    margin-top: 0;
  }
`

export const Title = styled(Typography.Title)`
  padding: 16px 24px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #f3f3f3;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 14px 24px 85px 35px;

  .ant-typography {
    margin-bottom: 0;
    line-height: initial;
  }
`

export const AlertRulesCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 8px;
  height: 45px;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 2px;
`

export const IconAlert = styled(AiFillExclamationCircle)`
  font-size: 20px;
  color: #faad14;
`

export const TextAlert = styled(Typography.Paragraph)`
  width: 485px;

  font-size: 12px;
  color: #404040;
`

export const ProductArea = styled.div`
  display: flex;
  gap: 13px;
`

export const ImageContainer = styled.div`
  display: block;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const TitleProduct = styled(Typography.Text)`
  font-size: 20px;
  color: #80c343;
  font-weight: 300;
`

export const ProductLink = styled(Link)`
  font-size: 20px;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 600;
  text-transform: capitalize;

  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.brand};
    filter: brightness(0.8);
  }
`

export const DescriptionProduct = styled(Typography.Paragraph)`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
`

export const ValueProduct = styled(Typography.Text)`
  font-size: 14px;
  color: #999999;
`
