import { useQuery } from '@tanstack/react-query'

import type { Group } from '../../types'
import { groupKeys } from '../query-keys'

import { api } from '@/services'

type GroupAPIResponse = {
  message: Group[]
}

const fetchGroups = async () => {
  const { data } = await api.get<GroupAPIResponse>('/group')

  return data.message
}

const useGroups = () => useQuery(groupKeys.list(), fetchGroups)

export { useGroups }
