import styled from 'styled-components'

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .anticon {
    color: ${({ theme }) => theme.colors.brand};
  }
`
