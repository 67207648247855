import { ReactNode } from 'react'

import { Container, Content, ContentContainer, SidebarContainer } from './styles'

import { useDocumentTitle } from '@/common'
import { Header } from '@/components'

interface PageLayoutProps {
  children: ReactNode
  sidebar?: ReactNode
  title?: string
  windowTitle?: string
}

const PageLayout = ({ title, sidebar, children, windowTitle }: PageLayoutProps) => {
  useDocumentTitle(windowTitle)

  return (
    <Container>
      <Header />

      <SidebarContainer>
        {title}
        {sidebar}

        <ContentContainer>
          <Content>{children}</Content>
        </ContentContainer>
      </SidebarContainer>
    </Container>
  )
}

export { PageLayout }
